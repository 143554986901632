import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

export default class Socket {
    constructor(token) {
        this.socketURI = `wss://www.aikit.ai/ws/?bearer=${token}`;
        this.connect();
        this.events = [];
        this.handlers = {};
    }
 
    connect() {
        // console.log('ws connect inited');
        this.ws = new WebSocket(this.socketURI);
        this.ws.onopen = event => this.onopen(event);
        this.ws.onclose = event => this.onclose(event);
        this.ws.onerror = event => this.onerror(event);
        this.ws.onmessage = event => this.onmessage(event);
    }
 
    onmessage(ev) {
        const data = JSON.parse(ev.data);
        // console.log('onmessage:', data)
        
        if(data.event === "warning" && data?.payload?.id){
            // if(!this.handlers[data?.payload?.id]) return;
            // this.handlers[data?.payload?.id](data);
            toast.error(data?.payload?.code.split('-').map(item => `${item[0].toUpperCase()}${item.slice(1)}`).join(' '));

            return;
        }

        if(!data.id) return;
        if(!this.handlers[data.id]) return;
        this.handlers[data.id](data);
    }
 
    onopen() {
        // console.log('Socket connected');
    }
    
    onclose(e) {
        this.handlers = {};
        console.log(e);
        if(e.reason === 'Unauthorized') return;
        console.log('ws onclose');
        setTimeout(() => this.connect(), 2000);
    }
 
    onerror(error) {
        console.log('Socket error: ', error);
    }
    send({ event, payload, handler, id = uuidv4() }) {
        if(this.ws.readyState !== 1) return;
        this.handlers[id] = handler;
        this.ws.send(JSON.stringify({ event, payload, id }));
    }
};