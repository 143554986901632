import React, { useState } from 'react';
import classNames from 'classnames';
import { isAfter, isToday, isYesterday, subWeeks } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import ChatItem from './ChatItem';

const getChatHistory = (chats) => {
    const history = {
        'Today': [],
        'Yesterday': [],
        'Previous 7 days': [],
        'More than a week ago': []
    };
    chats.forEach((chat) => {
        const date = new Date(chat.updatedAt);
        if(isToday(date)) {
            history['Today'].push(chat);
        } else if(isYesterday(date)) {
            history['Yesterday'].push(chat);
        } else if(isAfter(date, subWeeks(new Date(), 1))) {
            history['Previous 7 days'].push(chat);
        } else  {
            history['More than a week ago'].push(chat);
        }
    })
    return history;
} 

const History = ({ chats, setChats }) => {
    const { botId, chatId } = useParams();
    const history = getChatHistory(chats);

    return <div className='py-[13px] px-[16px] flex-1 flex flex-col overflow-auto'>
        <h2 className='text-[14px] font-medium leading-[14px]'>History</h2>
        <p className='text-[12px] leading-[14px] opacity-40 mt-[6px]'>Check history of your chats with bot</p>
        <div className='flex gap-[8px] mt-[20px]'>
            <div className='input-search flex-1'>
                <svg opacity='0.3' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <circle cx="10.215" cy="10.2155" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.67 17.6694L14.377 14.3764" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <input  placeholder='Search chats...' />
            </div>
            <Link to={`/chat-bot/${botId}`} className='generated-button btn small'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.16675V15.8334" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.16797 10H15.8346" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </Link>
        </div>
        <ul className='flex-auto mt-[16px] gap-[16px] flex flex-col overflow-y-scroll no-scroll'>
            {Object.entries(history).filter(([title, chats]) => chats.length).map(([title, chats]) => <li key={title}>
                <p className='text-[12px] leading-[14px] text-[#A7A7A7]'>{title}</p>
                <ul className='mt-[10px] flex flex-col gap-[4px]'>
                    {chats.map((chat) =>
                        <ChatItem key={chat.id} chat={chat} setChats={setChats}/>
                    )}
                </ul>
            </li>)}
        </ul>
    </div>
};

export default History;