import React, { useEffect, useState } from 'react';
import IconCheckCircle from "../../assets/img/icons/icon-check-circle.svg";
import Header from '../../components/parts/Header';
import api from '../../api';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import logo from '../../assets/img/logo.png';
import smallLogo from '../../assets/img/react/sidebar-logo-xs.png';
import { getToken, getUser } from '../../redux/slices/applicationSlice';
// import { useStripe } from '@stripe/react-stripe-js';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getUserThunk } from '../../redux/thunks/applicationThunks';
import GeneratedContentModal from '../GeneratedContent/GeneratedContentModal';

const Pricing = () => {
    const token = useSelector(getToken);
    // const stripe = useStripe();

    const navigate = useNavigate();
    const [pricing, setPricing] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const [yearly, setYearly] = useState(false);

    const [openedModal, setOpenedModal] = useState(null);

    const handleSubscribePlan = async (planId) => {
        const toastId = toast.loading("Subscribing...");
        try {
            if (!token) {
                navigate('/sign-in', {
                    state: {
                        sourse: '/pricing'
                    }
                });
                return;
            }

            const data = await api.payments.subscribePlan({
                planId,
                token
            });

            if (!data.success) {
                toast.update(toastId, {
                    render: data.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000
                });
                return;
            } else {
                toast.update(toastId, {
                    render: 'Subscribing success',
                    type: 'success',
                    isLoading: false,
                    autoClose: 5000
                });
                dispatch(getUserThunk({ token }));
                return;
            }
            // const dataConfirm = await stripe.confirmCardPayment(data.client_secret);
            // console.log('dataConfirm', dataConfirm);

            // const resolve = await api.payments.resolveSubscribe({token});
            // toast.update(toastId, {
            //     render: resolve.message,
            //     type: 'success', 
            //     isLoading: false,
            //     autoClose: 5000
            // });
            dispatch(getUserThunk({ token }));
        } catch (e) {
            toast.update(toastId, {
                render: e.response.data.message || 'Error',
                type: 'error',
                isLoading: false,
                autoClose: 5000
            });
        }
    }

    const cancelSubscribePlan = async () => {
        const toastId = toast.loading("Canceling...");

        const data = await api.payments.cancelPlan({ token });

        if (!data.success) {
            toast.update(toastId, {
                render: data.message,
                type: 'error',
                isLoading: false,
                autoClose: 5000
            });
        } else {
            toast.update(toastId, {
                render: 'Cancel success',
                type: 'success',
                isLoading: false,
                autoClose: 5000
            });
        }

        dispatch(getUserThunk({ token }));
    }

    useEffect(() => {
        api.pricingPlans.getPricing()
            .then(response => setPricing(response.data));
    }, [])

    return <div>
        <Header showLogo={true} />
        <section className="mt-[62px]">
            <div className="container">
                <div className="pricing__header-group mb-[30px] lg:mb-[60px]">
                    <span className="block text-[14px] lg:text-[18px] font-[600] leading-[24px] lg:leading-[54px] mb-[8px]">PRICING</span>
                    <h2 className="text-[24px] lg:text-[48px] font-[800] mb-[8px]">Take control of your productivity</h2>
                    <p className="text-[14px] lg:text-[20px] leading-[24px] lg:leading-[32px]">Start small and free, upgrade as you go.<br />Take control of everything.</p>
                    <div className="d-flex justify-center">
                        <div className="settings-checkbox-container mt-[15px] lg:mt-[30px]">
                            <div className="sett-checkbox-desc min-w-[90px] text-right">
                                <span className="sett-title">Bill Monthly</span>
                            </div>
                            <label for="bill-checkbox" className="sett-checkbox" style={{ margin: "0 15px" }}>
                                <input
                                    id="bill-checkbox" type="checkbox" className="sett-checkbox-hid"
                                    hidden checked={yearly}
                                    onChange={(e) => {
                                        setYearly(e.target.checked);
                                    }}
                                />
                                <div className="sett-checkbox-sub"></div>
                            </label>
                            <div className="sett-checkbox-desc min-w-[90px] text-left">
                                <span className="sett-title">Bill Yearly</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-30">
                    {pricing.map(item =>
                        <div
                            className="col-md-6 col-lg-3 mb-[30px]"
                            key={item.id}
                        >
                            <div className={classNames('pricing__card', {
                                'popular': item.is_popular
                            })}>
                                {item.is_popular && <span className="popular-text">POPULAR</span>}
                                <h3 className="pricing__title">{item.name}</h3>
                                <p className="pricing__subtitle">{item.description}</p>
                                <span className="pricing__price-group">
                                    <span className="pricing__currency">USD</span>
                                    <span className="pricing__price">${yearly ? ((item.price * 12) - ((item.price * 12 / 100) * 20)).toFixed(2) : item.price}</span>
                                </span>
                                <span className="block text-[14px] leading-[30px] color-secondary-lighten-60 mb-[20px] lg:mb-[40px]">
                                    billed {yearly ? 'yearly' : 'monthly'}
                                </span>
                                <div className="flex justify-center">
                                    {
                                        user?.plan === item.id ?
                                            <button
                                                className="btn btn--primary-outline btn-lg-4 rounded-[8px] text-[16px] w-full max-w-[220px] mb-[20px] lg:mb-[40px]"
                                                style={{ backgroundColor: '#FF8114', color: '#331A04' }}
                                                onClick={() => setOpenedModal({ title: 'Cancel Subscribe', action: () => { setOpenedModal(null); cancelSubscribePlan(); } })}
                                            >
                                                Cancel Subscribe
                                            </button> :
                                            <button
                                                className="btn btn--primary-outline btn-lg-4 rounded-[8px] text-[16px] w-full max-w-[220px] mb-[20px] lg:mb-[40px]"
                                                onClick={() => setOpenedModal({ title: 'Confirm Subscribe', action: () => { setOpenedModal(null); handleSubscribePlan(item.id); } })}
                                            >
                                                Get Started
                                            </button>
                                    }

                                </div>
                                <ul className="pricing__list">
                                    {item.options.map((option, index) =>
                                        <li className="pricing__item" key={index}>
                                            <div className="min-w-[20px] mr-[8px]">
                                                <img src={IconCheckCircle} />
                                            </div>
                                            <span>{option}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>

        {openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            <div className="modal__body w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        {openedModal?.title ? openedModal.title : 'Do you really want to delete?'}
                    </h3>
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div>
        </GeneratedContentModal>}
    </div>
}

export default Pricing;