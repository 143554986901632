import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Label from '../../../../components/base/Label';
import SelectBox from '../../../../components/base/SelectBox';
import DatePicker from '../../../../components/base/DatePicker';
import { endOfToday, isToday, startOfToday } from 'date-fns';

const Schedule = ({ selectedForSetting, setSelectedForSetting }) => {
    console.log('Schedule selectedForSetting:', selectedForSetting);
    const [localData, setLocalData] = useState({
        date: '',
        time: '',
        send_all: false,
    });

    return <div>
        <div className="d-flex gap-[16px] flex-col lg:flex-row mb-[16px] lg:mb-[0px]">
            <div className='flex flex-col gap-[10px] lg:mb-[24px] flex-1'>
                <Label>Lesson Frequency</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Select Frequency', value: '', placeholder: true },
                        { label: 'Daily', value: 'Daily' },
                        { label: 'Weekly', value: 'Weekly' },
                        { label: 'Monthly', value: 'Monthly' },
                        { label: 'Yearly', value: 'Yearly' },
                    ]}
                    value={selectedForSetting?.settings?.scheduleLessonFrequency}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                scheduleLessonFrequency: value
                            }
                        }))
                    }
                />
            </div>
            <div className='flex flex-col gap-[10px] lg:mb-[24px] flex-1'>
                <Label>Repeats on</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Select Day', value: '', placeholder: true },
                        { label: 'Monday', value: 'Monday' },
                        { label: 'Tuesday', value: 'Tuesday' },
                        { label: 'Wednesday', value: 'Wednesday' },
                        { label: 'Thursday', value: 'Thursday' },
                        { label: 'Friday', value: 'Friday' },
                        { label: 'Saturday', value: 'Saturday' },
                        { label: 'Sunday', value: 'Sunday' },
                    ]}
                    value={selectedForSetting?.settings?.scheduleRepeatOn}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            scheduleRepeatOn: value
                        }))
                    }
                />
            </div>
        </div>
        <div className="d-flex gap-[16px] flex-col lg:flex-row mb-[16px] lg:mb-[0px]">
            <div className='flex flex-col gap-[10px] lg:mb-[24px] flex-1'>
                <Label>Begins</Label>
                <DatePicker
                    selected={selectedForSetting?.settings?.scheduleBegins}
                    onChange={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                scheduleBegins: value
                            }
                        }))
                    }
                    dateFormat="MM.dd.yyy"
                    minDate={startOfToday()}
                    placeholderText={"MM.dd.yyyy"}
                    closeWhenChange
                />
            </div>
            <div className='flex flex-col gap-[10px] lg:mb-[24px] flex-1'>
                <Label>Time</Label>
                <DatePicker
                    selected={selectedForSetting?.settings?.scheduleTime}
                    onChange={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                scheduleTime: value
                            }
                        }))
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Select Time"
                    dateFormat="h:mm aa"
                    placeholderText="00:00 AM"
                    closeWhenChange
                    minTime={isToday(selectedForSetting?.settings?.scheduleTime) ? new Date() : startOfToday()}
                    maxTime={endOfToday()}
                />
            </div>
        </div>
        <div className="d-flex mb-[16px]">
            <a href="#" className="d-flex items-center min-h-[30px]" onClick={(e)=>{
                e.preventDefault();
                setSelectedForSetting((prevState)=>({
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        scheduleIsSendOneMessage: !prevState?.settings?.scheduleIsSendOneMessage
                    }
                }))
            }}>
                <div className={`checkbox-item-square ${selectedForSetting?.settings?.scheduleIsSendOneMessage ? 'active' : ''}`}>
                    {
                        selectedForSetting?.settings?.scheduleIsSendOneMessage ?
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6654 3.79102L5.2487 10.2077L2.33203 7.29102" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> : null
                    }
                </div>
                <span className="ml-[12px] text-[13px] text-[#FFFFFF] opacity-90">Send all in one message</span>
            </a>
        </div>
    </div>
};


export default Schedule;