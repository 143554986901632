import React, { useState, useEffect } from 'react';
import Header from "../../components/parts/Header";
import { 
    // categories,
    cardsContent
} from "../../data";
import { useDebounce } from "../../helpers/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import api from '../../api';

const options = [...cardsContent]

const ProductDescription = () => {
    const [rerenderKey, setRerenderKey] = useState(1);
    const [rerenderCatKey, setRerenderCatKey] = useState(1);
    const [search, setSearch] = useState('');
    const [isOpenInput, setIsOpenInput] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([])
    const [selectedCardId, setSelectedCardId] = useState(null)

    const [searchLoading, setSearchLoading] = useState(false);

    const [productsData, setProductsData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [localCategories, setLocalCategories] = useState([]);

    const [cardsContentCount, setCardsContentCount] = useState(1);

    const [cardsContentWithServer, setCardsContentWithServer] = useState([...cardsContent]);

    // .slice(0, 15)
    const [localCardsContent, setLocalCardsContent] = useState([...cardsContentWithServer]);
    
    console.log("localCardsContent:", localCardsContent);

    const [updatedTimes, setUpdatedTimes] = useState(0);
    const isUpdated = useDebounce(updatedTimes, 1000);

    const [selectedCategories, setSelectedCategories] = useState([]);

    const startSearch = () => {
        // setSearchLoading(true);

        // setTimeout(() => {
            setLocalCategories(
                [...categories].filter(item => !selectedCategories.length ? true : item.toLowerCase().includes(search.toLowerCase()))
            );

            if (selectedCardId) {
                setLocalCardsContent(
                    [...cardsContentWithServer].filter(item => item.id == selectedCardId)
                );
                return
            }

            setLocalCardsContent(
                // .slice(0, 15*cardsContentCount)
                [...cardsContentWithServer].filter(item => item.title.toLowerCase().includes(search.toLowerCase()) && (!selectedCategories.length ? true : selectedCategories.filter( el => item.categories.indexOf( el ) > -1 ).length ))
            );
            // setSearchLoading(false);
        // }, 300);
    }
    
    // useEffect(() => {
    //     if(isUpdated){
    //         startSearch();
    //     }
    // }, [isUpdated]);

    useEffect(() => {
        startSearch();
        setRerenderKey(Math.floor(Math.random() * 10));
    }, [search, selectedCategories]);

    useEffect(() => {
        setRerenderCatKey(Math.floor(Math.random() * 10));
    }, [search]);

    const filterOptions = (value) => {
        const val = options.filter(option => option.title.toLowerCase().includes(value.toLowerCase()))
    
        setFilteredOptions(val)
    } 

    const handleChangeInput = (e) => {
        const value = e.target.value
        setIsOpenInput(true)
        setSelectedCardId(null)
        setSearch(value)
        // setUpdatedTimes((prevState) => prevState + 1)
    
        if (value === '') {
            setFilteredOptions([])
            setIsOpenInput(false)
            return
        }
    
        filterOptions(value)
        
      }
    
    const handleOptionClick = (option) => {
        setSearch(option.title)
        setSelectedCardId(option.id)
        setIsOpenInput(false)
        filterOptions(option.title)
        setUpdatedTimes((prevState) => prevState + 1)
    }

    const closeInputHandler = (e) => {
        if (!e.target.closest('.search-input-form')) {
            setIsOpenInput(false)
        }
    }

    const fetchMoreData = () => {
        setCardsContentCount((prevState) => prevState + 1);
        startSearch();
    }

    const fetchProductsWithCategories = async() => {
        const responseCategories = await api.assets.getProductСategories();
        const responseProducts = await api.assets.getProducts();

        setCategories(responseCategories?.data?.map(item => item.title));
        setLocalCategories(responseCategories?.data?.map(item => item.title));

        setProductsData(responseProducts?.data);
        // console.log('cardsContent:', cardsContent);

        const forCardsContent = cardsContent?.map((item)=>({
            ...item,
            categories: responseProducts?.data.find(itemP => +itemP.id === +item.id)?.categories.map(itemC => itemC.title) || []
        }))

        setCardsContentWithServer(forCardsContent);

        setLocalCardsContent(forCardsContent);

        console.log("formatted:", cardsContent?.map((item)=>({
            ...item,
            categories: responseProducts?.data.find(itemP => +itemP.id === +item.id)?.categories.map(itemC => itemC.title) || []
        })));

        console.log('responseProducts?.data:', responseProducts?.data);
    }

    useEffect(() => {
        if (isOpenInput) {
            document.addEventListener('click', closeInputHandler)
        }

        return () => document.removeEventListener('click', closeInputHandler)
    }, [isOpenInput])

    useEffect(() => {
        startSearch();
    }, [cardsContentCount])


    useEffect(() => {
        fetchProductsWithCategories();
    }, [])
    

    return (
        <div className="px-[20px] want-to-create-wrpr pb-[20px]">
            <Header showLogo={true} />
            <div className="d-flex flex-col items-center assets-page" style={{ minHeight: "calc(100dvh - 84px)" }}>
                <div className="d-flex flex-col items-center bg-gradient-section-box w-100 product-desctription-box">
                    <h1 className="main-title text-center mb-48">What do you want to create?</h1>

                    <form className={`search-input-form mb-[20px] ${ isOpenInput && filteredOptions.length ? 'autofill-dropdown-open' : '' }`}>
                        <input
                            className='search-input'
                            type="text" placeholder="Search product you want to create..."
                            value={search}
                            onClick={() => setIsOpenInput(true)}
                            onChange={handleChangeInput}
                        />
                        {isOpenInput && filteredOptions.length > 0 && (
                            <ul className={`search-container animate__fadeIn animate__animated`}>
                            {filteredOptions.map((option, index) => (
                                <li key={index} onClick={() => handleOptionClick(option)}>
                                    <p className='search-title'>
                                    {option.title.split('').map((char, i) => {
                                        return (
                                        <span key={i} className={`${search.toLocaleLowerCase().includes(char.toLowerCase()) ? 'highlight-char' : '' }`}>
                                            {char}
                                        </span>
                                        );
                                    })}
                                    </p>
                                    <p className='search-desc'>{option.description}</p>
                                
                                </li>
                            ))}
                            </ul>
                        )}
                        <button style={{ pointerEvents: search.length ? 'initial' : 'none' }} onClick={(e)=>{
                            e.preventDefault();
                            setSearch('');
                            setFilteredOptions([])
                            setSelectedCardId(null)
                            // setUpdatedTimes((prevState) => prevState + 1);
                        }}>
                            {
                                search.length ? 
                                <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 11.8333L5.875 15.9583C5.68056 16.1528 5.45486 16.25 5.19792 16.25C4.94097 16.25 4.72222 16.1528 4.54167 15.9583C4.34722 15.7778 4.25 15.559 4.25 15.3021C4.25 15.0451 4.34722 14.8264 4.54167 14.6458L8.66667 10.5L4.54167 6.375C4.36111 6.19444 4.27083 5.97222 4.27083 5.70833C4.27083 5.44444 4.36111 5.22222 4.54167 5.04167C4.70833 4.86111 4.92361 4.77083 5.1875 4.77083C5.45139 4.77083 5.68056 4.86111 5.875 5.04167L10 9.1875L14.1458 5.02083C14.3264 4.84028 14.5451 4.75 14.8021 4.75C15.059 4.75 15.2847 4.84028 15.4792 5.02083C15.6597 5.21528 15.75 5.44097 15.75 5.69792C15.75 5.95486 15.6597 6.17361 15.4792 6.35417L11.3333 10.4792L15.4583 14.625C15.6528 14.8194 15.75 15.0451 15.75 15.3021C15.75 15.559 15.6528 15.7778 15.4583 15.9583C15.2778 16.1528 15.0556 16.25 14.7917 16.25C14.5278 16.25 14.3125 16.1528 14.1458 15.9583L10 11.8333Z" fill="#A7A7A7"/>
                                </svg> :
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.6">
                                        <path d="M9.33597 16.125C7.41412 16.125 5.7985 15.4691 4.4891 14.1574C3.17972 12.8457 2.52502 11.2499 2.52502 9.36993C2.52502 7.48997 3.18088 5.89583 4.4926 4.5875C5.80432 3.27917 7.40015 2.625 9.2801 2.625C11.16 2.625 12.7542 3.28037 14.0625 4.5911C15.3709 5.90183 16.025 7.49772 16.025 9.37875C16.025 10.1429 15.9167 10.8375 15.7 11.4625C15.4834 12.0875 15.1667 12.6833 14.75 13.25L20.675 19.125C20.8917 19.3509 21 19.6281 21 19.9564C21 20.2847 20.8917 20.5618 20.675 20.7875C20.4361 21.0125 20.1591 21.125 19.8439 21.125C19.5287 21.125 19.2558 21.0083 19.025 20.775L13.1618 14.9C12.654 15.2821 12.0795 15.5817 11.4385 15.7991C10.7976 16.0164 10.0967 16.125 9.33597 16.125ZM9.28605 13.85C10.5525 13.85 11.6131 13.4205 12.4678 12.5615C13.3226 11.7024 13.75 10.6437 13.75 9.3853C13.75 8.12688 13.3221 7.06473 12.4664 6.19883C11.6106 5.33294 10.5512 4.9 9.28825 4.9C8.01138 4.9 6.94412 5.33294 6.08647 6.19883C5.22884 7.06473 4.80002 8.12688 4.80002 9.3853C4.80002 10.6437 5.22762 11.7024 6.0828 12.5615C6.938 13.4205 8.00575 13.85 9.28605 13.85Z" fill="white"/>
                                    </g>
                                </svg>                                    
                            }
                        </button>
                    </form>
                
                    <ul className="categories-items mb-48">
                        {
                            localCategories.map((item, index) =>{
                                return <li key={item+'-'+index+rerenderCatKey} className="animate__animated animate__fadeIn" style={{animationDelay: `${0.05 * index}s`}}>
                                    <a 
                                        href="#" className={`category-item ${selectedCategories.includes(item) ? 'active' : ''}`} 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            // selectedCategories === item ? '' : item
                                            setSelectedCategories((prevState)=>{
                                                if(prevState.includes(item)){
                                                    return prevState.filter(itemF => itemF !== item)
                                                } else {
                                                    return [...prevState, item]
                                                }
                                            });
                                            // setUpdatedTimes((prevState) => prevState + 1);
                                            setSearch('');
                                            setSelectedCardId(null);
                                        }}
                                    >   
                                        { item }
                                        <span className='min-w-[16px]'>{ [...localCardsContent].filter(item2 => item2?.categories.includes(item)).length }</span>
                                    </a>
                                </li>
                            })
                        }
                    </ul>
                </div>
                {/* <InfiniteScroll
                    dataLength={localCardsContent.length}
                    next={fetchMoreData}
                    hasMore={true}
                    // loader={<h4>Loading...</h4>}
                > */}
                    <div className="documents-cards-wrpr">
                    {
                        localCardsContent.map((item, index) =>{
                            const noBage = ['1', '2', '3', '5', '4', '6', '7', '8', '9', '-lesson-', '11', '12', '13', '14', '15']
                            const needBage = noBage.includes(item.id)

                            return <Link
                                to={item.type ? `/generated-content?type=${item.type}` : '/'} className="documents-card animate__animated animate__fadeIn relative" key={item.title+'-'+index+rerenderKey}
                                style={{animationDelay: `${0.05 * index}s`, overflow: 'hidden'}}
                            >
                                {!needBage && <div className='coming-soon-badge'>Coming soon</div>}
                                <div className="icon-wrpr">
                                    {
                                        item?.icon ? item?.icon : 
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="1">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M31.77 20.8417C32.89 19.7217 34.7066 19.72 35.8266 20.8417V20.8417C36.9466 21.9617 36.9466 23.7783 35.825 24.8983L26.2016 34.5133C25.89 34.825 25.4666 35 25.025 35H21.6666V31.6417C21.6666 31.2 21.8416 30.7767 22.155 30.4633L31.77 20.8417V20.8417Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.6666 15H25" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.6666 21.6667H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.6666 28.3333H16.6666" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M33.7 27.0333L29.6333 22.9667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M31.6667 15V10C31.6667 8.15834 30.175 6.66667 28.3333 6.66667H8.33333C6.49167 6.66667 5 8.15834 5 10V31.6667C5 33.5083 6.49167 35 8.33333 35H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M25 5V8.33333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M18.3333 5V8.33333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.6667 5V8.33333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                        </svg>
                                    }
                                </div>
                                <h3>{ item.title }</h3>
                                <p>{ item.description }</p>
                            </Link>
                        })
                    }
                    </div>
                {/* </InfiniteScroll> */}
                {/* {
                    searchLoading ? 
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div> :
                    <>

                    </>
                } */}
            </div>
        </div>

    )
}

export default ProductDescription