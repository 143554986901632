import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import api from '../../api';
import ButtonTooltip from '../GeneratedContent/Blog/ButtonTooltip';

export default function PrivateButton({ item, fetchData }) {
    const [localIsPrivate, setLocalIsPrivate] = useState(!!item.is_private);
    console.log(item.word, localIsPrivate)
    const token = useSelector(getToken);

    const handleClick = async () => {
        try {
            const response = await api.newWords.updateNewWord({
                id: item.id,
                body: {
                    is_private: !localIsPrivate
                },
                token
            });

            if (fetchData) {
                fetchData();
            }

            if (!response.success) return;
            setLocalIsPrivate(response.data.is_private);
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className='flex items-center gap-[5px]'>
            <button
                className="d-block"
                onClick={handleClick}
            >
                {localIsPrivate ? 'Make Public' : 'Make Private'}
            </button>
            <ButtonTooltip tooltip="Make this new word private and do not include It in the New Word Gallery.">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M7.00019 7.41542L7.7746 6.98518C8.16108 6.7705 8.40078 6.36316 8.40078 5.92106C8.355 5.18588 7.7245 4.62543 6.98902 4.66615C6.33184 4.63887 5.74827 5.08311 5.59961 5.72383" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round" />
                    <circle cx="7.00023" cy="7.00023" r="5.25219" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.05913 9.27624C7.05909 9.30845 7.03296 9.33454 7.00075 9.33452C6.96854 9.33451 6.94243 9.3084 6.94241 9.27619C6.9424 9.24398 6.96849 9.21785 7.0007 9.21781C7.0162 9.21779 7.03107 9.22394 7.04204 9.2349C7.053 9.24586 7.05915 9.26074 7.05913 9.27624" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </ButtonTooltip>
        </div>
    )
}
