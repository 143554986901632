import Tippy from '@tippyjs/react'
import React, { useEffect, useRef } from 'react'

const EditorHtmlTollbar = ({setCardFullScreen, isFullScreen, toolbarRef} ) => {
    const barRef = useRef()
    useEffect(() => {
        if(barRef.current) {
            toolbarRef.current = barRef.current
        }
    }, [barRef])
    return (
        <>
            <div ref={barRef}></div>
            <Tippy
                offset={[0, 4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Expand'
            >
                <button
                    onClick={() => setCardFullScreen(isFullScreen ? false : true)}
                    className="blog-generator-button"
                >
                    {isFullScreen ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M2.83125 16.5939C2.57187 16.5939 2.36328 16.515 2.20547 16.3572C2.04766 16.1994 1.96875 15.9908 1.96875 15.7314V11.2502C1.96875 11.0152 2.05224 10.8127 2.21921 10.6427C2.38619 10.4727 2.58494 10.3877 2.81546 10.3877C3.04599 10.3877 3.24687 10.4727 3.41812 10.6427C3.58937 10.8127 3.675 11.0152 3.675 11.2502V13.6689L13.125 4.21895H10.6875C10.4578 4.21895 10.2598 4.13546 10.0933 3.96848C9.92695 3.80151 9.84375 3.60276 9.84375 3.37223C9.84375 3.14171 9.92695 2.94082 10.0933 2.76957C10.2598 2.59832 10.4578 2.5127 10.6875 2.5127H15.1875C15.44 2.5127 15.6469 2.59332 15.8081 2.75457C15.9694 2.91582 16.05 3.1227 16.05 3.3752V7.8752C16.05 8.10488 15.9647 8.30293 15.7941 8.46934C15.6234 8.63574 15.4201 8.71895 15.1842 8.71895C14.9536 8.71895 14.7559 8.63574 14.591 8.46934C14.4262 8.30293 14.3437 8.10488 14.3437 7.8752V5.4377L4.89375 14.8877H7.3125C7.5475 14.8877 7.75 14.973 7.92 15.1436C8.09 15.3143 8.175 15.5176 8.175 15.7535C8.175 15.9841 8.09 16.1818 7.92 16.3467C7.75 16.5115 7.5475 16.5939 7.3125 16.5939H2.83125Z" fill="#FF8114" />
                    </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <g opacity="0.6">
                                <path d="M2.83125 16.5939C2.57187 16.5939 2.36328 16.515 2.20547 16.3572C2.04766 16.1994 1.96875 15.9908 1.96875 15.7314V11.2502C1.96875 11.0152 2.05224 10.8127 2.21921 10.6427C2.38619 10.4727 2.58494 10.3877 2.81546 10.3877C3.04599 10.3877 3.24687 10.4727 3.41812 10.6427C3.58937 10.8127 3.675 11.0152 3.675 11.2502V13.6689L13.125 4.21895H10.6875C10.4578 4.21895 10.2598 4.13546 10.0933 3.96848C9.92695 3.80151 9.84375 3.60276 9.84375 3.37223C9.84375 3.14171 9.92695 2.94082 10.0933 2.76957C10.2598 2.59832 10.4578 2.5127 10.6875 2.5127H15.1875C15.44 2.5127 15.6469 2.59332 15.8081 2.75457C15.9694 2.91582 16.05 3.1227 16.05 3.3752V7.8752C16.05 8.10488 15.9647 8.30293 15.7941 8.46934C15.6234 8.63574 15.4201 8.71895 15.1842 8.71895C14.9536 8.71895 14.7559 8.63574 14.591 8.46934C14.4262 8.30293 14.3437 8.10488 14.3437 7.8752V5.4377L4.89375 14.8877H7.3125C7.5475 14.8877 7.75 14.973 7.92 15.1436C8.09 15.3143 8.175 15.5176 8.175 15.7535C8.175 15.9841 8.09 16.1818 7.92 16.3467C7.75 16.5115 7.5475 16.5939 7.3125 16.5939H2.83125Z" fill="white" />
                            </g>
                        </svg>}
                </button>
            </Tippy>
        </>
    )
}

export default EditorHtmlTollbar
