import { useQuill } from "react-quilljs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSocket, getToken, setUser, } from "../../../redux/slices/applicationSlice";
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { getActiveBlogIndex, getBlogGeneratingContent, getGeneratedBlogs, setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogGeneratingContent, setBlogSelectedVersionId, setGenerateLoading } from "../../../redux/slices/blogGeneratorSlice";

import { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import EditorToolbar from "../Blog/EditorToolbar";
import DefaultToolbar from "../Blog/DefaultToolbar";
import EditorControl from "../Blog/EditorControl";
import BlogVariantKeywords from "../Blog/BlogVariantKeywords";
import { toast } from 'react-toastify';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';
import AddEditorChartModal from "../../../components/parts/AddEditorChartModal";

const ReleaseCardContent = ({ index, isFullScreen, setCardFullScreen, setUpdateBlogs, markup, isNotDone = false }) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const blogs = useSelector(getGeneratedBlogs);
    const socket = useSelector(getSocket);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const generatingContent = useSelector(getBlogGeneratingContent);
    const { activeVersionIndex, versions, selectedVersionId } = blogs[activeBlogIndex] || {};
    const isSelected = selectedVersionId === versions[activeVersionIndex]?.id;
    const { id } = useParams();
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
            toolbar: '#toolbar' + index,
            history: {
                delay: 2000,
                userOnly: true
            },
        },
        formats: [
            'bold', 'italic', 'underline', 'align', 'link', 'image', 'video',
            'color', 'background', 'header', 'font', 'size',
            'clean', 'imageBlot'
        ],
    });
    useEffect(() => {
        if (!quill || !markup) return;
        quill.disable();
        // quill.setText(generatingContent || markup);

        // quill.clipboard.dangerouslyPasteHTML(generatingContent || markup, 'api');
        const contentToEditor = generatingContent || markup;
        const delta = quill.clipboard.convert(contentToEditor);
        quill.setContents(delta);
    }, [quill, markup, generatingContent]);

    const [showHTMLcontent, setShowHTMLcontent] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    useEffect(() => {
        if (!quill) return;
        if (isEdit) {
            quill.enable();
        } else {
            quill.disable();
        }
    }, [quill, isEdit]);
    const [editorActiveButton, setEditorActiveButton] = useState(null);
    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);
    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [customPrompt, setCustomPrompt] = useState('');
    const [editBlogLoading, setEditBlogLoading] = useState(false);

    const [initioaChartData, setInitioaChartData] = useState(null)
    useEffect(() => {
        if (isEdit) {
            const handelClick = ({ target }) => {
                if (target.classList.contains('chart-img')) {
                    target.classList.add('selected-chart')
                    setEditorActiveButton('chart')
                    setInitioaChartData(JSON.parse(target.getAttribute('data-chart')))
                }
            }
            document.addEventListener('dblclick', handelClick)
            return () => {
                document.removeEventListener('dblclick', handelClick)
            }
        }
    }, [isEdit])
    // const changeIsEditAction = (value) => {
    //     quill.setText(generatingContent || markup);

    //     if(value){
    //         setIsEdit(true);

    //         setTimeout(() => {
    //             setShowHTMLcontent(false);
    //         }, 30);
    //     } else {
    //         setIsEdit(false);
    //         setShowHTMLcontent(true);
    //     }
    // }

    const editBlog = async ({ operation, isActive, customPayload = {} }) => {
        let checkRes = await checkUserHaveCredits(token);
        if (!checkRes?.success) {
            dispatch(setUser(checkRes?.data));
            return;
        }

        dispatch(setGenerateLoading(false));
        setEditBlogLoading(true);

        // console.log('edit', versions[activeVersionIndex]?.content);

        // return
        socket.send({
            event: 'press-release-edit',
            payload: {
                content: versions[activeVersionIndex]?.content,
                operation,
                ...customPayload
            },
            handler: (response) => {
                if (response?.isError) {
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                dispatch(setBlogGeneratingContent(response.payload));
                const { firstChild } = quillRef.current;
                firstChild.scroll({ top: firstChild.scrollHeight, behavior: 'smooth' });
                if (response.isDone) {
                    dispatch(setBlogGeneratingContent(null));
                    isActive ? dispatch(setBlogActiveContent(response.payload)) : dispatch(setBlogContent(response.payload));
                    setUpdateBlogs(true);
                    setEditBlogLoading(false);
                    dispatch(getUserUpdateThunk({ token }));

                    return;
                }

            }
        })
    }
    const wordsCount = markup?.split(' ').filter(word => word.length > 0).length;
    const imageButtonRef = useRef();
    return <>
        <div
            id={'toolbar' + index}
            className={
                classNames("editor-toolbar-column-wrpr flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px] lg:min-h-[40px] ql-toolbar ql-snow",
                    isNotDone ? 'block-disabled' : '')
            }
            style={{ display: isEdit ? 'flex' : 'none' }}
        >
            {/* <button
                onClick={() => {
                    changeIsEditAction(false);
                }}
                className={classNames('blog-generator-button label fill text-[#A7A7A7]')}
                style={{ width: "initial" }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M14.8688 5.60674L12.5063 3.26299L13.2375 2.51299C13.475 2.27549 13.7656 2.15674 14.1094 2.15674C14.4531 2.15674 14.7625 2.28174 15.0375 2.53174L15.6188 3.11299C15.8938 3.37549 16.0156 3.67861 15.9844 4.02236C15.9531 4.36611 15.8188 4.65674 15.5813 4.89424L14.8688 5.60674ZM3.1125 15.8817C2.85 15.8817 2.64062 15.8005 2.48438 15.638C2.32813 15.4755 2.25 15.2755 2.25 15.038V13.8755C2.25 13.7755 2.275 13.6661 2.325 13.5474C2.375 13.4286 2.43125 13.338 2.49375 13.2755L11.7375 4.05049L14.1 6.37549L4.89375 15.6192C4.79375 15.6942 4.6875 15.7567 4.575 15.8067C4.4625 15.8567 4.35625 15.8817 4.25625 15.8817H3.1125Z" fill="#A7A7A7"/>
                </svg>
                Close Edit Manually
            </button> */}


            <EditorToolbar
                isEdit={isEdit}
                quill={quill}
                activeButton={editorActiveButton}
                setActiveButton={setEditorActiveButton}
                setImagePopupOpened={setImagePopupOpened}
                setImageLinkOpened={setImageLinkOpened}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                imageButtonRef={imageButtonRef}
            />
        </div>
        <div
            className={classNames("default-toolbar-wrpr flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px]",
                isEdit ? 'hidden' : 'flex')}
        >
            <DefaultToolbar
                disabled={isNotDone || editBlogLoading}
                footerActions={footerActions}
                setBlogIsEdit={setIsEdit}
                setFooterActions={setFooterActions}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                hiddenButtons={["Emojis", "List"]}
            />
        </div>

        <pre
            className={classNames(!showHTMLcontent ? 'flex' : 'flex', "p-[20px] overflow-y-auto no-scroll max-w-[calc(100vw-106px)] lg:max-w-full", versions?.length > 1 ? 'mb-[56px]' : 'mb-[20px]')}
            // className={classNames(isEdit ? 'flex overflow-y-auto' : 'hidden')}
            ref={quillRef}
        // style={{display: isEdit ? 'block': 'none'}}
        />
        {/* <div
            className={classNames(!showHTMLcontent ? 'hidden' : 'flex', "p-[20px] overflow-y-auto no-scroll", versions?.length > 1 ? 'mb-[56px]' : 'mb-[20px]')}
            dangerouslySetInnerHTML={{ __html: markup }}
        ></div> */}

        <div
            // className={classNames('blog-generator-card-footer', isOpen || isFullScreen ? 'block' : 'hidden')}
            className='blog-generator-card-footer'
        >
            {isEdit ? <EditorControl
                activeButton={editorActiveButton}
                closeEditor={() => setIsEdit(false)}
                imageLinkOpened={imageLinkOpened}
                quill={quill}
                setActiveButton={setEditorActiveButton}
                setImageLinkOpened={setImageLinkOpened}
                setUpdateBlogs={setUpdateBlogs}
            // onlyInnerTextEmit={true}
            /> : footerActions.keyword ? <BlogVariantKeywords
                data={versions[activeVersionIndex]}
                setUpdateBlogs={setUpdateBlogs}
                editBlog={editBlog}
                setFooterActions={setFooterActions}
            /> : footerActions.customPrompt ? <div>
                <div className='flex gap-[10px]'>
                    <input
                        className='bg-inherit h-[32px] text-[13px] flex-1 pl-[12px] pr-[8px]
                        border border-solid border-[#333] rounded-[8px]'
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                    />
                    <button
                        className='goal-generator-action btn--primary-gradient btn'
                        style={{
                            height: 32,
                            padding: '0 12px',
                            fontSize: 13
                        }}
                        onClick={() => {
                            dispatch(setBlogActiveContent(null));
                            dispatch(setBlogActiveButton(null));
                            editBlog({
                                operation: 'custom',
                                isActive: false,
                                customPayload: {
                                    data: customPrompt
                                }
                            });
                            setFooterActions({
                                keyword: false,
                                customPrompt: false
                            });
                        }}
                        disabled={!customPrompt}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Apply
                    </button>
                </div>
            </div> : versions?.length > 1 ? <div className='flex justify-between items-center'>
                <ul className='flex gap-[8px]'>
                    {versions.map((value, index) => <li
                        key={index}
                        className={classNames(`rounded-[8px] border border-solid border-[#333333] bg-[#1B1B1B]
                        p-[10px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7] cursor-pointer hover:text-white`, {
                            'border-[#FF8114] text-white': index === activeVersionIndex
                        })}
                        onClick={() => dispatch(setBlogActiveVersionIndex(index))}
                    >
                        <span className="capitalize">{value?.pageName || value?.title}</span>
                    </li>)}
                </ul>
                <div className='flex gap-[16px] items-center'>
                    <span className='text-[13px] opacity-30'>
                        {wordsCount} words
                    </span>
                    {isSelected ? <button
                        className='word-selected-button'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_4010_73587)">
                                <path d="M6.9834 9.1168L5.66673 7.78346C5.52451 7.65013 5.34945 7.58346 5.14155 7.58346C4.93365 7.58346 4.75315 7.65291 4.60007 7.7918C4.45562 7.9418 4.3834 8.12235 4.3834 8.33346C4.3834 8.54458 4.46118 8.71124 4.61673 8.83346L6.45007 10.6835C6.58643 10.839 6.76219 10.9168 6.97733 10.9168C7.19249 10.9168 7.37784 10.839 7.5334 10.6835L11.3167 6.90013C11.4723 6.75791 11.5501 6.58563 11.5501 6.38328C11.5501 6.18094 11.4732 5.99766 11.3194 5.83346C11.1656 5.70013 10.9817 5.63624 10.7676 5.6418C10.5534 5.64735 10.3754 5.7168 10.2334 5.85013L6.9834 9.1168ZM8.00237 15.0168C7.03817 15.0168 6.12647 14.8357 5.26728 14.4734C4.40808 14.1111 3.66043 13.612 3.02433 12.9759C2.38823 12.3398 1.88905 11.5929 1.5268 10.7352C1.16453 9.87756 0.983398 8.96663 0.983398 8.00243C0.983398 7.03539 1.16485 6.12379 1.52777 5.26765C1.89069 4.4115 2.39078 3.66431 3.02805 3.02608C3.66533 2.38784 4.41174 1.88506 5.26728 1.51775C6.12284 1.15045 7.03254 0.966797 7.99638 0.966797C8.96424 0.966797 9.8771 1.14992 10.735 1.51618C11.5928 1.88244 12.3404 2.38386 12.9776 3.02046C13.6149 3.65706 14.1168 4.40401 14.4834 5.2613C14.8501 6.1186 15.0334 7.032 15.0334 8.00151C15.0334 8.97033 14.8497 9.88145 14.4824 10.7349C14.1151 11.5883 13.6124 12.3339 12.9741 12.9715C12.3359 13.6092 11.5895 14.1095 10.7348 14.4724C9.88024 14.8353 8.96941 15.0168 8.00237 15.0168Z" fill="#331A04" />
                            </g>
                            <defs>
                                <clipPath id="clip0_4010_73587">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Selected
                    </button> : <button
                        className='rounded-full border border-solid border-[#333333] bg-[#1B1B1B]
                        px-[12px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7]'
                        onClick={() => {
                            dispatch(setBlogSelectedVersionId(versions[activeVersionIndex].id));
                            setUpdateBlogs(true);
                        }}
                    >
                        Select Version
                    </button>}
                </div>
            </div> : null}
        </div>
        {imagePopupOpened && <div className='generated-blog-popup'>
            <button
                onClick={() => {
                    imageButtonRef.current.click();
                    setImagePopupOpened(false);
                    setEditorActiveButton(null);
                }}
                className='py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="1.5" y="1.5" width="21" height="21" rx="5" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.3928 16.6596L19.2412 13.508C18.7856 13.0524 18.0469 13.0524 17.5913 13.508L14.9163 16.1831L9.90791 11.1747C9.4523 10.7191 8.71361 10.7191 8.258 11.1747L1.86426 17.5684" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.7094 7.71081L14.7099 7.7103C14.8237 7.59419 15.01 7.59222 15.1262 7.70589C15.2423 7.81957 15.2444 8.00588 15.1308 8.12213C15.0172 8.23838 14.8309 8.24057 14.7146 8.12704C14.5983 8.01351 14.596 7.82719 14.7094 7.71081" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='mt-[20px] text-[13px] font-medium opacity-90'>Upload Image</p>
                <p className='mt-[12px] text-[12px] font-light opacity-50'>Add image from your computer</p>
            </button>
            <button
                onClick={() => {
                    setImageLinkOpened(true);
                    setImagePopupOpened(false);
                }}
                className='py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020]'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M19.2239 16.2887L21.7811 13.7315C23.8119 11.6495 23.7911 8.32164 21.7346 6.26509C19.6781 4.20855 16.3502 4.18781 14.2682 6.21856L11.7109 8.77579" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.1123 10.8867L10.8867 17.1123" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.77579 11.7109L6.21856 14.2682C4.18781 16.3502 4.20855 19.6781 6.26509 21.7346C8.32164 23.7911 11.6495 23.8119 13.7315 21.7811L16.2887 19.2239" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='mt-[20px] text-[13px] font-medium opacity-90'>Add Image URL</p>
                <p className='mt-[12px] text-[12px] font-light opacity-50'>Paste image link to add picture</p>
            </button>
        </div>}
        {editorActiveButton === 'chart' &&
            <AddEditorChartModal
                handelClose={() => {
                    setEditorActiveButton(null);
                    setInitioaChartData(null)
                }}
                isOpened={editorActiveButton === 'chart'}
                editor={quill}
                initialData={initioaChartData}
            />
        }
    </>
};

export default ReleaseCardContent;