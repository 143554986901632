import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

const initialState = {
    generateLoading: false,
    blogs: [],
    activeBlogIndex: 0,
    generatingContent: null
}

const blogGeneratorSlice = createSlice({
    name: 'blogGenerator',
    initialState,
    reducers: {
        setBlogs: (state, action) => {
            state.blogs = action.payload;
        },
        setGenerateLoading: (state, action) => {
            state.generateLoading = action.payload;
        },
        setActiveBlogIndex: (state, action) => {
            state.activeBlogIndex = action.payload; 
        },

        setBlogVersions: (state, action) => {
            if(!state.blogs[state.activeBlogIndex]) {
                state.blogs[state.activeBlogIndex] = {}
            }
            state.blogs[state.activeBlogIndex].versions = Object.values(action.payload).map(value => ({
                language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
                tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
                ...value
            })); 
        },
        setBlogVersionsData: (state, action) => {
            if(!state.blogs[state.activeBlogIndex]) {
                state.blogs[state.activeBlogIndex] = {}
            }
            state.blogs[state.activeBlogIndex].versions = action.payload.map((value, index) => ({
                language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
                tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
                id: state.blogs?.[state?.activeBlogIndex]?.versions?.[index]?.id || v4(),
                ...value
            })); 
        },
        setBlogVersion: (state, action) => {
            if(!state.blogs[state.activeBlogIndex]) {
                state.blogs[state.activeBlogIndex] = {}
            }
            // state.blogs[state.activeBlogIndex].versions = Object.values(action.payload).map(value => ({
            //     language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
            //     tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
            //     ...value
            // }));
            if(!state.blogs[state.activeBlogIndex].versions[action.payload.index]) {
                state.blogs[state.activeBlogIndex].versions[action.payload.index] = {}
            }
            state.blogs[state.activeBlogIndex].versions[action.payload.index] = {
                ...action.payload.data,
                language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
                tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
            }
        },
        setBlogVersionAddon: (state, action) => {
            state.blogs[state.activeBlogIndex].addonsValues[action.payload.index] = action.payload.data;
        },
        deleteActiveBlogVersion: (state, action) => {
            let { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            versions.splice(activeVersionIndex, 1);
            if(versions.length === 0) {
                state.blogs.splice(state.activeBlogIndex, 1);
                return;
            }
            if(activeVersionIndex === versions.length) {
                state.blogs[state.activeBlogIndex].activeVersionIndex = 0;
            }
        },
        copyActiveBlogVersion: (state, action) => {
            let { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            versions.push(versions[activeVersionIndex]);
        },
        addBlogVersion: (state, action) => {
            let activeBlog = state.blogs[state.activeBlogIndex];
            const newLen = activeBlog.versions.push({
                title: '',
                content: '',
                isDone: true,
                addons: {}
            });
            activeBlog.activeVersionIndex = newLen - 1;
        },
        setBlogActiveVersionIndex: (state, action) => {
            state.blogs[state.activeBlogIndex].activeVersionIndex = action.payload;
        },
        setBlogAddonsValues: (state, action) => {
            state.blogs[state.activeBlogIndex].addonsValues = action.payload;
        },
        setBlogAdd_Ons: (state, action) => {
            let updatesBlogs = JSON.parse(JSON.stringify(state.blogs));
            updatesBlogs.forEach((item)=>{
                item.add_ons = action.payload;
            });
            state.blogs = updatesBlogs;
        },
        setBlogAddonVersion: (state, action) => {
            let updatesBlogs = JSON.parse(JSON.stringify(state.blogs));

            if(action.payload?.onlyAddonGenerate){
                if(action.payload?.kit === "landing-entire" || action.payload?.kit === "general" || action.payload?.kit === "etsy" || action.payload?.kit === "product-description" || action.payload?.kit === "press-release"){
                    updatesBlogs[action.payload.blogIndex].addonsValues[action.payload.versionIndex][action.payload.key] = action.payload.value;
                } else {
                    updatesBlogs[action.payload.blogIndex].versions[action.payload.versionIndex].addons[action.payload.key] = action.payload.value;
                }

                state.blogs = updatesBlogs;
                return; 
            }
            
            if(updatesBlogs?.[action.payload.blogIndex]?.versions[action.payload?.forceVersionIndex || state?.activeBlogIndex]?.addons?.[action.payload?.key]){
                updatesBlogs[action.payload.blogIndex].versions[action.payload?.forceVersionIndex || state.activeBlogIndex].addons[action.payload.key] = action.payload.value;
            } else if(updatesBlogs?.[action.payload.blogIndex || 0].versions[0].addons?.[action.payload?.key]) {
                updatesBlogs[action.payload.blogIndex || 0].versions[0].addons[action.payload.key] = action.payload.value;
            } else if(updatesBlogs?.[action.payload.blogIndex].addonsValues?.[action.payload.index]?.[action.payload?.key]){
                updatesBlogs[action.payload.blogIndex].addonsValues[action.payload.index][action.payload.key] = action.payload.value;
            } else if(updatesBlogs?.[action.payload.blogIndex]?.addonsValues?.[0]?.[action.payload?.key]) {
                updatesBlogs[action.payload.blogIndex].addonsValues[0][action.payload.key] = action.payload.value;
            }

            state.blogs = updatesBlogs;
        },
        setEtsyAddonVersion: (state, action) => {
            let updatesBlogs = JSON.parse(JSON.stringify(state.blogs));

            if(updatesBlogs?.[action.payload.blogIndex].addonsValues?.[action.payload.activeVersionIndex]?.[action.payload.key]){
                updatesBlogs[action.payload.blogIndex].addonsValues[action.payload.activeVersionIndex][action.payload.key] = action.payload.value;
            } else if(updatesBlogs?.[action.payload.blogIndex || 0].addonsValues?.[action.payload.activeVersionIndex || 0]?.[action.payload.key]){
                updatesBlogs[action.payload.blogIndex || 0].addonsValues[action.payload.activeVersionIndex || 0][action.payload.key] = action.payload.value;
            }

            state.blogs = updatesBlogs;
        },
        setBlogContent: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].content = action.payload;
        },
        setBlogActiveContent: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].activeContent = action.payload;
        },
        setBlogActiveButton: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].activeButton = action.payload;
        },
        setBlogIsEdit: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].isEdit = action.payload;
        },
        setBlogTone: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].tone = action.payload;
        },
        setBlogLanguage: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].language = action.payload;
        },
        setBlogDone: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            if(activeBlog?.done){
                activeBlog.done = action.payload;
            }
        },
        setBlogSelectedVersionId: (state, action) => {
            state.blogs.forEach(blog => {
                blog.selectedVersionId = action.payload;
            })
        },
        addBlogKeyword: (state, action) => {
            const { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            if(!versions[activeVersionIndex].keywords) versions[activeVersionIndex].keywords = []
            versions[activeVersionIndex].keywords.push(action.payload);
        },
        deleteBlogKeyword: (state, action) => {
            const { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            if(!versions[activeVersionIndex].keywords) return;
            versions[activeVersionIndex].keywords = versions[activeVersionIndex].keywords.filter((keyword) => keyword !== action.payload);
        },
        setBlogGeneratingContent: (state, action) => {
            state.generatingContent = action.payload;
        },
        setFullAddonsValues: (state, action) => {
            state.blogs[state.activeBlogIndex].addonsValues = action.payload
        },
        setNewVersionEtsy: (state, action) => {
            const activeVersion = state.blogs[state.activeBlogIndex].activeVersionIndex;
        
            const updatedVersion = {...state.blogs[state.activeBlogIndex].versions[activeVersion]};

            delete updatedVersion.title;
            delete updatedVersion.description;
            delete updatedVersion.research;
            delete updatedVersion.hash_tags;
        
            state.blogs[state.activeBlogIndex].versions[activeVersion] = {...updatedVersion, ...action.payload};
        },
        updateVersionEtsy: (state, action) => {
            const activeVersion = state.blogs[state.activeBlogIndex].activeVersionIndex;
        
            const updatedVersion = {...state.blogs[state.activeBlogIndex].versions[activeVersion]};
        
            state.blogs[state.activeBlogIndex].versions[activeVersion] = {...updatedVersion, ...action.payload};
        },
        resetBlogGenerator: (state) => {
            state.generateLoading = false;
            state.blogs = [];
            state.activeBlogIndex = 0;
            state.generatingContent = null;
        }
    }
})


export const {
    addBlogKeyword, setBlogLanguage, setBlogTone, setBlogActiveButton, 
    setBlogContent, setBlogVersions, setBlogs, setActiveBlogIndex, setBlogActiveContent, 
    setBlogActiveVersionIndex, deleteBlogKeyword, deleteActiveBlogVersion, copyActiveBlogVersion,
    setBlogIsEdit, setBlogGeneratingContent, setBlogDone, setGenerateLoading, setBlogSelectedVersionId,
    setBlogAddonsValues, setBlogAdd_Ons, setBlogAddonVersion, setEtsyAddonVersion, resetBlogGenerator, setBlogVersion, setBlogVersionAddon,
    setBlogVersionsData, setFullAddonsValues, setNewVersionEtsy, updateVersionEtsy, addBlogVersion
} = blogGeneratorSlice.actions

export const getGeneratedBlogs = (state) => state.blogGenerator.blogs;
export const getGenerateLoading = (state) => state.blogGenerator.generateLoading;
export const getActiveBlogIndex = (state) => state.blogGenerator.activeBlogIndex;
export const getBlogGeneratingContent = (state) => state.blogGenerator.generatingContent;
export const getBlogActiveVersion = ({blogGenerator: {blogs, activeBlogIndex}}) => {
    const activeBlog = blogs[activeBlogIndex];
    return activeBlog.versions[activeBlog.activeVersionIndex];
}

export default blogGeneratorSlice.reducer