import React, { useEffect, useState, createRef, useRef } from 'react'
import Select, {
    components,
    ControlProps,
    Props,
    StylesConfig,
  } from 'react-select';
import CreatableSelect  from 'react-select';
import { ReactComponent as DropDownIcon } from '../../assets/img/icons/icon-arrow_drop_down.svg';

const SelectBox = ({
    type, identifier, propsOptions, change, value=null, profileName = "Jackson Smith", menuPortalTarget, menuPosition, className='',
    isSearchable = false, isMulti = false, placeholder, InputTextarea = false, addOptionEvent, icon
}) => {
    const selectRef = createRef();

    let controlComponent = {
        Control: ({ children, ...props }) => {
            return(
                <components.Control {...props}>
                    { icon ? icon : null }
                    { children }

                    <span className="select-selection-arrow-wrpr" role="presentation"><b role="presentation"></b></span>
                </components.Control>
            )
        }
    }

    let selectComponents = {
        DropdownIndicator:({ children, ...props })=>{
            return(
                <components.DropdownIndicator {...props} className="select-selection-arrow" >
                    <DropDownIcon />
                </components.DropdownIndicator>
            )
        },
        Option:({ children, ...props })=>{
            let item = props.data;
            return(
                item.placeholder ? null : 
                <components.Option {...props} className={`select2-results__option ${props.isFocused || props.isSelected ? 'select2-results__option--selected' : ''} ${props.isSelected ? 'option-selected' : ''}`}>
                    {children} 
                </components.Option>
            )
        },
        IndicatorSeparator:()=>{
            return null
        },
        GroupHeading:({ children, ...props })=>{
            return(
                <components.GroupHeading {...props} className="select-options-group-title" >
                    {children}
                </components.GroupHeading>
            )
        },
        Group:({ children, ...props })=>{
            return(
                <components.Group {...props} className="select-options-group" >
                    {children}
                </components.Group>
            )
        },
        MenuPortal:({ children, ...props })=>{
            const menuPortalRef = createRef(null);

            return(
                <components.MenuPortal {...props} style={{ zIndex: '999' }} className="select-menu-portal">
                    <div ref={menuPortalRef}>
                        {children}
                    </div>
                </components.MenuPortal>
            )
        },
        MenuList:({ children, ...props })=>{
            const menuPortalRef2 = createRef(null);

            return(
                <components.MenuList {...props} className="select-menu-list">
                    <div ref={menuPortalRef2}>
                        {children}
                    </div>
                </components.MenuList>
            )
        },
        MultiValueContainer:({ children, ...props })=>{
            return(
                <components.MultiValueContainer {...props} className="select-multi-value-container" >
                    {children}
                </components.MultiValueContainer>
            )
        },
        MultiValue:({ children, ...props })=>{
            if (identifier === 'multi-orange') {
                return (
                    <components.MultiValue {...props} className="select-multi-value orange" >
                        {children}
                    </components.MultiValue>
                )
            }
            return(
                <components.MultiValue {...props} className="select-multi-value" >
                    {children}
                </components.MultiValue>
            )
        },
        MultiValueRemove:({ children, ...props})=>{
            if (identifier === 'multi-orange') {
                return (
                    <components.MultiValueRemove {...props} className="select-multi-value-label orange" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                            <path d="M10.5 1.41406C5.38125 1.41406 1.3125 5.48281 1.3125 10.6016C1.3125 15.7203 5.38125 19.7891 10.5 19.7891C15.6188 19.7891 19.6875 15.7203 19.6875 10.6016C19.6875 5.48281 15.6188 1.41406 10.5 1.41406ZM14.0437 15.1953L10.5 11.6516L6.95625 15.1953L5.90625 14.1453L9.45 10.6016L5.90625 7.05781L6.95625 6.00781L10.5 9.55156L14.0437 6.00781L15.0938 7.05781L11.55 10.6016L15.0938 14.1453L14.0437 15.1953Z" fill="white"/>
                        </svg>
                        {children}
                    </components.MultiValueRemove>
                )
            }
            return (
                <components.MultiValueRemove {...props} className="select-multi-value-label" >
                    {children}
                </components.MultiValueRemove>
            )
        },
        MultiValueLabel:({ children, ...props })=>{
            if (identifier === 'multi-orange') {
                return (
                    <components.MultiValueLabel {...props} className="select-multi-value-label" >
                        {children}
                    </components.MultiValueLabel>
                )
            }
            return(
                <components.MultiValueLabel {...props} className="select-multi-value-label" >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="circle-icon">
                        <g clipPath="url(#clip0_4939_52592)">
                            <path d="M7.99374 15.0168C7.03464 15.0168 6.12549 14.8357 5.26631 14.4734C4.40711 14.1111 3.65946 13.612 3.02336 12.9759C2.38726 12.3398 1.88808 11.5922 1.52582 10.733C1.16356 9.87392 0.982422 8.96372 0.982422 8.00243C0.982422 7.03121 1.16388 6.11857 1.52679 5.26451C1.88971 4.41046 2.39084 3.66372 3.03017 3.02431C3.66951 2.3849 4.41623 1.88213 5.27036 1.516C6.12448 1.14986 7.03037 0.966797 7.98802 0.966797C8.9608 0.966797 9.87683 1.14934 10.7361 1.51443C11.5954 1.87952 12.3429 2.38095 12.9788 3.01871C13.6146 3.65648 14.1158 4.40378 14.4825 5.26061C14.8491 6.11744 15.0324 7.03362 15.0324 8.00916C15.0324 8.97287 14.8494 9.88055 14.4832 10.7322C14.1171 11.5838 13.6143 12.3294 12.9749 12.9689C12.3355 13.6083 11.5887 14.1095 10.7346 14.4724C9.88041 14.8353 8.96681 15.0168 7.99374 15.0168ZM7.99527 13.5001C9.51463 13.5001 10.8118 12.9646 11.8867 11.8937C12.9616 10.8227 13.4991 9.52615 13.4991 8.00395C13.4991 6.48459 12.9629 5.18745 11.8905 4.11253C10.8181 3.0376 9.51948 2.50013 7.99457 2.50013C6.48077 2.50013 5.18641 3.03632 4.11149 4.10871C3.03656 5.18109 2.49909 6.47974 2.49909 8.00465C2.49909 9.51845 3.03457 10.8128 4.10554 11.8877C5.17649 12.9627 6.47307 13.5001 7.99527 13.5001Z" fill="white" fillOpacity="0.3"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4939_52592">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    {children}
                </components.MultiValueLabel>
            )
        },
        IndicatorsContainer:({ children, ...props })=>{
            return(
                <components.IndicatorsContainer {...props} className="indicators-container" >
                    {children}
                </components.IndicatorsContainer>
            )
        },
        Input:({ children, ...props })=>{
            return(
                InputTextarea ?
                <div className="select-input with-textarea">
                    <textarea
                        name='input-textarea'
                        value={props.value}
                        onChange={props.onChange}
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                        placeholder={placeholder ? placeholder : "Type to add a keyword or filter the keyword list"}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && addOptionEvent) {
                                addOptionEvent(props.value);
                            }
                        }}
                    ></textarea>
                </div> :
                <components.Input {...props} placeholder={placeholder ? placeholder : "Type to add a keyword or filter the keyword list"} className="select-input" >
                    {children}
                </components.Input>
            )
        },
        Placeholder:({ children, ...props })=>{
            return(
                <components.Placeholder {...props} className="select-placeholder" >
                    {children}
                </components.Placeholder>
            )
        },
    }

    let classNames = {
        control: (e) => {
            return `select-selection ${e.menuIsOpen ? 'select-selection-focused' : ''} ${e.getValue().value || value ? 'has-value' : ''}`
        },
        singleValue:(e)=>{
            return e.getValue()?.value ? 'aaa has-value' : 'aaa';
        }, 
        valueContainer:()=>'bbb',
        menu:()=>'ccc select2-dropdown select2-dropdown--below',
        // select2-dropdown select2-dropdown--below select2-container select2-container--default select2-container--open 
        container:()=>'ddd'
    }

    let options = propsOptions ? propsOptions : []

    let selectBoxValue = null;

    const [selectValue, setSelectValue] = useState(findValue(value));

    useEffect(()=>{
        setSelectValue(findValue(value))
    }, [value])
   
    function findValue (value){
        return options.find((option)=>option.value === value);
    }

    function onChange(value){
        if(change){
            change(isMulti ? value.map(item => item.value) : value.value);
        }
        setSelectValue((prev)=>value);
    }

    const defaultValue = isMulti && value && options?.length ? 
        [...options].filter(itemF => value.includes(itemF.value)) : 
        selectBoxValue ? selectBoxValue : options[0];

    return (
        <div className={`${type} ${className ? className : ''} ${isMulti ? 'multi-select' : ''}`}>
            <div style={{ width: "100%", position: 'relative' }} ref={selectRef}>
                <Select
                    defaultValue={defaultValue}
                    options={options}
                    classNames={classNames}
                    clearable={false}
                    components={{
                        ...selectComponents,
                        ...(icon ? controlComponent : {})
                    }}
                    // menuPosition="fixed"
                    // menuPlacement={type==='languages-footer'?'top':'bottom'}
                    isSearchable={isSearchable}
                    isMulti={isMulti}
                    onChange={onChange}
                    value={selectBoxValue ? selectBoxValue : value ? selectValue : undefined}
                    // menuIsOpen={true}
                    menuPortalTarget={menuPosition !== 'absolute' ? menuPortalTarget ? menuPortalTarget : document.body : null}
                    menuPosition={menuPosition ? menuPosition : 'fixed'}
                />
            </div>
        </div>
    )
}

export default SelectBox;