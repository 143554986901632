import { useParams } from "react-router-dom";
import { getImageSrc } from "../helpers/getImageSrc";
import api from "../api";


class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        // const formData = new FormData();
        return this.loader.file.then(
            (file) =>
                new Promise(async (resolve, reject) => {
                    const url = window.location.pathname;
                    const match = url.match(/\/(\d+)(\/|$)/);
                    const id = match ? match[1] : null;
                    const { data: { attachments }, success } = await api.assets.changeAsset({
                        id,
                        data: {
                            attachments: file
                        },
                        token: localStorage.getItem('authToken')
                    })
                    if (success) {
                        resolve({
                            default: getImageSrc(attachments[attachments.length - 1].path),
                        });
                    } else {
                        reject(`Couldn't upload file: ${file.name}.`);
                    }

                })

        );
    }
}

export default function ThisCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
}