import React, { Fragment, useEffect, useState, version } from 'react';
import GeneratedContentModal from '../GeneratedContentModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import logo from '../../../assets/img/gen4logo.svg';
import ChatBot from '../ChatBot';
import { navLinks } from './consts';
import SelectBox from '../../../components/base/SelectBox';
import { useSelector } from 'react-redux';
import BlogPostCard from './EtsyCard';
import api from '../../../api';
import { getActiveBlogIndex, getGeneratedBlogs, getGenerateLoading, setActiveBlogIndex, setBlogVersions, setBlogs, setBlogDone, setGenerateLoading, setBlogAddonsValues, setBlogSelectedVersionId, setBlogVersion, setBlogVersionAddon, getBlogGeneratingContent } from '../../../redux/slices/blogGeneratorSlice';
import { useDispatch } from 'react-redux';
import { getSocket, getToken, getUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";

import { toast } from 'react-toastify';
 
import { v4 } from 'uuid';
import AddonCard from './AddonCard';
import axios from 'axios';
import Popup from 'reactjs-popup';
import HelpModal from '../../../components/base/HelpModal';
import { addEssay, getEssaysGenerating, getEssaysState, getGeneratedEssays, setActiveEssayIndex, setEssayVersion, setEssays, setEssaysGenerating } from '../../../redux/slices/essayGeneratorSlice';
import EssayCard from './EtsyCard';
import { addEtsyItem, getEtsyState, setEtsyItemData, setEtsyItems } from '../../../redux/slices/etsyGeneratorSlice';
import EtsyCard from './EtsyCard';
import useScreenWidth from '../../../helpers/useScreenWidth';


const combineBlogData = (config, versions, addonsValues, index) => {
    const result = [...config];
    result[index] = {
        ...result[index],
        versions,
        addonsValues
    }
    return result
}

const EtsyGeneratedContent = () => {
    const screenWidth = useScreenWidth();
    const mobileScreen = screenWidth < 1199;

    const token = useSelector(getToken);
    const socket = useSelector(getSocket);
    const meData = useSelector(getUser);
    // const { essays, generating, activeEssayIndex } = useSelector(getEssaysState);
    // const activeEssay = essays[activeEssayIndex];
    // console.log('essays:', essays);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { items, activeItemIndex } = useSelector(getEtsyState);
    const activeItem = items[activeItemIndex];
    console.log(activeItem?.data);

    // const isNotDone = generateLoading ? blogs.filter(item => !item?.done).length : false;

    // const wordsCount = (blogs.reduce((accumulator, blog) => {
    //     const blogWordCount = blog.versions.reduce((accumulator, version) => {
    //         const displayText = version?.activeContent || version?.content || '';
    //         return accumulator + displayText.split(' ').filter(word => word.length > 0).length;
    //     }, 0);
    //     return accumulator + blogWordCount
    // }, 0)) + (generatingContent ? generatingContent?.split(' ')?.filter(word => word.length > 0)?.length : 0);

    const [openedModal, setOpenedModal] = useState();
    const [configForm, setConfigForm] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const [disableSelect, setDisabledSelect] = useState(false);

    // useEffect(() => {
    //     setDisabledSelect(generateLoading ? true : false)
    // }, [generateLoading])

    const [toastId, setToastId] = useState(null);
    // useEffect(() => {
    //     if(!generating) return;
    //     if(!toastId) {
    //         setToastId(toast.loading('Generating your essays, please wait...'));
    //     }
    //     // console.log(activeEssay);
    //     if(activeEssay?.versions?.length && activeEssay?.versions.every(version => version.isDone)) {
    //         // if(activeBlog?.add_ons.length && (activeBlog.versions.length !== activeBlog.addonsValues.length || activeBlog?.addonsValues.some(addon => !addon.isDone))) return;
    //         toast.update(toastId, {
    //             render: 'Generated successfully!',
    //             type: 'success', 
    //             isLoading: false,
    //             autoClose: 5000
    //         });
    //         console.log('SAVE BLOG');
    //         console.log(activeEssay)
    //         dispatch(setEssaysGenerating(false));
    //         setToastId(null);
    //         // dispatch(setBlogSelectedVersionId(activeBlog?.versions[0].id));
    //         // dispatch(setBlogDone(true));
    //         api.assets.changeAsset({
    //             id,
    //             data: {
    //                 content: JSON.stringify(essays)
    //             },
    //             token
    //         }).then(resp => {console.log(resp)});
    //     }
    // }, [activeEssay, id, token, dispatch, toastId, essays, generating])

    useEffect(() => {
        if(!navigate || !id || !dispatch || !token) return;
        const fetchBlogData = async () => {
            try {
                const response = await api.assets.getAssetById({
                    id,
                    token
                });
                if(!response.success) return;
                console.log('FETCH etsy', JSON.parse(response.data.content))
                dispatch(setEtsyItems(JSON.parse(response.data.content)));
            } catch (e) {
                if(e.response.status === 404) {
                    navigate('/')
                }
            }
        }
        fetchBlogData();
    }, [id, token, navigate, dispatch])

    useEffect(() => {
        setConfigForm(activeItem?.settings);
    }, [activeItem])
    
    const handleGenerate = async () => {
        try {
            // dispatch(resetEssayGenerator());
            dispatch(addEtsyItem({
                settings: configForm,
                data: {},
                generatedDate: new Date().toISOString()
            }))
            
            const toastId = toast.loading('Generating etsy listing...');
            socket.send({
                event: 'etsy-listing-create',
                payload: {
                    ...configForm,
                },
                handler: (response) => {
                    if(response?.isError){
                        toast.error("AI bot is not available at the moment");
                        return;
                    }

                    console.log(response);
                    dispatch(setEtsyItemData(response.data));
                    if(response.done) {
                       
                            toast.update(toastId, {
                                render: 'Generated successfully!',
                                type: 'success', 
                                isLoading: false,
                                autoClose: 5000
                            });
                    }

                    if(response?.isDone){
                        dispatch(getUserUpdateThunk({ token }));
                    }
                }})
              
        } catch(e) {
            console.log(e);
        }
    }

    console.log(configForm, 'configForm');

    const [activeTab, setActiveTab] = useState(mobileScreen ? null : navLinks[0].title);
    const activeTabObjectIndex = navLinks.findIndex(({title}) => title === activeTab);
    const ActiveComponent = navLinks[activeTabObjectIndex]?.Component;
    const [sideBarOpened, setSideBarOpened] = useState(mobileScreen ? false : true);
    const [keywordsOptions, setKeywordsOptions] = useState([]);

    const [initKeywords, setInitKeywords] = useState([])


    const [keywordsSelected, setKeywordsSelected] = useState([]);

    useEffect(() => {
        const filterDuplicate = (arr1, arr2) => {
            const combinedArray = [...arr1, ...arr2]

            const uniqueArray = Array.from(new Set(combinedArray))

            return uniqueArray.map(el => ({label: el, value: el}))
        }

        if (configForm && configForm.keywords) {
            const arr = configForm.keywords.split(',')
            !initKeywords.length && setInitKeywords(arr)

            const options = initKeywords.length ? filterDuplicate(initKeywords, arr) : [...arr.map(el => ({label: el, value: el}))]

            setKeywordsOptions(options)
            setKeywordsSelected(arr)

        }
    }, [configForm])
    
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const closeModal = () => setShowHelpModal(false);

    // const duplicate = async() => {
    //     if(!navigate || !id || !token) return;
        
    //     const response = await api.assets.cloneAsset({
    //         id,
    //         token
    //     });

    //     if(response?.success){
    //         setOpenedModal({
    //             name: 'duplicate',
    //             title: `Blog “${blogs?.[0]?.blog_topic}” successfully duplicated as: ”${response.data?.name}”`
    //         });

    //         return;
    //     }

    //     setOpenedModal(null);
    // }

    const deleteAction = async() => {
        const response = await api.assets.deleteAsset({
            id,
            token
        });

        if(response.success){
            setOpenedModal({
                name: 'save',
                title: response.message,
                text: ''
            });

            setTimeout(() => {
                navigate('/');
            }, 1500);

            return;
        }

        setOpenedModal(null);
    }

    const sharePopupActions = [
        [
            TwitterShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M22.4591 6C21.6891 6.35 20.8591 6.58 19.9991 6.69C20.8791 6.16 21.5591 5.32 21.8791 4.31C21.0491 4.81 20.1291 5.16 19.1591 5.36C18.3691 4.5 17.2591 4 15.9991 4C13.6491 4 11.7291 5.92 11.7291 8.29C11.7291 8.63 11.7691 8.96 11.8391 9.27C8.27906 9.09 5.10906 7.38 2.99906 4.79C2.62906 5.42 2.41906 6.16 2.41906 6.94C2.41906 8.43 3.16906 9.75 4.32906 10.5C3.61906 10.5 2.95906 10.3 2.37906 10V10.03C2.37906 12.11 3.85906 13.85 5.81906 14.24C5.18979 14.4122 4.52916 14.4362 3.88906 14.31C4.16067 15.1625 4.6926 15.9084 5.41008 16.4429C6.12756 16.9775 6.99451 17.2737 7.88906 17.29C6.37269 18.4904 4.49306 19.1393 2.55906 19.13C2.21906 19.13 1.87906 19.11 1.53906 19.07C3.43906 20.29 5.69906 21 8.11906 21C15.9991 21 20.3291 14.46 20.3291 8.79C20.3291 8.6 20.3291 8.42 20.3191 8.23C21.1591 7.63 21.8791 6.87 22.4591 6Z" fill="white"/>
                </g>
            </svg>,
            'Twitter',
            () => {}
        ], [   
            FacebookShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="white"/>
                </g>
            </svg>,
            'Facebook',
            () => {}
        ], [
            null,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="white"/>
                </g>
            </svg>,
            'Instagram',
            () => {}
        ], [
            LinkedinShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="white"/>
                </g>
            </svg>,
            'LinkedIn',
            () => {}
        ], [
            EmailShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 6.8V6.8C3 7.4 3.3 7.9 3.8 8.3L9.8 12.4C11.2 13.3 12.9 13.3 14.3 12.4L20.3 8.4C20.7 7.9 21 7.4 21 6.8V6.8C21 5.8 20.2 5 19.2 5H4.8C3.8 5 3 5.8 3 6.8Z" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 7V17C3 18.1 3.9 19 5 19H19C20.1 19 21 18.1 21 17V7" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.58594 18.4124L9.67994 12.3184" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.3594 12.3604L20.4114 18.4124" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
            'Email',
            () => {}
        ]
    ];

    const linkToShare = `https://www.example.com/file/D7keGdZyuXb0NHmSL1Qyvr/aikit.ai?type=design&node-id=416%3A20984&mode=design&t=u2AAYmBG6J987C5w-1`;

    const copyCustomLink = () => {
        const textarea = document.createElement('textarea');
        textarea.value = linkToShare;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setLinkCopied(true);

        toast.success('Copied successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        setTimeout(() => {
            setLinkCopied(false);
        }, 1500);
    }

    const save = async() => {
        let res = await api.assets.changeAsset({
            id,
            data: {
                content: JSON.stringify(items)
            },
            token
        });

        if(res?.success){
            setOpenedModal({
                name: 'save',
                title: 'Saved',
                text: `Your essay has been successfully saved`
            })
        } else {
            console.error(res);
        }
    }

    useEffect(() => {
        if(!mobileScreen) setSideBarOpened(!cardFullScreen);
    }, [cardFullScreen])

    // const handleExport = async (id, ext) => {
    //     await api.assets.exportAsset({
    //         token,
    //         id,
    //         type: 'blog',
    //         ext,
    //         filename: `${blogs[activeBlogIndex].blog_topic}.${ext}`,
    //     });

    //     setOpenedModal(null);
    // }
    return <main className='w-screen h-screen overflow-hidden flex'>
        <ChatBot className='bottom-[74px] right-[16px]'/>
        <nav className='flex flex-col w-[72px] bg-[#151515]'>
            <Link to='/' className={classNames('generated-tab w-[72px] h-[60px]', {
                'generated-tab--active-top': activeTabObjectIndex === 0,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <img src={logo} alt='Logo'/>
            </Link>
            {navLinks.map(({ icon, title }, index) =>
                <button
                    key={index} 
                    className={classNames('generated-tab w-[72px] h-[72px]', {
                        'generated-tab--active': title === activeTab,
                        'generated-tab--active-top': index === activeTabObjectIndex - 1,
                        'generated-tab--active-bottom': index === activeTabObjectIndex + 1,
                        'generated-tab--closed': !sideBarOpened,
                    })}
                    onClick={() => {
                        setActiveTab(title);
                        setSideBarOpened(true);
                    }}
                >
                    {icon}
                    <span className='text-[10px] font-medium'>{title}</span>
                </button>
            )}
            <div className={classNames('generated-exit-wrapper', {
                    'generated-tab--active-bottom': activeTabObjectIndex === navLinks.length - 1,
                    'generated-tab--closed': !sideBarOpened,
                })}>
                    <Link
                        to='/my-assets?tab=My Blogs'
                        className='w-[72px] h-[72px] flex flex-col gap-[8px] justify-center items-center opacity-40 bg-[#111111]'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M5 12.5H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10 7.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10 17.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className='text-[10px] font-medium'>Exit</span>
                    </Link>
            </div>
        </nav>
        <div className={classNames('generated-sidebar', {
            'generated-sidebar--closed': !sideBarOpened
        })}>
            <button 
                className='absolute top-[18px] right-[16px]' 
                onClick={() => {
                    setSideBarOpened(false);
                    setActiveTab(null);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                        <path d="M18 6L6 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>
            {(configForm && ActiveComponent) && 
                <ActiveComponent 
                    setIsLoading={setIsLoading}
                    configForm={configForm}
                    setConfigForm={setConfigForm}
                    handleGenerate={handleGenerate}
                    isNotDone={ false }
                />
            }
        </div>
        {isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> :
        <div className='flex-1 flex flex-col'>
            <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
                'px-[30px]': cardFullScreen
            })}>
                <div>
                    <h1 className='text-[14px] font-medium leading-normal'>
                        Generated Content
                    </h1>
                    <div className='text-[12px] leading-[14px] opacity-40 flex items-center gap-[5px]'>
                        Text
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        Blog
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        No Generated Content
                    </div>
                </div>
                <button 
                    onClick={() => setShowHelpModal(true)}
                    className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className={`${disableSelect ? 'block-disabled' : ''}  `}>
            <SelectBox
                key={keywordsSelected.join(' ')}
                className={classNames("default-select multi-select one-line mx-[16px] mb-[12px] blog-keywords-select", {
                    'hidden': cardFullScreen,
                    'block-disabled': disableSelect
                })}
                propsOptions={keywordsOptions}
                addOptionEvent={(optionValue) => {
                    setKeywordsOptions((prevState) => {
                        return [...prevState, {label: optionValue, value: optionValue}]
                    })
                    setKeywordsSelected((prevState) => { return [...prevState, optionValue] });
                }}
                isMulti={true}
                isSearchable={true}
                InputTextarea={true}
                value={keywordsSelected}
                change={(value)=>{
                    setKeywordsSelected(value);
                    setConfigForm(prev => ({...prev, keywords: value.join(',') }))
                }}
            />
            </div>
            <div className='py-[4px] px-[16px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
                <ul className={classNames('flex flex-col gap-[12px]', {
                    'h-full': cardFullScreen
                })}>
                    {items.map((item, index) => {
                        return <Fragment key={index}>
                            <EtsyCard
                                // isNotDone={isNotDone}
                                // data={data.versions} 
                                item={item}
                                index={index}
                                setCardFullScreen={setCardFullScreen}
                                isFullScreen={cardFullScreen}
                            />
                        </Fragment>
                    })}
                </ul>
            </div>
            <div className='generated-content-bottom-panel flex-wrap'>
                <div className='flex items-center gap-[20px]'>
                    <div className='flex items-center gap-[10px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.8346 1.66663H4.16797C2.79297 1.66663 1.66797 2.79163 1.66797 4.16663V15.8333C1.66797 17.2083 2.79297 18.3333 4.16797 18.3333H15.8346C17.2096 18.3333 18.3346 17.2083 18.3346 15.8333V4.16663C18.3346 2.79163 17.2096 1.66663 15.8346 1.66663ZM9.16797 12.5H5.83464C5.3763 12.5 5.0013 12.125 5.0013 11.6666C5.0013 11.2083 5.3763 10.8333 5.83464 10.8333H9.16797C9.6263 10.8333 10.0013 11.2083 10.0013 11.6666C10.0013 12.125 9.6263 12.5 9.16797 12.5ZM12.5013 9.16663H5.83464C5.3763 9.16663 5.0013 8.79163 5.0013 8.33329C5.0013 7.87496 5.3763 7.49996 5.83464 7.49996H12.5013C12.9596 7.49996 13.3346 7.87496 13.3346 8.33329C13.3346 8.79163 12.9596 9.16663 12.5013 9.16663Z" fill="#9D9D9D"/>
                        </svg>
                        <div className='text-[14px] text-[#9D9D9D] leading-normal flex'>
                            <div className='w-[40px]'>{123}</div>
                            <span className='opacity-50'>words</span>
                        </div>
                    </div>
                    {/* <div className='flex items-center gap-[10px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#9D9D9D"/>
                        </svg>
                        <p className='text-[14px] text-[#9D9D9D] leading-normal'>
                            2.44
                            <span className='opacity-50 ml-[4px]'>credits used</span>
                        </p>
                    </div> */}
                    <div className='flex items-center gap-[10px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207"/>
                        </svg>
                        <p className='text-[14px] text-[#EA8207] leading-normal'>
                            {meData?.credits || 0}
                            <span className='opacity-50 ml-[4px]'>available credits</span>
                        </p>
                    </div>
                </div>
                <div 
                    className={`flex items-center gap-[8px] ${false ? 'block-disabled' : ''}`}
                >
                    <button 
                        className="btn btn-outline-black btn-lg-2 rounded-full mr-auto"
                        style={{ padding: '9px' }} 
                        onClick={() => setOpenedModal('export')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M6.66536 8.33301H4.9987C4.07786 8.33301 3.33203 9.07884 3.33203 9.99967V15.833C3.33203 16.7538 4.07786 17.4997 4.9987 17.4997H14.9987C15.9195 17.4997 16.6654 16.7538 16.6654 15.833V9.99967C16.6654 9.07884 15.9195 8.33301 14.9987 8.33301H13.332" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.9987 2.5V11.6667" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.5 5L9.99917 2.5L12.4992 5" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <button 
                        className="btn btn-outline-black btn-lg-2 rounded-full"
                        style={{ padding: '9px' }}
                        onClick={() => setOpenedModal('share')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g opacity="0.6">
                                <path d="M15.1483 18.7708C14.3747 18.7708 13.7086 18.4901 13.1502 17.9285C12.5917 17.367 12.3125 16.7107 12.3125 15.9595C12.3125 15.8524 12.3264 15.7236 12.3542 15.573C12.3819 15.4225 12.4097 15.2815 12.4375 15.1502L6.881 11.8958C6.61311 12.1736 6.3101 12.3993 5.97196 12.5729C5.63382 12.7465 5.27128 12.8333 4.88433 12.8333C4.12132 12.8333 3.46026 12.553 2.90117 11.9923C2.34206 11.4317 2.0625 10.7667 2.0625 9.99754C2.0625 9.22835 2.34206 8.56771 2.90117 8.01562C3.46026 7.46354 4.12132 7.1875 4.88433 7.1875C5.26636 7.1875 5.6242 7.26042 5.95785 7.40625C6.29151 7.55208 6.59722 7.76389 6.875 8.04167L12.4375 4.82725C12.4097 4.73092 12.3819 4.60502 12.3542 4.44956C12.3264 4.29412 12.3125 4.16242 12.3125 4.05446C12.3125 3.29131 12.5909 2.6331 13.1477 2.07985C13.7045 1.52662 14.3698 1.25 15.1434 1.25C15.902 1.25 16.5608 1.52686 17.1198 2.08058C17.6788 2.63429 17.9583 3.29575 17.9583 4.06496C17.9583 4.83415 17.6788 5.49479 17.1197 6.04687C16.5606 6.59896 15.8995 6.875 15.1365 6.875C14.7308 6.875 14.3665 6.81966 14.0436 6.70898C13.7207 6.5983 13.4334 6.40364 13.1815 6.125L7.60417 9.17102C7.63194 9.29242 7.65625 9.43784 7.67708 9.60727C7.69792 9.7767 7.70833 9.90928 7.70833 10.005C7.70833 10.1008 7.69792 10.2143 7.67708 10.3457C7.65625 10.477 7.63194 10.6034 7.60417 10.7248L13.1815 13.8542C13.4334 13.6311 13.715 13.4538 14.0265 13.3223C14.3379 13.1908 14.7079 13.125 15.1365 13.125C15.8995 13.125 16.5606 13.4053 17.1197 13.966C17.6788 14.5267 17.9583 15.1916 17.9583 15.9608C17.9583 16.7161 17.6796 17.3733 17.1222 17.9323C16.5648 18.4913 15.9069 18.7708 15.1483 18.7708Z" fill="white"/>
                            </g>
                        </svg>
                    </button>
                    <div className='goal-generator-divider' />
                    <button 
                        className="btn btn-outline-black btn-lg-2 rounded-full"
                        style={{ padding: '9px' }}
                        onClick={() => setOpenedModal({
                            name: 'confirm-delete',
                            action: () => deleteAction(),
                            // title: `Do you really want to delete "${blogs?.[0]?.blog_topic}" blog?`
                        })}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M5.26953 17.9378C4.76692 17.9378 4.32182 17.7509 3.93424 17.3772C3.54666 17.0034 3.35286 16.5583 3.35286 16.042V4.54199H2.14453V2.64616H6.89453V1.66699H13.082V2.64616H17.832V4.54199H16.6237V16.042C16.6237 16.5583 16.4348 17.0034 16.0569 17.3772C15.679 17.7509 15.2291 17.9378 14.707 17.9378H5.26953ZM7.4362 14.417H8.97786V6.12533H7.4362V14.417ZM11.0404 14.417H12.6029V6.12533H11.0404V14.417Z" fill="white" fillOpacity="0.6"/>
                        </svg>
                    </button>
                    <button 
                        className="btn btn-outline-black btn-lg-2 rounded-full"
                        style={{ padding: '9px' }}
                        onClick={() => setOpenedModal({
                            name: 'confirm-delete',
                            // action: () => duplicate(),
                            // title: `Do you really want to duplicate "${blogs?.[0]?.blog_topic}" blog?`
                        })}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2.5 15.833V16.7854C2.50014 17.1798 2.81985 17.4995 3.21428 17.4997H4.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.832 7.5H11.7844C12.1788 7.50014 12.4986 7.81985 12.4987 8.21428V9.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.33464 17.4997H6.66797" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.33464 7.49967H6.66797" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.832 17.4997H11.7844C12.1788 17.4995 12.4986 17.1798 12.4987 16.7854V15.833" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.5 9.16667V8.21428C2.50014 7.81985 2.81985 7.50014 3.21428 7.5H4.16667" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.4987 11.667V13.3337" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.4987 11.667V13.3337" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.66797 5V4.16667C6.66785 3.7246 6.8434 3.30061 7.15599 2.98802C7.46858 2.67543 7.89257 2.49988 8.33464 2.5H15.8346C16.2767 2.49988 16.7007 2.67543 17.0133 2.98802C17.3259 3.30061 17.5014 3.7246 17.5013 4.16667V11.6667C17.5014 12.1087 17.3259 12.5327 17.0133 12.8453C16.7007 13.1579 16.2767 13.3335 15.8346 13.3333H15.0013" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <button 
                        className="btn btn-outline-black btn-lg-2 rounded-full h-[40px] px-[14px]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99967 15.5208L6.35384 17.0625C5.72884 17.3264 5.13162 17.2747 4.56217 16.9075C3.99273 16.5403 3.70801 16.0086 3.70801 15.3125V3.85417C3.70801 3.33767 3.89976 2.8891 4.28326 2.50846C4.66677 2.12782 5.10697 1.9375 5.60384 1.9375H14.3955C14.8981 1.9375 15.3432 2.12782 15.7308 2.50846C16.1184 2.8891 16.3122 3.33767 16.3122 3.85417V15.3125C16.3122 16.0086 16.024 16.5403 15.4476 16.9075C14.8712 17.2747 14.2705 17.3264 13.6455 17.0625L9.99967 15.5208Z" fill="white" fillOpacity="0.6"/>
                        </svg>
                        <span className='ml-[8px] text-[14px] font-medium opacity-60 text-white'>Add to My Collection</span>
                    </button>
                    <button 
                        className={`generated-button btn generated-content-bottom-panel-btn ${false ? 'block-disabled' : ''}`}
                        onClick={() => save()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>}
        {openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            {openedModal === 'export' && <div className="modal__body w-[600px]">
                <button 
                    className="modal-close-btn" 
                    onClick={() => setOpenedModal(null)}
                >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Export Generated Content</h3>
                <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select one of options to export content</p>
                <div className="flex items-stretch gap-[9px]">
                    <button
                        // onClick={() => handleExport(id, 'csv')}
                        className="share-platforms-item share-item"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 11V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14.5H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M15 18H9C8.448 18 8 17.552 8 17V12C8 11.448 8.448 11 9 11H15C15.552 11 16 11.448 16 12V17C16 17.552 15.552 18 15 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>CSV</span>
                    </button>
                    <button
                        // onClick={() => handleExport(id, 'json')}
                        className="share-platforms-item share-item">
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>JSON</span>
                    </button>
                    <button
                        // onClick={() => handleExport(id, 'pdf')}
                        className="share-platforms-item share-item"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.4817 7.483L18.1824 4.18367C17.7449 3.74617 17.151 3.5 16.5327 3.5H8.16536C6.8762 3.5 5.83203 4.54417 5.83203 5.83333V22.1667C5.83203 23.4558 6.8762 24.5 8.16536 24.5H19.832C21.1212 24.5 22.1654 23.4558 22.1654 22.1667V9.13267C22.1654 8.51433 21.9192 7.9205 21.4817 7.483V7.483Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22.1654 9.33333H17.4987C16.8547 9.33333 16.332 8.81067 16.332 8.16667V3.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.0625 19.3079H15.9358" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6367 14.8758V13.5132C12.6367 13.1375 12.9412 12.833 13.3169 12.833H14.6796C15.0552 12.833 15.3597 13.1375 15.3597 13.5132V14.8758C15.3597 15.2515 15.0552 15.556 14.6796 15.556H13.3169C12.9412 15.5548 12.6367 15.2503 12.6367 14.8758Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.33203 20.3202V18.9575C9.33203 18.5818 9.63653 18.2773 10.0122 18.2773H11.3749C11.7505 18.2773 12.055 18.5818 12.055 18.9575V20.3202C12.055 20.6958 11.7505 21.0003 11.3749 21.0003H10.0122C9.63653 21.0003 9.33203 20.6958 9.33203 20.3202Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.9453 20.3202V18.9575C15.9453 18.5818 16.2498 18.2773 16.6255 18.2773H17.9881C18.3638 18.2773 18.6683 18.5818 18.6683 18.9575V20.3202C18.6671 20.6958 18.3626 21.0003 17.987 21.0003H16.6243C16.2498 21.0003 15.9453 20.6958 15.9453 20.3202H15.9453Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.6132 18.2817L14.8516 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.3867 18.2817L13.1484 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <span>PDF</span>
                    </button>
                </div>
            </div>}
            {openedModal === 'share' && <div className="modal__body w-[600px]">
                <button 
                    className="modal-close-btn" 
                    onClick={() => setOpenedModal(null)}
                >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Share Generated Content </h3>
                <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select on of options or copy link to share content</p>
                <div className="share-platforms-items">
                    {
                        sharePopupActions.map(([Button, icon, title]) => {
                            return Button ? <Button className="share-platforms-item" key={title} url={linkToShare}>
                                <span className="d-flex align-items-center mb-[14px]">
                                    { icon }
                                </span>
                                <span>{ title }</span>
                            </Button> : null
                        })
                    }
                </div>
                <div className="input-custom">
                    <label className="input-custom__label mb-[10px] text-[12px] font-[400]">Custom Link</label>
                    <div className="input-custom__wrap-input icon-right">
                        <input className="input-custom__input h-[40px]" type="text" placeholder="Custom Link" value={linkToShare} />
                        <a href="#" className="icon-input" style={{ pointerEvents: 'initial' }} onClick={(e)=>{
                            e.preventDefault();
                            copyCustomLink();
                        }}>
                            {
                                linkCopied ? 
                                <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="7.5" y="7.5" width="10" height="10" rx="2" stroke="#8F8F8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5 12.5H4.16667C3.24619 12.5 2.5 11.7538 2.5 10.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667V5" stroke="#8F8F8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                        </a>
                    </div>
                </div>
            </div>}
            {openedModal === 'duplicate' || openedModal?.name === 'duplicate' && <div className="modal__body w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M6 38V40.2857C6.00034 41.2323 6.76765 41.9997 7.71428 42H10" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M26 18H28.2857C29.2323 18.0003 29.9997 18.7677 30 19.7143V22" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 42H16" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 18H16" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M26 42H28.2857C29.2323 41.9997 29.9997 41.2323 30 40.2857V38" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 22V19.7143C6.00034 18.7677 6.76765 18.0003 7.71428 18H10" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M30 28V32" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 28V32" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16 12V10C15.9997 8.93904 16.421 7.92146 17.1713 7.17125C17.9215 6.42104 18.939 5.99971 20 6H38C39.061 5.99971 40.0785 6.42104 40.8288 7.17125C41.579 7.92146 42.0003 8.93904 42 10V28C42.0003 29.061 41.579 30.0785 40.8288 30.8288C40.0785 31.579 39.061 32.0003 38 32H36" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        Duplicated
                    </h3>
                    <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        { openedModal?.title ? openedModal?.title : 'Plan successfully duplicated' }
                    </p>
                </div>
                <button 
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]" 
                    onClick={() => setOpenedModal(null)}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div>}
            {(openedModal === 'save' || openedModal?.name === 'save') && <div className="modal__body w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        { openedModal?.title ? openedModal?.title : 'Saved' }
                    </h3>
                    { (openedModal?.name === 'save' && openedModal?.text) || openedModal === 'save' ?
                    <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        { openedModal?.text ? openedModal?.text : 'Your plan “Publish a Novel” has been successfully saved' }
                    </p> : null }
                </div>
                <button 
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                    onClick={() => {
                        setOpenedModal(null);
                    }}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div>}
            {openedModal?.name === 'confirm-delete' && <div className="modal__body w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        { openedModal?.title ? openedModal.title : 'Do you really want to delete?' }
                    </h3>
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div>}
        </GeneratedContentModal>}
        <Popup
            open={showHelpModal}
            onClose={closeModal}
            overlayStyle={{
                background: 'rgba(0,0,0,0.5)'
            }}
        >
            <HelpModal closeModal={closeModal}/>
        </Popup>
    </main>;
};

export default EtsyGeneratedContent;