import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    generating: false,
    essays: [],
    activeEssayIndex: 0,
    generatingContent: null,

    // диспатч дергает все слайсы, методы с использованием blogs нужно убрать
    blogs: []
}

const essayGeneratorSlice = createSlice({
    name: 'blogGenerator',
    initialState,
    reducers: {
        setEssays: (state, action) => {
            state.essays = action.payload;
        },
        setGenerateLoading: (state, action) => {
            state.generateLoading = action.payload;
        },
        setActiveEssayIndex: (state, action) => {
            state.activeEssayIndex = action.payload; 
        },

        // setBlogVersions: (state, action) => {
        //     if(!state.blogs[state.activeBlogIndex]) {
        //         state.blogs[state.activeBlogIndex] = {}
        //     }
        //     state.blogs[state.activeBlogIndex].versions = Object.values(action.payload).map(value => ({
        //         language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
        //         tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
        //         ...value
        //     })); 
        // },
        setEssayVersions: ({ essays, activeEssayIndex }, action) => {
            if(!essays[activeEssayIndex]) {
                essays[activeEssayIndex] = {}
            }
            if(!essays[activeEssayIndex].versions) {
                essays[activeEssayIndex].versions = []
            }
            essays[activeEssayIndex].versions = [
                ...action.payload
            ]
            // if(!essays[activeEssayIndex].versions[action.payload.index]) {
            //     essays[activeEssayIndex].versions[action.payload.index] = {}
            // }
            // essays[activeEssayIndex].versions[action.payload.index] = {
            //     ...essays[activeEssayIndex].versions[action.payload.index],
            //     ...action.payload.data,
            //     // language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
            //     // tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
            // }
        },
        setEssayVersion: ({ essays, activeEssayIndex }, action) => {
            if(!essays[activeEssayIndex]) {
                essays[activeEssayIndex] = {}
            }
            if(!essays[activeEssayIndex].versions) {
                essays[activeEssayIndex].versions = []
            }
            if(!essays[activeEssayIndex].versions[action.payload.index]) {
                essays[activeEssayIndex].versions[action.payload.index] = {}
            }
            essays[activeEssayIndex].versions[action.payload.index] = {
                ...essays[activeEssayIndex].versions[action.payload.index],
                ...action.payload.data,
                // language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
                // tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
            }
        },
        setEssayAddon: ({ essays, activeEssayIndex }, action) => {
            if(action.payload?.onlyAddonGenerate){
                essays[action.payload.essayIndex].versions[action.payload.versionIndex].addons = {
                    ...essays[action.payload.essayIndex].versions[action.payload.versionIndex].addons,
                    ...action.payload.data,
                }

                return;
            }

            essays[activeEssayIndex].versions[action.payload.index].addons = {
                ...essays[activeEssayIndex].versions[action.payload.index].addons,
                ...action.payload.data,
                // language: state.blogs[state.activeBlogIndex]?.settings?.language || '',
                // tone: state.blogs[state.activeBlogIndex]?.settings?.tone || '',
            }
        },
        setEssaySettingsAddons: (state, action) => {
            let updatesEssays = JSON.parse(JSON.stringify(state.essays));
            updatesEssays.forEach((item)=>{
                item.settings.addons = action.payload;
            });

            state.essays = updatesEssays;
        },
        setEssaysGenerating: (state, action) => {
            state.generating = action.payload;
        },
        // setBlogVersionAddon: (state, action) => {
        //     state.blogs[state.activeBlogIndex].addonsValues[action.payload.index] = action.payload.data;
        // },
        // deleteActiveBlogVersion: (state, action) => {
        //     let { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
        //     versions.splice(activeVersionIndex, 1);
        //     if(versions.length === 0) {
        //         state.blogs.splice(state.activeBlogIndex, 1);
        //         return;
        //     }
        //     if(activeVersionIndex === versions.length) {
        //         state.blogs[state.activeBlogIndex].activeVersionIndex = 0;
        //     }
        // },
        // copyActiveBlogVersion: (state, action) => {
        //     let { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
        //     versions.push(versions[activeVersionIndex]);
        // },
        // setBlogActiveVersionIndex: (state, action) => {
        //     state.blogs[state.activeBlogIndex].activeVersionIndex = action.payload;
        // },
        // setBlogAddonsValues: (state, action) => {
        //     state.blogs[state.activeBlogIndex].addonsValues = action.payload;
        // },
        // setBlogAddonVersion: (state, action) => {
        //     state.blogs[action.payload.blogIndex].addonsValues[action.payload.index][action.payload.key] = action.payload.value;
        // },
        // setBlogContent: (state, action) => {
        //     const activeBlog = state.blogs[state.activeBlogIndex];
        //     activeBlog.versions[activeBlog.activeVersionIndex].content = action.payload;
        // },
        // setBlogActiveContent: (state, action) => {
        //     const activeBlog = state.blogs[state.activeBlogIndex];
        //     activeBlog.versions[activeBlog.activeVersionIndex].activeContent = action.payload;
        // },
        // setBlogActiveButton: (state, action) => {
        //     const activeBlog = state.blogs[state.activeBlogIndex];
        //     activeBlog.versions[activeBlog.activeVersionIndex].activeButton = action.payload;
        // },
        // setBlogIsEdit: (state, action) => {
        //     const activeBlog = state.blogs[state.activeBlogIndex];
        //     activeBlog.versions[activeBlog.activeVersionIndex].isEdit = action.payload;
        // },
        setEssayTone: (state, action) => {
            const activeEssay = state.essays[state.activeEssayIndex];
            activeEssay.versions[action.payload.activeVersionIndex].tone = action.payload.data;
        },
        setEssayLanguage: (state, action) => {
            const activeEssay = state.essays[state.activeEssayIndex];
            activeEssay.versions[action.payload.activeVersionIndex].language = action.payload.data;
        },
        // setBlogDone: (state, action) => {
        //     const activeBlog = state.blogs[state.activeBlogIndex];
        //     activeBlog.done = action.payload;
        // },
        setBlogSelectedVersionId: (state, action) => {
            state.blogs.forEach(blog => {
                blog.selectedVersionId = action.payload;
            })
        },
        addEssay: (state, action) => {
            const newLen = state.essays.push(action.payload);
            state.activeEssayIndex = newLen - 1;
        },
        addEssayKeyword: (state, action) => {
            const { versions } = state.essays[state.activeEssayIndex];
            versions[action.payload.activeVersionIndex].keywords.push(action.payload.keyword);
        },
        // deleteBlogKeyword: (state, action) => {
        //     const { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
        //     if(!versions[activeVersionIndex].keywords) return;
        //     versions[activeVersionIndex].keywords = versions[activeVersionIndex].keywords.filter((keyword) => keyword !== action.payload);
        // },
        // setBlogGeneratingContent: (state, action) => {
        //     state.generatingContent = action.payload;
        // },
        resetEssayGenerator: (state) => {
            state.generating = false;
            state.essays = [];
            state.activeEssayIndex = 0;
            state.generatingContent = null;
        }
    }
})


export const {
    setEssayVersion, setEssaysGenerating, setEssays, resetEssayGenerator, addEssayKeyword, setEssayTone,
    setEssayLanguage, setActiveEssayIndex, addEssay, setEssayAddon, setEssaySettingsAddons, setEssayVersions
} = essayGeneratorSlice.actions

export const getGeneratedEssays = (state) => state.essayGenerator.essays;
export const getEssaysState = (state) => state.essayGenerator;
export const getEssaysGenerating = (state) => state.essayGenerator.generating;

export default essayGeneratorSlice.reducer