import React, { useEffect, useRef, useState } from 'react';
import { format, formatDistanceToNow, formatRelative } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { getSocket, getToken, getUser, setUser } from '../../redux/slices/applicationSlice';
import { useNavigate, useParams, Link } from 'react-router-dom';
import api from '../../api';
import getUserImageSrc from '../../helpers/getUserImageSrc';
import ChatMessage from './ChatMessage';
import { ReactComponent as RegenerateIcon } from '../../assets/img/icons/regenerate.svg';
import { ReactComponent as StopIcon } from '../../assets/img/icons/stop.svg';
import { checkUserHaveCredits } from '../../helpers/userHelpers';
import { toast } from 'react-toastify';
import { getUserUpdateThunk } from '../../redux/thunks/applicationThunks';
 

const Chat = ({ botKit, setChats }) => {
    const { botId, chatId } = useParams();
    const dispatch = useDispatch();
    const socket = useSelector(getSocket);
    const token = useSelector(getToken);
    const user = useSelector(getUser);

    const navigate = useNavigate();

    const [chat, setChat] = useState({
        messages: []
    });
    console.log('chat:', chat);
    const [canSend, setCanSend] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [processMessage, setProcessMessage] = useState(null);
    const [isChatting, setIsChatting] = useState(false);
    const [generatorId, setGeneratorId] = useState(null);

    const [resetByTimeout, setResetByTimeout] = useState(null);

    const chatRef = useRef(null);

    const handleSendMessage = (customPayload = {}) => {
        // console.log()
        if(!canSend){
            return false;
        }

        setIsChatting(true);
        setInputValue('');
        setChat(prev => ({
            ...prev,
            messages: [...prev?.messages, {
                role: 'user',
                content: inputValue,
                createdAt: Date.now(),
                is_active: true,
                id: 'user-message'
            }]
        }))

        const resetByTimeoutAction = setTimeout(() => {
            setIsChatting(false);
            setGeneratorId(null);

            toast.error('Something went wrong, please try again');
        }, 10000);

        socket.send({
            event: 'chat-bot',
            payload: {
                ...(chatId && {chatId}),
                messageText: inputValue,
                is_new: !chatId,
                chat_options: {
                    roles: botKit.roles,
                    bot_tone: botKit.bot_tone,
                    brand_tone: botKit.brand_tone,
                    response_length: botKit.response_length,
                    multilang_enabled: botKit.multilang_enabled,
                    language: botKit.language,
                    imported_data: botKit.imported_data,
                    include_citatiaons: botKit.include_citatiaons,
                    botId: botKit.id
                },
                ...customPayload
            },
            handler: (response) => {
                console.log("chat-bot response:", response);

                if(response?.isError){
                    setIsChatting(false);
                    toast.error("AI bot is not available at the moment");

                    return;
                }

                setChat(prev => ({
                    ...prev,
                    messages: prev.messages.filter(({id}) => id !== 'user-message')
                }))
                setGeneratorId(response?.id);

                // setInputValue('');

                if(response.event === 'chat-bot-new-user-message' || response.isDone) {
                    setProcessMessage(null);
                    setChat(prev => ({
                        ...prev,
                        messages: [...prev?.messages, response.payload]
                    }))
                } else {
                    // console.log('Process message', response.payload)
                    setProcessMessage(response.payload);
                }

                clearTimeout(resetByTimeoutAction);
                if(resetByTimeout) clearTimeout(resetByTimeout);

                if(response.isDone) {
                    setIsChatting(false);
                    setGeneratorId(null);

                    checkCredits();
                    
                    dispatch(getUserUpdateThunk({ token }));

                    setResetByTimeout(null);

                    if(chatId) return;
                    navigate(`/chat-bot/${botId}/c/${response.chatId}`);
                    api.botKits.getBotKitChats({
                        botId,
                        token
                    }).then(resp => {
                        if(!resp.success) {
                            return;
                        }
                        setChats(resp.data);
                    })
                } else {
                    setTimeout(() => {    
                        setResetByTimeout(resetByTimeoutAction); 
                    }, 50);
                }
            }
        }); 
    }

    const sendDisabled = !inputValue || isChatting;

    const stopGenerate = (regenerate = false) => {
        if(generatorId){
            socket.send({
                event: 'chat-bot-stop',
                payload: {
                    generatorId,
                },
                handler: (response) => {
                    console.log('chat bot response', response)
                    if(!response.payload.success) return;
                    api.botChatMessages.getMessages({
                        chatId,
                        token
                    }).then(response => {
                        console.log('GET CHAt', response)
                        if(!response.success) return;
                        setProcessMessage(null);
                        setChat(response.data);
                        if(regenerate) {
                            console.log(chat.messages[chat.messages.length -1])
                            const reply_to = chat.messages[chat.messages.length -1].id
                            setChat(prev => ({
                                ...prev,
                                messages: prev.messages.map(message => ({
                                    ...message,
                                    is_active: message.reply_to === reply_to ? false : message.is_active
                                }))
                            }))
                            handleSendMessage({reply_to});
                        }
                    })
                }
            });

            setIsChatting(false);
            setGeneratorId(null);
            // setChat(prev => ({
            //     ...prev,
            //     messages: [...prev?.messages, processMessage]
            // }))
            // setProcessMessage(null);
            
        }
    }

    const checkCredits = async() => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));
            setCanSend(false);
            return;
        } else {
            setCanSend(true);
        }
    }

    useEffect(() => {
        if(token){
            checkCredits();
        }

        if(!chatId || !token) {
            setChat({
                messages: []
            });
            return;
        };

        api.botChatMessages.getMessages({
            chatId,
            token
        }).then(response => {
            if(!response.success) return;
            setChat(response.data);
        })
    }, [chatId, token]);

    useEffect(() => {
        chatRef.current?.scrollIntoView({ behavior: "smooth"});
    }, [chat?.messages, processMessage])

    useEffect(() => {
        if(localStorage.getItem(`chat-bot-${botId}-input`) && !inputValue?.length){
            setInputValue(localStorage.getItem(`chat-bot-${botId}-input`));
        }
    }, [botId]);

    useEffect(() => {
        if(botId){
            localStorage.setItem(`chat-bot-${botId}-input`, inputValue);
        }

        if(!inputValue?.length && localStorage.getItem(`chat-bot-${botId}-input`)?.length === 0){
            localStorage.removeItem(`chat-bot-${botId}-input`);
        }
    }, [botId, inputValue]);

    return <div className={`flex-1 flex flex-col bg-[#111]`}>
        <div className='py-[13px] px-[16px] flex items-center justify-between'>
            <div>
                <h2 className='text-[14px] font-medium'>{chat?.title || 'New Chat'}</h2>
                <div className='text-[12px] leading-[14px] flex items-center gap-[5px] mt-[2px]'>
                    <Link
                        to="/generated-content?type=bot"
                        className='opacity-40 hover:text-[#FF8114] hover:underline hover:opacity-100'
                    >
                        Bot
                    </Link>
                    <div className='w-[3px] h-[3px] rounded-full bg-white opacity-40'/>
                    <span className="opacity-40">
                        {chat?.title || 'New Chat'}
                    </span>
                </div>
            </div>
        </div>
        {chat.messages.length ? 
            <ul className='flex-1 px-[16px] flex flex-col gap-[16px] w-full max-w-[882px] mx-auto mt-[16px]
            overflow-y-scroll no-scroll'>
                {chat.messages.map((message, index) => 
                    <ChatMessage 
                        key={message.id} 
                        mesage={message} 
                        botName={botKit?.name}
                        sendMessage={handleSendMessage}
                        messages={chat.messages}
                        setChat={setChat}
                        showRegenerate={index === chat.messages.length - 1}
                        botImage={botKit?.logo}
                    />
                )}
                {processMessage && <ChatMessage 
                    mesage={processMessage} 
                    botName={botKit?.name}
                    sendMessage={handleSendMessage}
                    messages={[...chat.messages, processMessage]}
                    setChat={setChat}
                    showRegenerate={false}
                    botImage={botKit?.logo}
                />}
                <div ref={chatRef} />
            </ul> : 
            <div className='flex-1 px-[24px] lg:px-[56px] flex items-center justify-center gap-[8px]'>
                <p className='text-[24px] lg:text-[32px] font-semibold text-center'>
                    Welcome to {botKit?.name} Bot! Start new chat by sending first message.
                </p>
            </div>
        }
        {isChatting && <div className='flex items-center justify-center gap-[10px]'>
            <button 
                className='flex items-center gap-[8px] h-[36px] px-[12px] rounded-full border border-solid border-[#333] bg-[#1B1B1B]'
                onClick={() => {
                    // setProcessMessage(null);
                    stopGenerate(true);
                    // console.log(chat.messages[chat.messages.length -1])
                    // const reply_to = chat.messages[chat.messages.length -1].id
                    // setChat(prev => ({
                    //     ...prev,
                    //     messages: prev.messages.map(message => ({
                    //         ...message,
                    //         is_active: message.reply_to === reply_to ? false : message.is_active
                    //     }))
                    // }))
                    // handleSendMessage({reply_to});
                    // setInputValue(chat.messages[chat.messages.length -1].content);
                    // handleSendMessage();
                }}
            >
                <RegenerateIcon />
                <span className='text-[#A7A7A7] text-[14px] font-medium'>Regenerate</span>
            </button>
            {/* <button
                className='flex items-center gap-[8px] h-[36px] px-[12px] rounded-full border border-solid border-[#333] bg-[#1B1B1B]'
                onClick={() => { stopGenerate(); }}
            >
                <StopIcon />
                <span className='text-[#A7A7A7] text-[14px] font-medium'>Stop</span>
            </button> */}
        </div>}
        <div className={`py-[13px] px-[16px] ${canSend ? '' : 'block-disabled'}`}>
            <div className='flex w-full max-w-[800px] m-auto rounded-[8px] h-[80px]
            pl-[16px] pr-[12px] items-center gap-[16px] bg-[#1B1B1B]'>
                <textarea
                    className='flex-1 bg-transparent text-[14px] py-[10px] self-stretch max-h-full'
                    placeholder='Send a message...'
                    style={{resize: 'none'}}
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter' && !sendDisabled && !e.shiftKey) {
                            e.preventDefault()
                            handleSendMessage()
                        };
                    }}
                />
                {
                    isChatting ? 
                    <button
                        className='ml-[10px]'
                        onClick={() => { stopGenerate(); }}
                    >
                        <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="6.41667" y="6.41667" width="9.16667" height="9.16667" fill="white"/>
                            <circle cx="11" cy="11" r="10.5" stroke="white"/>
                        </svg>
                    </button> : 
                    <button
                        disabled={sendDisabled}
                        className='disabled:opacity-30 ml-[10px]'
                        onClick={() => handleSendMessage()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.4814 18.7097L21.4684 12.8777C22.1774 12.5017 22.1774 11.4897 21.4684 11.1127L10.4974 5.28969C9.66943 4.84969 8.74143 5.67969 9.09243 6.54569L11.2814 11.9457L9.07443 17.4557C8.72843 18.3227 9.65543 19.1477 10.4814 18.7097Z" stroke="white" strokeWidth="1.5036" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.2812 11.9496L22.0013 11.9996" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 15H5.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3 12H5.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2 9H5.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                }
            </div>
        </div>
    </div>;
};

export default Chat;