import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';


import "./assets/css/quill-editor.css";

import "./assets/css/from-template/bootstrap-grid.css";
import "./assets/css/from-template/reset.css";
import "./assets/css/from-template/global.css";
import "./assets/css/from-template/components/components.css";
import "./assets/css/from-template/main-alpha.css";
import "./assets/css/from-template/main-bravo.css";
import "./assets/css/from-template/main-charlie.css";
import "./assets/css/from-template/main-delta.css";
import "./assets/css/from-template/main-echo.css";
import "./assets/css/from-template/media-alpha.css";
import "./assets/css/from-template/media-bravo.css";
import "./assets/css/from-template/media-charlie.css";
import "./assets/css/from-template/media-delta.css";

import './assets/css/reset.css'
import './assets/css/main-alpha.css'
import './assets/css/main-bravo.css'
import './assets/css/main-charlie.css'
import './assets/css/media-alpha.css'
import './assets/css/media-bravo.css'
import './assets/fonts/gilroy/style.css'

// StrictMode ломает drag and drop
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);