import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getToken } from '../../../../redux/slices/applicationSlice';
import { settingsPopupTabs, } from "../consts";
import Tippy from '@tippyjs/react';
import Label from '../../../../components/base/Label';
import SelectBox from '../../../../components/base/SelectBox';
import DatePicker from '../../../../components/base/DatePicker';
import Settings from "./Settings";
import AddOns from "./AddOns";
import Schedule from "./Schedule";
import Files from "./Files";
import CoverImage from "./CoverImage";
import Audience from "./Audience";

import { toast } from 'react-toastify';
 

const NewsletterSettingsPopup = ({ closeModal, selectedForSetting, setSelectedForSetting, save }) => {
    const token = useSelector(getToken);

    const tabs = settingsPopupTabs;
    const [activeTab, setActiveTab] = useState('Background Information');

    const ActiveComponent = activeTab === "Settings" ? Settings :
        activeTab === "Add-Ons" ? AddOns :
        activeTab === "Schedule" ? Schedule :
        activeTab === "Files" ? Files :
        activeTab === "Cover Image" ? CoverImage :
        activeTab === "Audience" ? Audience : null;

    const onSave = () => {
        console.log('selectedForSetting:', selectedForSetting);
        let errorText = null;
        if(!selectedForSetting?.settings?.backgroundInformation){
            errorText = 'Fill "Additional Background Information" input';
        }

        if(!selectedForSetting?.settings?.cover){
            errorText = 'Select "Cover Image" please';
        }

        if(errorText){
            toast.error(errorText);
        
            return false;
        }

        save();
    }



    return <div className='w-[calc(100vw-20px)] lg:w-[872px] bg-[#151515] relative rounded-[16px] max-h-[calc(100vh-60px)] overflow-y-scroll no-scroll flex flex-col newsletter-settings-popup'>
        <button 
            className='w-[34px] h-[34px] flex items-center justify-center rounded-[8px]
            absolute top-[16px] right-[16px] border border-solid border-[#333333] bg-[#1B1B1B]'
            onClick={closeModal}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g opacity="0.5">
                    <path d="M15 5L5 15" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 5L15 15" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </svg>
        </button>
        {
            selectedForSetting ? <div className='d-flex flex-col min-h-[calc(100vh-60px)]'>
                <div className="popup-header p-[20px] border-b border-solid border-[#1F1F1F]">
                    <h3 className="text-[20px] text-[#E8E8E8] font-[600] mb-[14px] leading-none">Newsletter Settings</h3>
                    <p className="text-[14px] text-[#FF8114] font-[300] leading-none">{ selectedForSetting?.title }</p>
                </div>
                <div className="settings-lesson-popup-body d-flex flex-wrap flex-1 flex-col lg:flex-row">
                    <div className="bg-[#121212] width-[250px] min-w-[250px] py-[24px] px-[20px] border-r border-solid border-[#1F1F1F]">
                        <ul>
                            {
                                tabs.map((tab, index) => {
                                    return <li key={index} className="d-block mb-[8px]">
                                        <a
                                            href="" className={
                                                `d-flex items-center text-[13px] mb-[8px] py-[9px] px-[10px] hover:text-[#E8E8E8] border-y border-x border-solid 
                                                ${activeTab === tab.name ? 'text-[#E8E8E8] bg-[#1B1B1B] border rounded-lg' : 'text-[#A7A7A7] border-transparent'}`
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab(tab.name);
                                            }}
                                        >
                                            <div className="d-flex items-center mr-[11px]">
                                                { tab.icon }
                                            </div>
                                            <span>{ tab.name }</span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="flex-1 d-flex flex-col">
                        <div className="flex-1 py-[24px] px-[24px] lg:px-[61px] relative">
                            {
                                activeTab === "Background Information" && <div>
                                    <label className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px] min-h-[16px]'>
                                        Additional Background Information
                                    </label>
                                    <div className='flex gap-[10px] mb-[10px]'>
                                        <textarea
                                            className="textarea min-h-[120px]"
                                            placeholder='Add some info to cater the lesson plan with specific elements... '
                                            value={selectedForSetting?.settings?.backgroundInformation}
                                            onChange={(e) => {
                                                // if(!selectedForSetting?.backgroundInformation) return false;
                                                setSelectedForSetting((prevState)=>({
                                                    ...prevState,
                                                    settings: {
                                                        ...prevState.settings,
                                                        backgroundInformation: e.target.value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                            { ActiveComponent &&<ActiveComponent
                                selectedForSetting={selectedForSetting}
                                setSelectedForSetting={setSelectedForSetting}
                            /> }
                        </div>
                        <div className="border-t border-solid border-[#1F1F1F] py-[16px] px-[20px] d-flex items-center justify-end">
                            <button
                                className="btn btn-outline-black btn-lg-2 rounded-full h-[36px] text-[14px] font-[500] mr-[8px]"
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}
                            >
                                <span>Cancel</span>
                            </button>
                            <button
                                className={`button-gradient hover:opacity-70 h-[36px] text-[14px] font-[500] py-[13px] px-[14px]`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSave();
                                }}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null
        }
    </div>
};

const ButtonTooltip = ({ tooltip }) => {
    return <Tippy
        offset={[0,4]}
        arrow={false}
        className='generator-button-tooltip'
        placement='bottom'
        content={tooltip}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.9991 8.47351L8.88414 7.98181C9.32583 7.73646 9.59977 7.27093 9.59977 6.76567C9.54745 5.92546 8.82688 5.28495 7.98633 5.33149C7.23528 5.30031 6.56834 5.80801 6.39844 6.54026" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="7.99859" cy="7.99957" r="6.0025" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.06814 10.6011C8.06809 10.6379 8.03822 10.6678 8.00141 10.6677C7.9646 10.6677 7.93476 10.6379 7.93475 10.6011C7.93473 10.5643 7.96454 10.5344 8.00135 10.5344C8.01907 10.5343 8.03607 10.5414 8.0486 10.5539C8.06113 10.5664 8.06816 10.5834 8.06814 10.6011" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Tippy>
};


export default NewsletterSettingsPopup;