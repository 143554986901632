import React, { useState } from "react";
import {Link, Outlet} from "react-router-dom";
import logo from '../assets/img/big-logo.png';
import ChatBot from "../routes/GeneratedContent/ChatBot";

const GeneratorLayout = () => {
    const [theme, setTheme] = useState('dark');
    return (
        <>
            <div className='gen4-backline'>
                <svg xmlns="http://www.w3.org/2000/svg" width="65%" height="65%" viewBox="0 0 677 640" fill="none">
                    <path d="M6.90641 161.142C-29.8624 35.851 118.652 -33.7166 230.681 18.6444C311.771 55.962 380.696 -2.3754 456.619 25.0928C580.145 69.81 495.848 137.777 601.719 198.138C715.031 265.662 700.023 493.187 541.486 494.596C399.993 496.004 440.801 554.916 319.14 618.953C197.479 682.991 25.0711 588.711 51.2709 443.546C77.4706 345.931 54.0355 318.687 6.90641 161.142Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="76%" height="76%" viewBox="0 0 812 763" fill="none">
                    <path d="M8.60151 193.354C-38.8723 28.3878 147.929 -38.9056 287.051 23.8208C382.2 65.3147 458.617 -12.424 554.683 22.3219C693.063 72.4001 583.872 165.354 721.708 235.707C866.159 312.428 831.47 589.391 652.329 591.504C488.338 593.617 535.114 658.55 388.751 744.223C242.388 829.897 24.748 706.151 64.0476 534.936C103.347 405.987 66.5049 391.411 8.60151 193.354Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="84%" height="84%" viewBox="0 0 949 821" fill="none">
                    <path d="M10.3014 215.79C-47.8773 11.1502 177.212 -53.8687 343.425 19.2228C452.634 64.8931 536.543 -32.2468 652.751 9.77668C805.985 65.216 671.901 183.156 841.702 263.503C1017.29 349.421 962.923 675.82 763.177 678.637C576.688 681.455 629.431 752.41 458.366 859.719C287.301 967.028 24.4297 813.816 76.8292 616.551C129.229 456.269 78.9791 454.362 10.3014 215.79Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="85%" height="85%" viewBox="0 0 1085 821" fill="none">
                    <path d="M12.0041 180.381C-56.8797 -63.9338 206.497 -126.678 399.803 -43.2217C523.071 6.62497 614.471 -109.916 750.823 -60.6151C918.91 0.185333 759.932 143.112 961.699 233.451C1168.43 328.566 1094.38 704.403 874.027 707.925C665.04 711.446 723.75 788.424 527.984 917.368C332.217 1046.31 24.1142 863.635 89.6135 640.32C155.113 448.705 91.456 459.465 12.0041 180.381Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="84%" height="84%" viewBox="0 0 1221 821" fill="none">
                    <path d="M13.7123 145.075C-65.8765 -138.914 235.788 -199.385 456.185 -105.563C593.514 -51.5395 692.406 -187.482 848.9 -130.903C1031.84 -64.7417 847.969 103.172 1081.7 203.504C1319.57 307.816 1225.84 733.09 984.884 737.315C753.397 741.541 818.076 824.541 597.607 975.121C377.139 1125.7 23.8041 913.557 102.403 664.193C181.003 441.244 103.939 464.673 13.7123 145.075Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="85%" height="85%" viewBox="0 0 1357 821" fill="none">
                    <path d="M15.4098 111.677C-74.8839 -211.987 265.068 -270.183 512.557 -165.996C663.945 -107.796 770.329 -263.14 946.966 -199.284C1144.76 -127.761 935.995 65.1387 1201.69 175.464C1470.7 288.972 1357.29 763.684 1095.73 768.614C841.745 773.544 912.391 862.566 667.22 1034.78C422.05 1207 23.4835 965.387 115.183 689.973C206.882 435.691 116.41 471.788 15.4098 111.677Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="85%" height="85%" viewBox="0 0 1440 821" fill="none">
                    <path d="M-5.884 79.8885C-106.883 -283.45 271.357 -339.371 545.938 -224.819C711.386 -162.443 825.261 -337.188 1022.04 -266.054C1234.69 -189.17 1001.03 28.7161 1298.69 149.034C1598.84 271.74 1465.75 795.888 1183.58 801.522C907.1 807.157 983.714 902.201 713.842 1096.05C443.97 1289.9 0.171427 1018.83 104.97 717.363C209.769 431.748 105.891 480.513 -5.884 79.8885Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="85%" height="85%" viewBox="0 0 1440 821" fill="none">
                    <path d="M-73.1734 48.0986C-184.877 -354.914 231.65 -408.561 533.323 -283.644C712.83 -217.092 834.198 -411.238 1051.12 -332.826C1278.62 -250.581 1020.07 -7.70844 1349.7 122.602C1680.98 254.505 1528.21 828.091 1225.44 834.429C926.461 840.768 1009.04 941.834 714.468 1157.32C419.894 1372.81 -69.1362 1072.27 48.7626 744.752C166.661 427.803 49.3757 489.236 -73.1734 48.0986Z" stroke="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="85%" height="85%" viewBox="0 0 1440 821" fill="none">
                    <path d="M-140.466 16.3146C-262.875 -426.373 191.94 -477.745 520.705 -342.463C714.271 -271.734 843.131 -485.281 1080.2 -399.592C1322.55 -311.986 1039.11 -44.127 1400.7 96.1766C1763.12 237.276 1590.67 860.299 1267.3 867.342C945.817 874.385 1034.37 981.473 715.09 1218.6C395.814 1455.72 -138.447 1125.71 -7.44862 772.146C123.55 423.865 -7.14288 497.966 -140.466 16.3146Z" stroke="white"/>
                </svg>
            </div>

            <div className={`gen4-layout relative`}>
                <Link to='/' className="lg:absolute lg:top-[25px] lg:left-[30px] mb-[10px] lg:mb-[0px]">
                    <img
                        src={logo}
                        alt="Logo"
                        className="h-[24px]"
                    />
                </Link>
                <ChatBot className='bottom-[20px] right-[16px]'/>
                {/* <div className="gen4-theme-switcher absolute top-[20px] right-[30px]">
                    <div className={`gen4-theme-part ${theme === 'light' && 'active'}`} onClick={() => setTheme('light')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M11.6517 6.34835C13.1161 7.81281 13.1161 10.1872 11.6517 11.6517C10.1872 13.1161 7.81281 13.1161 6.34835 11.6517C4.88388 10.1872 4.88388 7.81281 6.34835 6.34835C7.81281 4.88388 10.1872 4.88388 11.6517 6.34835" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9 3V1.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9 16.5V15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.7709 4.22999L14.3034 3.6975" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3.69664 14.3025L4.22914 13.77" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15 9H16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.5 9H3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.7709 13.77L14.3034 14.3025" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3.69664 3.6975L4.22914 4.22999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Light</span>
                    </div>
                    <div className={`gen4-theme-part ${theme === 'dark' && 'active'}`} onClick={() => setTheme('dark')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.0008 9.99999C11.4224 8.54031 11.7737 6.34488 10.8786 4.51445C10.8226 4.38119 10.8482 4.22762 10.9443 4.11965C11.0404 4.01168 11.19 3.96854 11.3288 4.00874C12.398 4.28433 13.3739 4.84107 14.1552 5.62116C16.5736 7.97713 16.6246 11.8473 14.2693 14.2663C11.8506 16.6218 7.98036 16.5711 5.62418 14.153C4.84364 13.3721 4.28659 12.3964 4.01101 11.3273C3.97061 11.1883 4.01381 11.0384 4.12202 10.9422C4.23023 10.846 4.38414 10.8207 4.51747 10.8771C6.34709 11.7718 8.54152 11.4208 10.0008 9.99999Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Dark</span>
                    </div>
                </div> */}
                <Outlet />
            </div>
        </>
    )
}

export default GeneratorLayout

// const GeneratorLayout = () => {
//     const [sidebarOpen, setSidebarOpen] = useState(true);
//
//     return (
//         <div className={`sidebar-layout ${!sidebarOpen ? 'sidebar-close' : ''}`}>
//             {/* <SideBar /> */}
//             <div className="wrapper sidebar-fixed">
//                 <main className="content">
//                     {/* <Header sidebarToggle={()=>{ setSidebarOpen((prevState)=>!prevState) }} /> */}
//                     <Outlet />
//                 </main>
//             </div>
//         </div>
//     )
// }
//
// export default GeneratorLayout