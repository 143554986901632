import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import Label from '../../../../components/base/Label';
import SelectBox from '../../../../components/base/SelectBox';

import { countriesOptions } from "../../../GeneratorV4/EmailEducatorNew/consts";

import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/icons-plus.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/icon-check-circle.svg';

const Settings = ({ selectedForSetting, setSelectedForSetting }) => {
    console.log('selectedForSetting:', selectedForSetting);

    const [competencyMapping, setCompetencyMapping] = useState(selectedForSetting?.settings?.competencyMapping?.length ? selectedForSetting?.settings?.competencyMapping.split(', ') : []);
    const [competencyMappingInput, setCompetencyMappingInput] = useState("");

    const [competencyMappingFocused, setCompetencyMappingFocused] = useState(false);
    
    useEffect(()=>{
        setSelectedForSetting((prevState)=>({
            ...prevState,
            settings: {
                ...prevState.settings,
                competencyMapping: competencyMapping.join(', ')
            }
        }))
    }, [competencyMapping])

    return <div>
        <div className="d-flex gap-[16px] flex-col lg:flex-row mb-[16px] lg:mb-[0px]">
            <div className='flex flex-col gap-[10px] lg:mb-[16px] lg:w-[68%]'>
                <Label>Lesson Type</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Select type', value: '', placeholder: true },
                        { label: 'Reporting', value: 'Reporting' },
                        { label: 'Analysis', value: 'Analysis' },
                        { label: 'Curation', value: 'Curation' },
                        { label: 'Artistic', value: 'Artistic' },
                        { label: 'Practical', value: 'Practical' },
                    ]}
                    value={selectedForSetting?.settings?.lessonType}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                lessonType: value
                            }
                        }))
                    }
                />
            </div>
            <div className='flex flex-col gap-[10px] lg:mb-[16px] lg:w-[32%]'>
                <Label>Number Of Lessons</Label>
                <input
                    type="number"
                    className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] text-[14px]'
                    placeholder='Number Of Lessons'
                    value={selectedForSetting?.settings?.numberOfLessons}
                    onChange={(e) => {
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                numberOfLessons: e.target.value
                            }
                        }))
                    }}
                />
            </div>
        </div>
        <div className="d-flex gap-[16px] flex-col lg:flex-row mb-[16px] lg:mb-[0px]">
            <div className='flex flex-col gap-[10px] lg:mb-[24px] flex-1'>
                <Label>Language</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Select language', value: '', placeholder: true },
                        { label: 'English', value: 'English' },
                        { label: 'Chinese', value: 'Chinese' },
                        { label: 'Hindi', value: 'Hindi' },
                        { label: 'Spanish', value: 'Spanish' },
                        { label: 'French', value: 'French' },
                        { label: 'Arabic', value: 'Arabic' },
                        { label: 'Bengali', value: 'Bengali' },
                        { label: 'Portuguese', value: 'Portuguese' },
                        { label: 'Indonesian', value: 'Indonesian' },
                        { label: 'Urdu', value: 'Urdu' },
                        { label: 'German', value: 'German' },
                    ]}
                    value={selectedForSetting?.settings?.language}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                language: value
                            }
                        }))
                    }
                />
            </div>
            <div className='flex flex-col gap-[10px] lg:mb-[24px] flex-1'>
                <Label>
                    <div className="d-flex items-center">
                        <div className="mr-[4px]">Country</div> <ButtonTooltip tooltip={<div className='max-w-[291px]'>Make your lessons more relevant and insightful by grounding them in local trends, traditions, and regulatory environments. By specifying a particular region, the generated content will be tailored to provide a nuanced understanding tailored to that locale.</div>} />
                    </div>
                </Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Select your country', value: '', placeholder: true },
                        ...countriesOptions,
                    ]}
                    value={selectedForSetting?.settings?.country}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                country: value
                            }
                        }))
                    }
                    isSearchable
                />
            </div>
        </div>
        <div className='mb-[24px] h-[1px] bg-[#1F1F1F]'></div>
        <div className="d-flex gap-[16px] flex-col lg:flex-row mb-[16px] lg:mb-[0px]">
            <div className='flex flex-col gap-[10px] lg:mb-[24px] lg:w-[33.33%]'>
                <Label>Subject Level</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Subject Level', value: '', placeholder: true },
                        { label: 'Intermediate', value: 'Intermediate' },
                        { label: 'Advanced', value: 'Advanced' },
                        { label: 'Expert', value: 'Expert' },
                        { label: 'Other', value: 'Other' },
                    ]}
                    value={selectedForSetting?.settings?.subjectLevel}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                subjectLevel: value
                            }
                        }))
                    }
                />
            </div>
            <div className='flex flex-col gap-[10px] lg:mb-[24px] lg:w-[33.33%]'>
                <Label>Reading Level</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Reading Level', value: '', placeholder: true },
                        { label: 'Early', value: 'Early' },
                        { label: 'Emergent', value: 'Emergent' },
                        { label: 'Transitional', value: 'Transitional' },
                        { label: 'Fluent', value: 'Fluent' },
                    ]}
                    value={selectedForSetting?.settings?.readingLevel}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                readingLevel: value
                            }
                        }))
                    }
                />
            </div>
            <div className='flex flex-col gap-[10px] lg:mb-[24px] lg:w-[33.33%]'>
                <Label>Special Education</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={[
                        { label: 'Special Education', value: '', placeholder: true },
                        { label: 'ESE (special needs)', value: 'ESE (special needs)' },
                        { label: 'ESL', value: 'ESL' },
                        { label: 'None', value: 'None' },
                    ]}
                    value={selectedForSetting?.settings?.specialEducation}
                    change={(value) =>
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                specialEducation: value
                            }
                        }))
                    }
                />
            </div>
        </div>
        <div className="d-flex gap-[16px]">
            <div className='flex flex-col gap-[10px] mb-[24px] flex-1'>
                <Label>
                    <div className="d-flex items-center">
                        <div className="mr-[4px]">Competency Mapping</div> <ButtonTooltip tooltip={<div className='max-w-[186px]'>Enter the skills or competencies you aim to address in your lessons. The content will then be generated to target these areas specifically.</div>} />
                    </div>
                </Label>
                <div className={`min-h-[40px] flex-1 bg-[#1B1B1B] rounded-[8px] border border-solid text-[14px] mb-[10px] d-flex flex-wrap gap-[8px] px-[8px] ${!competencyMapping?.length ? 'py-[8px]' : 'py-[5px]'} ${competencyMappingFocused ? 'border-[#FF8114]' : 'border-[#333]'}`}>
                    {
                        competencyMapping.map(item => {
                            return <div
                                index={item}
                                style={{ borderRadius: "4px", background: "#151515", padding: "5px 6px" }}
                                className="d-flex items-center text-[12px] text-[#8A8A8A]"
                            >
                                { item }
                                <a href="#" className="d-flex" onClick={(e) => {
                                    e.preventDefault();
                                    setCompetencyMapping(competencyMapping.filter(itemC => itemC !== item));
                                }}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                            <path d="M5.33203 5.83398L10.6654 11.1673" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.6654 5.83398L5.33203 11.1673" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        })
                    }
                    <input
                        type="text"
                        className={`bg-[#1B1B1B] text-[14px] flex-1 ${!competencyMapping?.length ? 'pl-[6px]' : 'pr-[8px]'}`}
                        placeholder='Enter the skills or competencies you aim to address in your lessons...'
                        value={competencyMappingInput}
                        onChange={(e) => {
                            setCompetencyMappingInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter'){
                                setCompetencyMapping((prevState)=> ([...prevState, competencyMappingInput]));

                                setCompetencyMappingInput("");
                            }
                        }}
                        onFocus={()=>{ setCompetencyMappingFocused(true); }}
                        onBlur={()=>{ setCompetencyMappingFocused(false); }}
                    />
                </div>
                <ul className='flex gap-[8px] flex-wrap'>
                    { [
                        "Critical Thinking",
                        "Team Collaboration",
                        "Digital Literacy",
                        "Leadership and Management",
                        "Communication Skills",
                        "Problem Solving",
                        "Cultural Awareness",
                        "Time Management",
                    ].map((option, index) => <li
                        key={ index }
                        className={classNames(`flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                        border border-solid bg-[#202020] rounded-[24px] cursor-pointer`,
                        competencyMapping.includes(option) ? 'border-orange-primary text-orange-primary' : 'border-[#383838] text-[#ffffff80]')}
                        onClick={() => {
                            if(competencyMapping.includes(option)) {
                                setCompetencyMapping(competencyMapping.filter(item => item !== option));
                            } else {
                                setCompetencyMapping([...competencyMapping, option]);
                            }
                        }}
                    >
                        { option }
                        { competencyMapping.includes(option) ? <CheckIcon /> : <PlusIcon /> }
                    </li>)}
                </ul>
            </div>
        </div>
    </div>
};

const ButtonTooltip = ({ tooltip }) => {
    return <Tippy
        offset={[0,4]}
        arrow={false}
        className='generator-button-tooltip'
        placement='bottom'
        content={tooltip}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.9991 8.47351L8.88414 7.98181C9.32583 7.73646 9.59977 7.27093 9.59977 6.76567C9.54745 5.92546 8.82688 5.28495 7.98633 5.33149C7.23528 5.30031 6.56834 5.80801 6.39844 6.54026" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="7.99859" cy="7.99957" r="6.0025" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.06814 10.6011C8.06809 10.6379 8.03822 10.6678 8.00141 10.6677C7.9646 10.6677 7.93476 10.6379 7.93475 10.6011C7.93473 10.5643 7.96454 10.5344 8.00135 10.5344C8.01907 10.5343 8.03607 10.5414 8.0486 10.5539C8.06113 10.5664 8.06816 10.5834 8.06814 10.6011" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Tippy>
};


export default Settings;