import React, { useState, useEffect, useRef } from 'react'
import SelectBox from '../../components/base/SelectBox';
import MultiSelect from '../../components/base/MultiSelect';
import { NewWordsBigSwiper } from './NewWordsBigSwiper';
// import { newWordsData } from './newWordsData';
import DatePicker from '../../components/base/DatePicker';
import GeneratedContentModal from '../GeneratedContent/GeneratedContentModal';
import api from '../../api';

import { useSelector } from 'react-redux';
import { getToken, getUser, getFormOptions } from '../../redux/slices/applicationSlice';
import { useDebounce } from "../../helpers/useDebounce";
import getUserImageSrc from '../../helpers/getUserImageSrc';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Pagination } from './Pagination';
// import { categoryOptions, styleOptions } from '../../configs/selectOptions';
import PrivateButton from './PrivateButton';
import WordCard from './WordCard';

export const highlightWordInText = (text, wordToHighlight) => {
    const words = text.split(' ')
  
    let highlighted = false
    const result = words.map((word, index) => {
        if (word.toLowerCase() === wordToHighlight.toLowerCase()) {
            highlighted = true
            return (
                `<span key=${index} class="font-500">
                    ${word}
                </span>`
            );
        } else {
            if (highlighted) {
                highlighted = false
                return `</span>${word}`
            } else {
                return word
            }
        }
    });
  
    if (highlighted) {
        result.push('</span>')
    }
  
    const formattedText = result.join(' ')
    return (
        `<p class="comment-text">
            ${formattedText}
        </p>`
    )
}

const NewWords = () => {
    const token = useSelector(getToken);
    const user = useSelector(getUser);
    const swiperRef = useRef(null);
    const [createSelectOpen, setCreateSelectOpen] = useState(false);

    const [updatedTimes, setUpdatedTimes] = useState(0);
    const [paginationData, setPaginationData] = useState({});
    const isUpdated = useDebounce(updatedTimes, 1500);

    const formOptions = useSelector(getFormOptions);

    const languagesOptions = formOptions.filter(({key}) => key === 'language').map(item => ({ label: item.title, value: item.title }));
    const categoryOptions = formOptions.filter(({key}) => key === 'word_category').map(item => ({ label: item.title, value: item.title }));
    const styleOptions = formOptions.filter(({key}) => key === 'word_style').map(item => ({ label: item.title, value: item.title }));

    const [categories, setCategories] = useState([]);
    const [styles, setStyles] = useState([]);
    const [languages, setLanguages] = useState([]);
    console.log('languages:', languages);

    const [newWordsData, setNewWordsData] = useState([]);

    const [cardView, setCardView] = useState(true);

    const [activeWord, setActiveWord] = useState(null);

    const [search, setSearch] = useState('');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const [sortBy, setSortBy] = useState('name-asc');


    const [myWords, setMyWords] = useState(false);
    console.log(swiperRef.current);

    const fetchData = async() => {
        if(!token) return;

        let queries = '';

        const addQuery = (query, value) => {
            queries += queries.length ? `&${query}=${value}` : `${query}=${value}`;
        }

        if(myWords) { addQuery('my_words', true); }

        if(search?.length) { addQuery('search', search); }

        if(categories?.length) { addQuery('word_category', categories.join(',')); }
        
        if(styles?.length) { addQuery('word_style', styles.join(',')); }

        if(languages?.length) { addQuery('language', languages.join(',')); }

        if(startDate && endDate) {
            addQuery('createdAt', `${startDate.toISOString()},${endDate.toISOString()}`);
        }

        if (paginationData.page) {
            addQuery('page', paginationData.page)
        }

        const sortOptions = sortBy.split('-');
        addQuery('sortBy', sortOptions[0]);
        addQuery('sort', sortOptions[1]);
        addQuery('isSimilar', true);

        await api.newWords.getWords({ token, queries }).then(data => {
            setNewWordsData(data.data);
            setPaginationData(data.pagination);
        })
    }

    useEffect(()=>{
        fetchData();  
    }, [myWords, isUpdated, categories, styles, startDate, endDate, paginationData.page, sortBy, languages])

    const [activeDropdownId, setActiveDropdownId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);

    const handleSetMyWords = (checked) => {
        setMyWords(checked);
        if (paginationData.page) {
            setPaginationData(prev => ({...prev, page: 1}))
        }

    }
    return (
        <div className="page-section pt-[0px]">
            <div className="container">
                <div className="assets-page">
                    <div className="d-flex align-items-center justify-between py-[16px] px-[20px] sm-filter-wrap">
                        <div className="d-flex items-center flex-1 sm-filter-wrap">
                            <h3 className="text-[18px] font-[600] text-[#ffffff] lh-n mr-[24px]">New Words</h3>
                            {/* <p className="text-[14px] font-[400] text-[#737373] lh-n">Where creativity meets language – discover, create, and share positive vocabulary</p> */}
                            <div className="relative mr-[8px] max-w-[400px] w-100 sm-mt">
                                <svg
                                    width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    style={{ position: 'absolute', left: '10px', top: '50%', transform: "translateY(-50%)", pointerEvents: 'none' }}
                                >
                                    <g opacity="0.3">
                                        <path d="M16.6667 16.6662L13.647 13.6465" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.33325 9.37467C3.33325 12.7114 6.0382 15.4163 9.37492 15.4163C12.7116 15.4163 15.4166 12.7114 15.4166 9.37467C15.4166 6.03795 12.7116 3.33301 9.37492 3.33301V3.33301C6.0383 3.33325 3.3335 6.03806 3.33325 9.37467" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>
                                
                                <input
                                    type="search" className="form-search-radiused__input" placeholder="Search new words..."
                                    value={search} onInput={(e) => { setSearch(e.target.value); setUpdatedTimes((prevState) => prevState + 1) }}
                                />
                            </div>
                        </div>

                        <div className="d-flex items-center sm-mt"> 
                                <Link
                                    to='/generated-content?type=new-word'
                                    className="btn btn--primary btn--lg d-flex align-items-center justify-between px-[10px] py-[7px] orange-rounded-drop-btn h-[36px]"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path  d="M9.99967 6.66666V13.3333" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path  d="M13.3337 10H6.66699" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span className='ml-[8px] text-[14px] font-medium'>Create New Word</span>
                                </Link>
                            <div
                                className="btn btn-outline-black btn-lg-2 rounded-[8px] d-flex align-items-center justify-between px-[10px] py-[6px] ml-[8px]">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setCardView(true);
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.95833 9.37496C3.43438 9.37496 2.98741 9.1865 2.61744 8.80958C2.24748 8.43268 2.0625 7.98225 2.0625 7.45829V3.95829C2.0625 3.43433 2.24748 2.9839 2.61744 2.607C2.98741 2.23008 3.43438 2.04163 3.95833 2.04163H7.47917C7.98924 2.04163 8.43272 2.23008 8.80963 2.607C9.18654 2.9839 9.375 3.43433 9.375 3.95829V7.45829C9.375 7.98225 9.18654 8.43268 8.80963 8.80958C8.43272 9.1865 7.98924 9.37496 7.47917 9.37496H3.95833ZM3.95833 17.9375C3.43438 17.9375 2.98741 17.7525 2.61744 17.3825C2.24748 17.0125 2.0625 16.5656 2.0625 16.0416V12.5208C2.0625 12.0107 2.24748 11.5672 2.61744 11.1903C2.98741 10.8134 3.43438 10.625 3.95833 10.625H7.47917C7.98924 10.625 8.43272 10.8134 8.80963 11.1903C9.18654 11.5672 9.375 12.0107 9.375 12.5208V16.0416C9.375 16.5656 9.18654 17.0125 8.80963 17.3825C8.43272 17.7525 7.98924 17.9375 7.47917 17.9375H3.95833ZM12.5417 9.37496C12.0177 9.37496 11.5673 9.1865 11.1904 8.80958C10.8135 8.43268 10.625 7.98225 10.625 7.45829V3.95829C10.625 3.43433 10.8135 2.9839 11.1904 2.607C11.5673 2.23008 12.0177 2.04163 12.5417 2.04163H16.0417C16.5656 2.04163 17.0161 2.23008 17.393 2.607C17.7699 2.9839 17.9583 3.43433 17.9583 3.95829V7.45829C17.9583 7.98225 17.7699 8.43268 17.393 8.80958C17.0161 9.1865 16.5656 9.37496 16.0417 9.37496H12.5417ZM12.5417 17.9375C12.0177 17.9375 11.5673 17.7525 11.1904 17.3825C10.8135 17.0125 10.625 16.5656 10.625 16.0416V12.5208C10.625 12.0107 10.8135 11.5672 11.1904 11.1903C11.5673 10.8134 12.0177 10.625 12.5417 10.625H16.0417C16.5656 10.625 17.0161 10.8134 17.393 11.1903C17.7699 11.5672 17.9583 12.0107 17.9583 12.5208V16.0416C17.9583 16.5656 17.7699 17.0125 17.393 17.3825C17.0161 17.7525 16.5656 17.9375 16.0417 17.9375H12.5417Z"
                                            fill={cardView ? '#FF8114' : '#A7A7A7'}/>
                                    </svg>
                                </a>
                                <a href="#" className="ml-[10px]" onClick={(e) => {
                                    e.preventDefault();
                                    setCardView(false);
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3 17.9375C2.7375 17.9375 2.51563 17.8468 2.33438 17.6656C2.15313 17.4843 2.0625 17.2625 2.0625 17V14.375C2.0625 14.1125 2.15313 13.8906 2.33438 13.7093C2.51563 13.5281 2.7375 13.4375 3 13.4375H17C17.2625 13.4375 17.4878 13.5281 17.676 13.7093C17.8642 13.8906 17.9583 14.1125 17.9583 14.375V17C17.9583 17.2625 17.8642 17.4843 17.676 17.6656C17.4878 17.8468 17.2625 17.9375 17 17.9375H3ZM3 12.25C2.7375 12.25 2.51563 12.1607 2.33438 11.9823C2.15313 11.8038 2.0625 11.5736 2.0625 11.2916V8.70829C2.0625 8.44579 2.15313 8.22045 2.33438 8.03225C2.51563 7.84406 2.7375 7.74996 3 7.74996H17C17.2625 7.74996 17.4878 7.84406 17.676 8.03225C17.8642 8.22045 17.9583 8.44579 17.9583 8.70829V11.2916C17.9583 11.5736 17.8642 11.8038 17.676 11.9823C17.4878 12.1607 17.2625 12.25 17 12.25H3ZM3 6.56246C2.7375 6.56246 2.51563 6.47175 2.33438 6.29033C2.15313 6.1089 2.0625 5.88017 2.0625 5.60413V2.99996C2.0625 2.73746 2.15313 2.51211 2.33438 2.32392C2.51563 2.13572 2.7375 2.04163 3 2.04163H17C17.2625 2.04163 17.4878 2.13572 17.676 2.32392C17.8642 2.51211 17.9583 2.73746 17.9583 2.99996V5.60413C17.9583 5.88017 17.8642 6.1089 17.676 6.29033C17.4878 6.47175 17.2625 6.56246 17 6.56246H3Z"
                                            fill={cardView ? '#A7A7A7' : '#FF8114'}/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="assets-page-body">
                        {/* <div className="px-[20px] bb-1-grey d-flex align-items-center justify-between">
                            <ul className="tabs-controls">
                                {
                                    [
                                        'All Words',
                                        'Emotions',
                                        'Nature',
                                        'Technology',
                                        'Art',
                                        'Connections',
                                        'Wellness',
                                        'Inspiration',
                                        'Communication',
                                        'Culture',
                                    ].map((item, index) => {
                                        return <li key={index}>
                                            <a href="#" className={mainFilter === item ? `active` : ''} onClick={(e)=>{
                                                e.preventDefault();
                                                setMainFilter(item);
                                            }}>
                                                {item}
                                            </a>
                                        </li>
                                    })
                                }
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.9987 6.66666V13.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.3346 10H6.66797" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </li>
                            </ul>
                        </div> */}

                        <div className="px-[20px] d-flex align-items-center justify-between sm-filter-wrap relative z-10">
                            <div className="d-flex align-items-center flex-1 sm-filter-wrap" style={{maxWidth: "700px"}}>
                                <MultiSelect
                                    className="min-w-[150px] mr-[8px] sm-half-width sm-mt"
                                    placeholder="Categories"
                                    options={categoryOptions.filter(item => !item.placeholder)}
                                    value={categories}
                                    change={setCategories}
                                />
                                <MultiSelect
                                    className="min-w-[150px] mr-[8px] sm-half-width sm-mt"
                                    placeholder="Styles"
                                    options={styleOptions.filter(item => !item.placeholder)}
                                    value={styles}
                                    change={setStyles}
                                />
                                <MultiSelect
                                    className="min-w-[150px] mr-[8px] sm-full-width"
                                    placeholder="Languages"
                                    options={languagesOptions.filter(item => !item.placeholder)}
                                    value={languages}
                                    change={setLanguages}
                                />
                                <div className='settings-checkbox-container checkbox-new-words'>
                                    <div className='sett-checkbox-desc'>
                                        <span className='sett-title'>My Words</span>
                                    </div>
                                    <label htmlFor='a2' className='sett-checkbox'>
                                        <input id='a2'  type="checkbox" className='sett-checkbox-hid' hidden checked={myWords} onChange={(e)=> handleSetMyWords(e.target.checked)} />
                                        <div className='sett-checkbox-sub'></div>
                                    </label>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-between sm-filter-wrap">
                                <DatePicker
                                    className='new-word-datepicker select-color-white sm-half-width'
                                    selected={startDate}
                                    onChange={onDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText="Date Created"
                                    dateFormat="dd/MM"
                                />
                                <SelectBox
                                    //  only-icon
                                    //  sort-select-box
                                    className="default-select flex-1 w-auto ml-[8px] sort-select-box small-select min-w-[150px] select-color-white sm-half-width"
                                    propsOptions={[
                                        {label: 'Name (A-Z)', value: 'name-asc'},
                                        {label: 'Name (Z-A)', value: 'name-desc'},
                                        {label: 'Popularity', value: 'popularity-desc'},
                                        {label: 'Time (new-old)', value: 'time-desc'},
                                        {label: 'Time (old-new)', value: 'time-asc'},
                                    ]}
                                    icon={
                                        <span style={{
                                            color: '#ffffff',
                                            fontSize: '14px',
                                            fontWeight: '400'
                                        }}>Sort by</span>
                                    }
                                    value={sortBy}
                                    change={(value) => {
                                        setSortBy(value);
                                    }}
                                    menuPosition="absolute"
                                />



                                {/* <a href="#"
                                    className="btn btn-outline-black btn-lg-2 rounded-[8px] d-flex align-items-center justify-between px-[8px] py-[6px] ml-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9.21698" cy="9.21549" r="5.88495" stroke="#A7A7A7"
                                                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.668 16.6694L13.375 13.3764" stroke="#A7A7A7" strokeWidth="1.5"
                                                strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        {newWordsData.length ? <div className="p-[20px] pt-[16px]">
                            { cardView ? <div className="pb-[16px]">
                                <NewWordsBigSwiper setOpenDeleteModal={setOpenDeleteModal} data={newWordsData} fetchData={()=>{ fetchData(); }}/>
                            </div> : null }

                            <div className={`custom-row custom-row-15 custom-row--5 new-words-cards-row ${ !cardView ? 'row-view' : '' }`}>
                                {newWordsData.map((el,idx) => <WordCard
                                    activeDropdownId={activeDropdownId}
                                    el={el}
                                    idx={idx}
                                    setActiveDropdownId={setActiveDropdownId}
                                    setActiveWord={setActiveWord}
                                    setOpenDeleteModal={setOpenDeleteModal}
                                    key={el.id}
                                />)}
                            </div>

                            {paginationData.page && <Pagination
                                currentPage={paginationData.page}
                                totalPages={paginationData.maxPages}
                                onPageChange={(page) => {setPaginationData(prev => ({...prev, page}))}}
                            />}

                        </div> : <div className='my-[30px] text-center text-[18px] font-semibold'>
                            No words found matching your query
                        </div>}
                    </div>
                </div>
            </div>

            { activeWord !== null && 
                <GeneratedContentModal
                    isOpened={activeWord !== null}
                    close={() => setActiveWord(null)}
                    modalContentClassList="modal__content max-w-[1080px]"
                    showCloseBtn
                    modalClassList="single-word-modal"
                >
                    <div className="w-100">
                        <NewWordsBigSwiper setOpenDeleteModal={setOpenDeleteModal} data={newWordsData} initialSlide={activeWord} ref={swiperRef} fetchData={()=>{fetchData();}} />
                        <div className="px-[20px] pb-[20px]">
                            <div className="settings-divider new-word-divider"></div>
                    
                            <div className='mb-[24px]'>
                                <h3 className="text-[18px] font-[600] text-[#ffffff] mb-[12px] lh-n">More New Words</h3>
                                <p className="text-[14px] font-[400] text-[#737373] lh-n">Discover other new words from gallery</p>
                            </div>

                            <div>
                                <div className="custom-row custom-row-15 custom-row--5">
                                    {newWordsData.map((el,idx) => <div key={idx} onClick={() => {
                                        // setActiveWord(idx);
                                        swiperRef.current.swiper.slideTo(idx);
                                        swiperRef.current.scrollIntoView({ behavior: "smooth"});
                                    }}>
                                        <WordCard
                                            activeDropdownId={activeDropdownId}
                                            el={el}
                                            idx={idx}
                                            setActiveDropdownId={setActiveDropdownId}
                                            setActiveWord={setActiveWord}
                                            setOpenDeleteModal={setOpenDeleteModal}
                                            key={el.id}
                                            destination='popup'
                                        />
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </GeneratedContentModal>
            }
            {openDeleteModal !== null && <GeneratedContentModal isOpened={openDeleteModal !== null} close={() => setOpenDeleteModal(null)} >
                <div className="modal__body w-[450px] modal-body-flex">
                    <div className="text-center">
                        <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                            Do you really want to delete <span className='text-orange-primary'>{openDeleteModal.word}</span> ?
                        </h3>
                    </div>
                    <div className="d-flex items-center gap-[10px]">
                        <button
                            className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                            onClick={() => { setOpenDeleteModal(null); }}
                        >
                            <span className="text-[14px] font-medium">Close</span>
                        </button>
                        <button
                            className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                            onClick={async () => {
                                try {
                                    const response = await api.newWords.deleteNewWord({
                                        id: openDeleteModal.id,
                                        token
                                    });
                                    if(!response.success) return;
                                    setNewWordsData(newWordsData.filter(({id}) => id !== openDeleteModal.id));
                                    setOpenDeleteModal(null);
                                } catch (e) {
                                    console.log(e);
                                }
                            }}
                        >
                            <span className="text-[14px] font-medium">Confirm</span>
                        </button>
                    </div>
                </div> 
            </GeneratedContentModal>}
        </div>
    )
}

export default NewWords