import React, { useContext, useEffect, useState, } from 'react';
import { ReactComponent as RandomGenerateIcon } from '../../../assets/img/icons/random-generate-icon.svg';

import { GenerateFormContext, initialState } from '../GeneratorContextComponent';
import Settings from "./Settings";

import { getSocket, getToken, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { db } from "../../../db";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import api from '../../../api';
import { toast } from 'react-toastify';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';
import { v4 } from 'uuid';
import PrimaryButton from '../../../components/base/PrimaryButton';

const Step2 = ({ setStep, generateRandom, randomGenerateLoading, currentCredits, priceCredits, changePayloadForCalculate }) => {
    const [searchParams] = useSearchParams();

    const socket = useSelector(getSocket);
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const bitId = searchParams.get('bitId');

    const navigate = useNavigate();
    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { selectedMethod, courses, } = generateForm;
    const [selectedForSetting, setSelectedForSetting] = useState(null);
    const [setupAll, setSetupAll] = useState(false);

    const [haveSettings, setHaveSettings] = useState([]);

    console.log('haveSettings:', haveSettings);

    const stepTwoDisabled = courses?.length !== haveSettings?.length;

    const courseSave = () => {
        if(!selectedForSetting?.backgroundInformation){
            toast.error('Fill "Additional Background Information" input');
        
            return false;
        }
        

        if(setupAll){
            for (let index = 0; index < courses.length; index++) {
                if(!haveSettings.includes(courses[index]?.text)){
                    setHaveSettings((prevState) => ([...prevState, courses[index]?.text]));
                    setGenerateForm((prevState) => ({
                        ...prevState,
                        courses: prevState.courses.map((itemC, itemIndex) => (itemIndex !== index ? itemC : {
                            ...itemC,
                            ...selectedForSetting,
                            text: itemC.text,
                        } ))
                    }))
                }
            }
        } else {
            const findedIndex = courses.findIndex(item => item.text === selectedForSetting?.text);

            setGenerateForm((prevState) => ({
                ...prevState,
                courses: prevState.courses.map((itemC, itemIndex) => (itemIndex !== findedIndex ? itemC : selectedForSetting ))
            }))

            if(!haveSettings.includes(selectedForSetting?.text)){
                setHaveSettings((prevState) => ([...prevState, selectedForSetting?.text]));
            }
        }

        setSetupAll(false);
        setSelectedForSetting(null);
    }
    console.log('selectedForSetting',selectedForSetting)
    const submit = async() => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));
            return;
        }

        const forPayload = courses.map(course => ({
            method: course.method,
            text: course.text,
            backgroundInformation: course.backgroundInformation,
            language: course.language,
            country: course.country,
            numberOfLessons: course.numberOfLessons,
            lessonType: course.lessonType,
            subjectLevel: course.subjectLevel,
            readingLevel: course.readingLevel,
            specialEducation: course.specialEducation,
            competencyMapping: course.competencyMapping,
            addons: course.addons,
            scheduleLessonFrequency: course.scheduleLessonFrequency,
            scheduleRepeatOn: course.scheduleRepeatOn,
            scheduleBegins: course.scheduleBegins,
            scheduleTime: course.scheduleTime,
            scheduleIsSendOneMessage: course.scheduleIsSendOneMessage,
        }));
        
        let dbIds = [];

        const createCourses = async() => {
            if(!db?.courses){
                db.version(1).stores({
                    courses: '++id, title, description, lessons, settings, isDone',
                    history: '++id, title, courses'
                });
            }

            for (let index = 0; index < courses.length; index++) {
                const dexieItemId = await db.courses.add({
                    settings: courses[index]
                });
                dbIds.push(dexieItemId);
            }
            
        }

        await createCourses();

        const eventId = v4();

        const toastId = toast.loading(<div className='flex flex-col items-center gap-[10px]'>
            <p>{`Generating your courses, please wait...`}</p>
            <div className="d-flex items-center gap-[10px]">
                <PrimaryButton 
                    className="min-w-[100px]"
                    onClick={() => { 
                        navigate('/generated-page/courses')
                    }}
                >View</PrimaryButton>
                <PrimaryButton onClick={() => {
                    socket.send({
                        event: 'stop-event-generation',
                        payload: {
                            id: eventId
                        },
                        handler: (stopResponse) => {
                            console.log(stopResponse);
                            toast.dismiss(toastId)
                        }
                    })
                }}>Stop Generation</PrimaryButton>
            </div>
        </div>);

        socket.send({
            id: eventId,
            event: 'lesson-kit-generate-cources',
            payload: {
                courses: forPayload
            },
            handler: async(response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");

                    return;
                }
                console.log('response' ,response)
                response.payload.forEach(async(item, index) => {
                    let payloadForDb = {
                        id: dbIds[index],
                        title: item.title,
                        description: item.description,
                        lessons: item.lessons,
                        isDone: item.isDone,
                        settings: courses[index],
                        materials: [],
                        materials_files: [],
                    };

                    if(response.isDone){
                        payloadForDb.lessons?.forEach(async(lesson) => {
                            lesson.materials = [];

                            if(lesson?.addons && Object.entries(lesson.addons)?.length){
                                await Promise.all(
                                    Object.entries(lesson.addons).map(async(addonEntries) => {
                                        const response = await api.courses.uploadAddon({
                                            data: {
                                                type: 'addon',
                                                title: addonEntries[0].split('_').map(item => `${item[0].toUpperCase()}${item.slice(1)}`).join(' '),
                                                content: addonEntries[1]
                                            },
                                            token
                                        });

                                        return response;
                                    } )
                                ).then(async(values) => {
                                    lesson.materials = [...lesson.materials, ...values.map(item => ({
                                        id: item.data.id,
                                        content: item.data.content,
                                        type: item.data.type,
                                        title: item.data.title,
                                    }))];

                                    payloadForDb.materials = [...payloadForDb.materials, ...values.map(item => item.data.id)];

                                    await db.courses.put(payloadForDb);
                                });
                            }
                        });

                        if(courses[index]?.files && courses[index]?.files?.length){
                            await Promise.all(
                                courses[index]?.files.map(async(fileItem) => {
                                    const response = await api.courses.uploadAddon({
                                        data: {
                                            type: 'file',
                                            file: fileItem
                                        },
                                        token
                                    });

                                    return response;
                                } )
                            ).then(async(values) => {
                                payloadForDb.materials = [...payloadForDb.materials, ...values.map(item => item.data.id)];
                                payloadForDb.materials_files = [...payloadForDb.materials_files, ...values];

                                await db.courses.put(payloadForDb);
                            });
                        }
                    }

                    await db.courses.put(payloadForDb);
                });

                if(response?.isDone) {
                    dispatch(getUserUpdateThunk({ token }));

                    toast.update(toastId, {
                        render: 'Generated successfully!',
                        type: 'success', 
                        isLoading: false,
                        autoClose: 5000
                    });

                    // const value = await db.courses.toArray();
                    // const dexieItemId = await db.history.add({
                    //     title: value[0].title,
                    //     courses: value
                    // });
                    // console.log(dexieItemId);
                }
            }
        });

        navigate('/generated-page/courses');
    }

    useEffect(()=>{
        if(!haveSettings?.length && courses){
            courses.forEach((course) => {
                if(course?.haveSettings){
                    setHaveSettings((prevState) => ([...prevState, course?.text]));
                }
            })
        }
    }, [courses])

    useEffect(() => {
        const forPayload = courses.map(course => ({
            method: course.method,
            text: course.text,
            backgroundInformation: course.backgroundInformation,
            language: course.language,
            country: course.country,
            numberOfLessons: course.numberOfLessons,
            lessonType: course.lessonType,
            subjectLevel: course.subjectLevel,
            readingLevel: course.readingLevel,
            specialEducation: course.specialEducation,
            competencyMapping: course.competencyMapping,
            addons: course.addons,
            scheduleLessonFrequency: course.scheduleLessonFrequency,
            scheduleRepeatOn: course.scheduleRepeatOn,
            scheduleBegins: course.scheduleBegins,
            scheduleTime: course.scheduleTime,
            scheduleIsSendOneMessage: course.scheduleIsSendOneMessage,
        }));

        changePayloadForCalculate({
            event: 'lesson-kit-generate-cources',
            data: {
                courses: forPayload
            }
        })
    }, [courses])

    return <>
        <div className='gen4-content email-educator-config-component'>
            {
                !selectedForSetting ? <div className='w-full max-w-[500px] m-auto mt-0'>
                    <div className="get4-content-header mb-[36px]">
                        <div className='flex items-center justify-center gap-[12px]'>
                            <h1 className='text-[24px] font-semibold text-[#ffffffe6] leading-none'>
                                Set Final Options
                            </h1>
                        </div>
                        <p className='text-[14px] leading-[22px] text-[#a7a7a780] mt-[20px]'>
                            Setup schedule, upload files and other details for your newsletter(s)
                        </p>
                    </div>
                    <div className='w-100 mb-[16px]'>
                        <div className="d-flex items-center justify-between mb-[19px]">
                            <div className="text-[12px] text-[#A7A7A7]">{ courses.length } Newsletter(s)</div>
                            <button
                                className="text-[12px] text-[#FF8114] d-flex items-center"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setSetupAll(true);
                                    setSelectedForSetting({
                                        method: courses[0].method,
                                        text: "",
                                        backgroundInformation: "",
                                        language: "",
                                        country: "",
                                        numberOfLessons: 3,
                                        lessonType: "",
                                        subjectLevel: "",
                                        readingLevel: "",
                                        specialEducation: "",
                                        competencyMapping: "",
                                        addons: [],
                                        files: [],
                                        cover: null,
                                        scheduleLessonFrequency: '',
                                        scheduleRepeatOn: '',
                                        scheduleBegins: '',
                                        scheduleTime: '',
                                        scheduleIsSendOneMessage: false,
                                    });
                                }}
                            >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-[5px]'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.33429 7.50358L2.45695 6.51425C2.26656 6.29955 2.23492 5.98711 2.3784 5.73859L3.23121 4.26142C3.37468 4.01291 3.66107 3.8841 3.9422 3.94162L5.23773 4.20671L6.09751 3.71029L6.51567 2.45585C6.60641 2.18362 6.86117 2 7.14812 2H8.85379C9.14074 2 9.3955 2.18362 9.48624 2.45585L9.90439 3.71029L10.7642 4.20671L12.0597 3.94162C12.3408 3.8841 12.6272 4.01291 12.7707 4.26142L13.6235 5.73857C13.767 5.98708 13.7353 6.29952 13.545 6.51422L12.6676 7.50358V8.49642L13.545 9.48575C13.7353 9.70045 13.767 10.0129 13.6235 10.2614L12.7707 11.7386C12.6272 11.9871 12.3408 12.1159 12.0597 12.0584L10.7641 11.7933L9.90437 12.2896L9.48621 13.5441C9.39548 13.8164 9.14073 14 8.85378 14H7.14813C6.86117 14 6.60641 13.8164 6.51567 13.5441L6.09751 12.2896L5.23777 11.7933L3.94219 12.0584C3.66107 12.1159 3.37468 11.9871 3.23121 11.7386L2.37838 10.2614C2.23491 10.0129 2.26656 9.70048 2.45695 9.48578L3.33429 8.49642V7.50358Z" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                    <circle cx="8" cy="8" r="2" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Setup All
                            </button>
                        </div>
                        <ul>
                            {
                                courses.map((item, index)=>{
                                    return <li key={index} className='d-flex items-center justify-between py-[15px] border-t border-[#1F1F1F] border-solid'>
                                        <div className='pr-[5px]'>
                                            <h3 className="text-[14px] text-[#E8E8E8]">{ item.text }</h3>
                                            <div className="d-flex items-center text-[12px] mt-[3px]">
                                                <span className={`${haveSettings.includes(item.text) ? 'text-[#FF8114]' : 'text-[#A7A7A7] opacity-40'}`}>
                                                    {
                                                        haveSettings.includes(item.text) ? <span className='d-flex items-center'>
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[6px]">
                                                                <g clip-path="url(#clip0_3229_136159)">
                                                                    <path d="M5.23828 6.83711L4.25078 5.83711C4.14411 5.73711 4.01282 5.68711 3.85689 5.68711C3.70097 5.68711 3.5656 5.73919 3.45078 5.84336C3.34245 5.95586 3.28828 6.09128 3.28828 6.24961C3.28828 6.40794 3.34661 6.53294 3.46328 6.62461L4.83828 8.01211C4.94056 8.12878 5.07237 8.18711 5.23373 8.18711C5.3951 8.18711 5.53411 8.12878 5.65078 8.01211L8.48828 5.17461C8.60495 5.06794 8.66328 4.93873 8.66328 4.78697C8.66328 4.63521 8.60562 4.49776 8.49029 4.37461C8.37496 4.27461 8.237 4.22669 8.07641 4.23086C7.91581 4.23503 7.78226 4.28711 7.67578 4.38711L5.23828 6.83711ZM6.00251 11.2621C5.27936 11.2621 4.59559 11.1263 3.95119 10.8546C3.30679 10.5829 2.74606 10.2085 2.26898 9.73141C1.79191 9.25433 1.41752 8.69417 1.14583 8.05092C0.874131 7.40768 0.738281 6.72448 0.738281 6.00133C0.738281 5.27605 0.874373 4.59236 1.14656 3.95025C1.41875 3.30814 1.79382 2.74775 2.27177 2.26907C2.74973 1.79039 3.30954 1.41331 3.95119 1.13782C4.59286 0.862347 5.27514 0.724609 5.99802 0.724609C6.72391 0.724609 7.40856 0.861955 8.05197 1.13665C8.69537 1.41134 9.25604 1.78741 9.73397 2.26486C10.2119 2.74231 10.5883 3.30252 10.8633 3.94548C11.1383 4.58846 11.2758 5.27351 11.2758 6.00065C11.2758 6.72726 11.138 7.4106 10.8626 8.05068C10.5871 8.69076 10.21 9.24992 9.73132 9.72817C9.25264 10.2064 8.69283 10.5816 8.05187 10.8538C7.41091 11.126 6.72779 11.2621 6.00251 11.2621Z" fill="#FF8114"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_3229_136159">
                                                                        <rect width="12" height="12" fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Newsletter is set up
                                                        </span> : 'No Settings'
                                                    }
                                                </span>
                                                <svg width="3" height="4" viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-[12px]">
                                                    <circle cx="1.5" cy="2" r="1.5" fill="#4F4F4F"/>
                                                </svg>
                                                <span className={`${item?.files?.length ? 'text-[#FF8114]' : 'text-[#A7A7A7] opacity-40'}`}>{item?.files?.length || 0} files</span>
                                            </div>
                                        </div>
                                        <div className="d-flex items-center">
                                            {   haveSettings.includes(item.text) ?
                                                <>
                                                    {/* <button
                                                        className="btn btn-outline-black btn-lg-2 rounded-full h-[32px] px-[11px] text-[13px]"
                                                    >
                                                        Preview
                                                    </button> */}
                                                    <button
                                                        className="btn btn-outline-black btn-lg-2 rounded-full h-[32px] w-[32px] p-[6px] ml-[6px]"
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            setSelectedForSetting(item);
                                                        }}
                                                    >
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.1659 9.37947L3.06924 8.14281C2.83125 7.87443 2.7917 7.48388 2.97105 7.17324L4.03705 5.32677C4.2164 5.01614 4.57439 4.85512 4.9258 4.92702L6.5452 5.25838L7.61994 4.63786L8.14263 3.06981C8.25606 2.72952 8.57451 2.5 8.9332 2.5H11.0653C11.424 2.5 11.7424 2.72952 11.8558 3.06981L12.3785 4.63786L13.4533 5.25838L15.0727 4.92702C15.4241 4.85512 15.7821 5.01614 15.9614 5.32677L17.0275 7.17321C17.2068 7.48385 17.1672 7.87441 16.9292 8.14277L15.8326 9.37947V10.6205L16.9292 11.8572C17.1672 12.1256 17.2068 12.5161 17.0274 12.8268L15.9614 14.6732C15.7821 14.9838 15.4241 15.1448 15.0727 15.0729L13.4532 14.7416L12.3785 15.362L11.8558 16.9301C11.7424 17.2704 11.424 17.5 11.0653 17.5H8.9332C8.57451 17.5 8.25606 17.2705 8.14264 16.9302L7.61994 15.362L6.54525 14.7416L4.92579 15.073C4.57438 15.1449 4.21639 14.9839 4.03705 14.6732L2.97102 12.8268C2.79168 12.5161 2.83124 12.1256 3.06924 11.8572L4.1659 10.6205V9.37947Z" stroke="#767676" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <circle cx="10" cy="10" r="2.5" stroke="#767676" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </button>
                                                </> :
                                                <button
                                                    className="h-[32px] flex items-center justify-center gap-[8px] px-[12px] radial-bg rounded-full text-[#331A04] text-[13px] font-medium hover:opacity-70"
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        setSelectedForSetting(item);
                                                    }}
                                                >
                                                    Setup
                                                </button>
                                            }
                                            
                                            <button
                                                className="d-flex items-center justify-center ml-[6px] hover:opacity-70"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setGenerateForm((prevState) => {
                                                        const forSet = prevState.courses.filter(itemF => itemF.text !== item.text);

                                                        if(!forSet?.length){
                                                            setTimeout(() => {
                                                                setStep(1);
                                                            }, 1000);
                                                        }

                                                        return {
                                                            ...prevState,
                                                            courses: forSet
                                                        }
                                                    });

                                                }}
                                            >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.5">
                                                        <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </li>
                                })
                            }
                            
                        </ul>
                    </div>
                </div> :
                <Settings
                    selectedForSetting={selectedForSetting}
                    setSelectedForSetting={setSelectedForSetting}
                    back={()=>{
                        setSelectedForSetting(null);
                        setSetupAll(false);
                    }}
                    setupAll={setupAll}
                />
            }
        </div>
        <div className="gen4-footer">
            { currentCredits && priceCredits ? 
                <div className='credit-price-block'>
                    <div className="flex items-center gap-[10px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207"></path></svg>
                        <p className="text-[14px] text-[#EA8207] leading-normal">{ currentCredits }<span className="opacity-50 ml-[4px]">available credits</span></p>
                    </div>
                    <p className="text-[14px] text-[#EA8207] leading-normal ml-[30px]">{ priceCredits }<span className="opacity-50 ml-[4px]">price in credits</span></p>
                </div>
            : null}
            <div className="d-flex items-center gen4-footer-btns-wrpr not-transform">
                {
                    !selectedForSetting ? <>
                        <button
                            className={`gen4-btn min-w-[162px] ${!stepTwoDisabled ? 'hover:opacity-70' : ''}`}
                            disabled={stepTwoDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                submit();
                            }}
                        >
                            <span>Submit</span>
                        </button>
                        <button className={`gen4-btn outline-btn min-w-[162px] ${randomGenerateLoading ? 'block-disabled' : ''}`} onClick={() => { generateRandom() }}>
                            <RandomGenerateIcon />
                            <span>{ randomGenerateLoading ? 'Loading' : 'Random' }</span>
                        </button>
                    </> : <>
                        <button
                            className="btn btn-outline-black btn-lg-2 rounded-full h-[44px] min-w-[162px] text-[14px]"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedForSetting(null);
                                setSetupAll(false);
                            }}
                        >
                            <span>Cancel</span>
                        </button>
                        <button
                            className={`gen4-btn min-w-[162px] hover:opacity-70`}
                            onClick={(e) => {
                                e.preventDefault();
                                courseSave();
                            }}
                        >
                            <span>Save</span>
                        </button>
                    </>
                }
            </div>
        </div>
    </>;
};

export default Step2;