import React, { Fragment, useEffect, useState, } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
 
import api from '../../../api';
import { 
    getActiveBlogIndex, getGeneratedBlogs, getGenerateLoading,
    setBlogs, setBlogDone, setBlogVersions, setGenerateLoading,
    setActiveBlogIndex, setBlogVersionAddon, setBlogAddonVersion,
    setBlogAdd_Ons,
} from '../../../redux/slices/blogGeneratorSlice';
import { getSocket, getToken, getUser, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';

import ChatBot from '../ChatBot';
import GeneratedContentModal from '../GeneratedContentModal';
import LandingPageCard from './LandingPageCard';
import AddonCard from './AddonCard';
import HelpModal from '../../../components/base/HelpModal';
import SidebarPanel from "./SidebarPanel";
import BottomPanel from "./BottomPanel";
import Modals from "./Modals";
import LandingPageCardContent from './LandingPageCardContent';
import useScreenWidth from '../../../helpers/useScreenWidth';


const LandingContentGeneratedContent = ({ type }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const token = useSelector(getToken);
    const socket = useSelector(getSocket);
    const meData = useSelector(getUser);

    const screenWidth = useScreenWidth();
    const mobileScreen = screenWidth < 1199;

    const generateLoading = useSelector(getGenerateLoading);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    // console.log('blogs:', blogs);

    const [openedModal, setOpenedModal] = useState();
    const [configForm, setConfigForm] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [toastId, setToastId] = useState(null);
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [generateOnlyAddons, setGenerateOnlyAddons] = useState(false);
    
    const activeBlog = blogs[activeBlogIndex];

    const isNotDone = generateLoading ? blogs.filter(item => !item?.done).length : false;
    const wordsCount = blogs.reduce((accumulator, blog) => {
        const blogWordCount = blog.versions.reduce((accumulator, version) => {
            const displayText = version?.activeContent || version?.content || '';
            return accumulator + displayText.split(' ').filter(word => word.length > 0).length;
        }, 0);
        return accumulator + blogWordCount
    }, 0);

    const closeModal = () => setShowHelpModal(false);

    const duplicate = async() => {
        if(!navigate || !id || !token) return;
        
        const response = await api.assets.cloneAsset({
            id,
            token
        });

        if(response?.success){
            setOpenedModal({
                name: 'duplicate',
                title: `${type === 'landing' ? 'Landing page' : 'Entire website'} “${activeBlog.versions[0].title}” successfully duplicated as: ”${response.data?.name}”`
            });

            return;
        }

        setOpenedModal(null);
    }

    const deleteAction = async() => {
        const response = await api.assets.deleteAsset({
            id,
            token
        });

        if(response.success){
            setOpenedModal({
                name: 'save',
                title: response.message,
                text: ''
            });

            setTimeout(() => {
                navigate('/');
            }, 1500);

            return;
        }

        setOpenedModal(null);
    }

    const save = async() => {
        let res = await api.assets.changeAsset({
            id,
            data: {
                content: JSON.stringify(blogs)
            },
            token
        });

        if(res?.success){
            setOpenedModal({
                name: 'save',
                title: 'Saved',
                text: `Your ${type === 'landing' ? 'landing page' : 'entire website'} has been successfully saved`
            })
        } else {
            console.error(res);
        }
    }

    const handleExport = async (id, ext) => {
        const toastId = toast.loading('File is generated')
        const res = await api.assets.exportAsset({
            token,
            id,
            type: 'blog',
            ext,
            filename: `${activeBlog.versions[0].title}.${ext}`,
        });
        console.log('handleExport ', res)
        toast.update(toastId, {
            render: res?.statusText === 'OK' ? 'File is Export' : 'Faild',
            type: res?.statusText === 'OK' ? 'success': 'error', 
            isLoading: false,
            autoClose: 2000
        });
        setOpenedModal(null);
    }

    const handleGenerate = async () => {
        try {
            let checkRes = await checkUserHaveCredits(token);
            if(!checkRes?.success){
                dispatch(setUser(checkRes?.data));
                return;
            }
            
            setIsLoading(true);

            const formatedAddons = configForm?.add_ons.map(addon => ({ title: addon.title, description: addon.description, key: addon.title.replace(/ /g, '_').toLowerCase() }));
            
            if(generateOnlyAddons){
                dispatch(setGenerateLoading(true));

                console.log('--===generateOnlyAddons===--');

                let addonsNotDone = [];
                let addonsIsDone = [];

                dispatch(setBlogAdd_Ons(configForm?.add_ons));

                for (let addonBlogIndex = 0; addonBlogIndex < blogs.length; addonBlogIndex++) {
                    for (let addonBlogVersionIndex = 0; addonBlogVersionIndex < blogs[addonBlogIndex].versions.length; addonBlogVersionIndex++) {
                        addonsNotDone.push(false);

                        setTimeout(() => {
                            socket.send({
                                event: 'generation-addons',
                                payload: {
                                    data:[{
                                        name: `${type === 'landing' ? 'Landing page' : 'Entire website'} content`,
                                        content: blogs[addonBlogIndex].versions[addonBlogVersionIndex].activeContent,
                                    }],
                                    addons: formatedAddons
                                },
                                handler: (response) => {
                                    if(response.done){
                                        addonsIsDone.push(true);
                                    }

                                    if(Object.keys(response.data)?.length){
                                        const addonKeys = Object.keys(response.data);
                                        for (let addonKeyIndex = 0; addonKeyIndex < addonKeys.length; addonKeyIndex++) {

                                            dispatch(setBlogAddonVersion({
                                                blogIndex: addonBlogIndex,
                                                versionIndex: addonBlogVersionIndex,
                                                key: addonKeys[addonKeyIndex],
                                                value: response.data[addonKeys[addonKeyIndex]],
                                                onlyAddonGenerate: true,
                                                kit: "landing-entire"
                                            }))
                                        }
                                    }

                                    if(addonsNotDone?.length === addonsIsDone?.length){
                                        setIsLoading(false);
                                        dispatch(setGenerateLoading(false));

                                        toast.success('Generated successfully!');
                                    }
                                }
                            });
                        }, addonBlogVersionIndex*100);
                    }
                }

                return;
            }

            const response = await api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify([...blogs, {
                        add_ons: formatedAddons || [],
                        settings: {
                            ...configForm.settings,
                            addons: formatedAddons
                        },
                        versions: [],
                        activeVersionIndex: 0,
                        generatedDate: new Date().toUTCString(),
                        selectedVersionId: null,
                        addonsValues: []
                    }])
                },
                token
            });
            if(!response.success) return;
            const dataRes = JSON.parse(response.data.content);
            const lastDataRes = dataRes[dataRes.length - 1];
            dispatch(setBlogs(dataRes));
            dispatch(setActiveBlogIndex(dataRes.length - 1));
            dispatch(setGenerateLoading(true));

            socket.send({
                event: configForm?.landing_or_entire_website === 'Landing Page' ? 'landing-page-create' : 'landing-page-create-entire',
                payload: {
                    addons: formatedAddons || [],
                    language: configForm.settings?.language || 'English',
                    tone: configForm.settings.tone || 'Professional',
                    ...(configForm?.landing_or_entire_website === 'Landing Page' ? {
                        suggestion: configForm?.pages[0].suggestions,
                        description: configForm?.pages[0].description,
                    } : {
                        pages: configForm?.pages.map(item => ({
                            name: item.name,
                            suggestion: item.suggestions,
                            description: item.description
                        })),
                    })
                },
                handler: async(data) => {
                    if(data?.isError){
                        setIsLoading(false);
                        dispatch(setGenerateLoading(false));
                        setToastId(null);

                        toast.error("AI bot is not available at the moment");
                        return;
                    }

                    dispatch(setBlogVersions(configForm?.landing_or_entire_website === 'Landing Page' ? 
                        [{
                            "title": data.payload.title,
                            "content": data.payload.content,
                            "isDone": data.isDone,
                            "id": `${id}-page-${0}`,
                            "language": configForm.settings.language,
                            "tone": configForm.settings.tone,
                            "activeContent": data.payload.content,
                            "activeButton": ""
                        }] : data.payload.map((itemP, itemPIndex) => ({
                            "title": itemP.title,
                            "content": itemP.content,
                            "isDone": itemP.isDone,
                            "id": `${id}-page-${itemPIndex}`,
                            "language": configForm.settings.language,
                            "tone": configForm.settings.tone,
                            "activeContent": itemP.content,
                            "activeButton": ""
                        }))
                    ));

                    if(data.isDone) {
                        if(configForm?.add_ons?.length) {
                            const pagesGenerated = configForm?.landing_or_entire_website === 'Landing Page' ? [{
                                "title": data.payload.title,
                                "content": data.payload.content
                            }] : data.payload.map((itemP) => ({
                                "title": itemP.title,
                                "content": itemP.content,
                            }));

                            let checkRes2 = await checkUserHaveCredits(token);
                            if(!checkRes2?.success){
                                dispatch(setUser(checkRes2?.data));
                                return;
                            }

                            for (let i = 0; i < pagesGenerated.length; i++) {
                                socket.send({
                                    event: 'landing-page-create-addons',
                                    payload: {
                                        content: pagesGenerated[i].content,
                                        title: pagesGenerated[i].title,
                                        addons: formatedAddons,
                                    },
                                    handler: (addonsResponse) => {
                                        if(addonsResponse?.isError){
                                            setIsLoading(false);
                                            dispatch(setGenerateLoading(false));
                                            setToastId(null);
                    
                                            toast.error("AI bot is not available at the moment");

                                            return;
                                        }

                                        dispatch(setBlogVersionAddon({
                                            index: i,
                                            data: {
                                                ...addonsResponse.payload,
                                                isDone: addonsResponse.isDone
                                            }
                                        }));

                                        if(addonsResponse?.isDone){
                                            dispatch(getUserUpdateThunk({ token }));
                                        }
                                    }
                                })
                            }
                        }

                        dispatch(getUserUpdateThunk({ token }));
                    }
                }
            });

        } catch(e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(!generateLoading) return;
        // if(!toastId) {
        //     setToastId(toast.loading(`Generating your ${type === 'landing' ? 'landing page' : 'entire website'}, please wait...`));
        // }

        if(activeBlog?.versions?.length && activeBlog?.versions.every(version => version.isDone)) {
            if(activeBlog?.add_ons?.length && (activeBlog.versions?.length !== activeBlog.addonsValues?.length || activeBlog?.addonsValues.some(addon => !addon.isDone))) return;

            // api.assets.changeAsset({
            //     id,
            //     data: {
            //         name: blogs?.length && blogs[0]?.versions?.length ? ( blogs[0]?.versions?.length === 1 ? blogs[0].versions[0].title : blogs[0].versions.map(item => item.title).join(', ') ) : 'Landing Page',
            //         content: JSON.stringify(blogs)
            //     },
            //     token
            // }).then(resp => {console.log(resp)});

            // toast.update(toastId, {
            //     render: 'Generated successfully!',
            //     type: 'success', 
            //     isLoading: false,
            //     autoClose: 5000
            // });

            dispatch(setGenerateLoading(false));
            // setToastId(null);

            dispatch(setBlogDone(true));
        }
    }, [activeBlog, generateLoading, blogs, id, token, dispatch, toastId])

    useEffect(() => {
        if(!navigate || !id || !dispatch || !token) return;
        const fetchBlogData = async () => {
            try {
                setIsLoading(true);
                const response = await api.assets.getAssetById({
                    id,
                    token
                });
                setIsLoading(false);
                if(!response.success) return;
                dispatch(setBlogs(JSON.parse(response.data.content)));
            } catch (e) {
                setIsLoading(false);
                if(e.response.status === 404) {
                    navigate('/')
                }
            }
        }
        fetchBlogData();
    }, [id, navigate, dispatch, token])

    useEffect(() => {
        if(!blogs.length) return;
        
        const addonsToConfig = blogs[activeBlogIndex]?.settings?.addons ? 
            blogs[activeBlogIndex]?.settings?.addons :
            blogs[activeBlogIndex]?.add_ons ? blogs[activeBlogIndex]?.add_ons : [];

        const payloadToConfigForm = {
            ...blogs[activeBlogIndex],
            add_ons: addonsToConfig,
            landing_or_entire_website: type === 'landing' ? 'Landing Page' : 'Entire Website',
            pages: (blogs[activeBlogIndex]?.settings?.pages || blogs[activeBlogIndex]?.versions)?.map((versionItem, versionItemIndex) => ({
                name: versionItem?.name || versionItem.title,
                description: versionItem?.description || blogs[activeBlogIndex].settings.description || "",
                suggestions: versionItem?.suggestion || blogs[activeBlogIndex].settings.suggestion || []
            }))
        };

        if(
            (JSON.stringify(payloadToConfigForm?.settings) !== JSON.stringify(configForm?.settings)) &&
            (JSON.stringify(payloadToConfigForm?.add_ons) !== JSON.stringify(configForm?.add_ons))
        ){
            setConfigForm(payloadToConfigForm);
        }
    }, [blogs, activeBlogIndex])
    
    return <main className='w-screen h-screen overflow-hidden flex'>
        <ChatBot className='bottom-[74px] right-[16px]'/>

        <SidebarPanel
            type={type}
            cardFullScreen={cardFullScreen}
            configForm={configForm}
            setConfigForm={setConfigForm}
            setIsLoading={setIsLoading}
            handleGenerate={handleGenerate}
            isNotDone={isNotDone}
            mobileScreen={mobileScreen}
            generateOnlyAddons={generateOnlyAddons}
            setGenerateOnlyAddons={setGenerateOnlyAddons}
        />

        {isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> :
        <div className='flex-1 flex flex-col'>
            <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
                'px-[30px]': cardFullScreen
            })}>
                <div>
                    <h1 className='text-[14px] font-medium leading-normal'>
                        Generated Content
                    </h1>
                    <div className='text-[12px] leading-[14px] flex items-center gap-[5px] flex-wrap'>
                        <Link
                            to={`/generated-content?type=${type === 'landing' ? 'landing-page' : 'entire-website'}`}
                            className='opacity-40 hover:text-[#FF8114] hover:underline hover:opacity-100'
                        >
                            { type === 'landing' ? 'Landing Page Kit' : 'Entire Website Kit' }
                        </Link>
                        <div className='w-[3px] h-[3px] rounded-full bg-white opacity-40'/>
                        <span className="opacity-40">
                            { type === 'landing' ? 'Landing Page' : 'Entire Website' }
                        </span>
                    </div>
                </div>
                <button 
                    onClick={() => setShowHelpModal(true)}
                    className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='py-[4px] px-[16px] pb-[12px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
                <ul className={classNames('flex flex-col gap-[12px]', {
                    'h-full': cardFullScreen
                })}>
                    {blogs.map((data, index) => {
                        return <Fragment key={index}>
                            <LandingPageCard
                                isNotDone={isNotDone}
                                data={data.versions} 
                                index={index}
                                setCardFullScreen={setCardFullScreen}
                                isFullScreen={cardFullScreen}
                                cardContent={LandingPageCardContent}
                            />
                            { Boolean(data?.addonsValues?.length) && data?.add_ons?.map((addonItem, addonIndex) => 
                            <AddonCard
                                key={addonItem?.title}
                                description={addonItem?.description || null}
                                title={addonItem?.title}
                                data={data}
                                index={index}
                                addonIndex={addonIndex}
                                forceActiveVersionIndex={data?.activeVersionIndex}
                                isFullScreen={cardFullScreen}
                                selectFromVersion={true}
                            />)}
                        </Fragment>
                    })}
                </ul>
            </div>

            <BottomPanel
                blogs={blogs}
                wordsCount={wordsCount}
                isNotDone={isNotDone}
                meData={meData}
                setOpenedModal={setOpenedModal}
                deleteAction={deleteAction}
                duplicate={duplicate}
                save={save}
            />
        </div>}
        
        <Modals
            openedModal={ openedModal }
            setOpenedModal={ setOpenedModal }
            closeModal={ closeModal }
            showHelpModal={ showHelpModal }
            GeneratedContentModal={ GeneratedContentModal }
            handleExport={ handleExport }
            id={ id }
            HelpModal={ HelpModal }
        />
    </main>;
};

export default LandingContentGeneratedContent;