import React, { useEffect, useRef, useState } from 'react';
import EditorToolbar from '../Blog/EditorToolbar';
import { useQuill } from 'react-quilljs';
import classNames from 'classnames';
import DefaultToolbar from '../Blog/DefaultToolbar';
import EditorControl from '../Blog/EditorControl';
import { useDispatch } from 'react-redux';
import BlogVariantKeywords from '../Blog/BlogVariantKeywords';
import { useSelector } from 'react-redux';
import {
    getActiveBlogIndex, getBlogGeneratingContent, getGeneratedBlogs,
    setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogGeneratingContent, setGenerateLoading
} from '../../../redux/slices/blogGeneratorSlice';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { getSocket, getToken, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';
import ImageUploader from "quill-image-uploader";
import Quill from "quill";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
// Quill.register("modules/imageUploader", ImageUploader);
import { toast } from 'react-toastify';
import AddEditorChartModal from '../../../components/parts/AddEditorChartModal';

const MetaTagsCardContent = ({index, isFullScreen, setCardFullScreen, setUpdateBlogs, markup, isNotDone = false}) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const blogs = useSelector(getGeneratedBlogs);
    const socket = useSelector(getSocket);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const generatingContent = useSelector(getBlogGeneratingContent);
    const { activeVersionIndex, versions, selectedVersionId } = blogs[activeBlogIndex] || {};
    const isSelected = selectedVersionId === versions[activeVersionIndex]?.id;
    const { id } = useParams();
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
          toolbar: '#toolbar' + index,
          history: {
            delay: 2000,
            userOnly: true
          },
          imageUploader: {
            upload: (file) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const { data: { attachments }, success } = await api.assets.changeAsset({
                            id,
                            data: {
                                attachments: file
                            },
                            token
                        })
                        if(success) {
                            resolve(process.env.REACT_APP_API_URL + attachments[attachments.length - 1].path);
                        }
                        reject("Upload failed");
                    } catch (e) {
                        reject("Upload failed");
                    }
                });
              },
          }
        },
        formats: [
            'bold', 'italic', 'underline', 'align', 'link', 'image', 'video',
            'color', 'background', 'header', 'font', 'size',
            'clean', 'imageBlot'
        ],
    });

    const [isEdit, setIsEdit] = useState(false);

    const [editorActiveButton, setEditorActiveButton] = useState(null);
    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);
    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [customPrompt, setCustomPrompt] = useState('');
    const [editBlogLoading, setEditBlogLoading] = useState(false);

    const [initioaChartData, setInitioaChartData] = useState(null)
    useEffect(() => {
        if (isEdit) {
            const handelClick = ({ target }) => {
                if (target.classList.contains('chart-img')) {
                    target.classList.add('selected-chart')
                    setEditorActiveButton('chart')
                    setInitioaChartData(JSON.parse(target.getAttribute('data-chart')))
                }
            }
            document.addEventListener('dblclick', handelClick)
            return () => {
                document.removeEventListener('dblclick', handelClick)
            }
        }
    }, [isEdit])

    const editBlog = async({operation, isActive, customPayload = {}}) => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));
            return;
        }

        dispatch(setGenerateLoading(false));
        setEditBlogLoading(true);

        socket.send({
            event: 'meta-tag-edit',
            payload: {
                content: versions[activeVersionIndex]?.content,
                operation,
                ...customPayload
            },
            handler: (response) => {
                if(response?.isError){
                    dispatch(setGenerateLoading(false));
                    setEditBlogLoading(false);

                    toast.error("AI bot is not available at the moment");
                    return;
                }

                if(quillRef?.current?.firstChild){
                    const { firstChild } = quillRef.current;
                    firstChild.scroll({ top: firstChild.scrollHeight, behavior: 'smooth' });
                }

                const setEditContent = () => {
                    isActive ? dispatch(setBlogActiveContent(response.payload)) : dispatch(setBlogContent(response.payload));
                } 

                if(response.isDone) {
                    dispatch(setBlogGeneratingContent(null));
                    setEditContent();
                    // setUpdateBlogs(true);
                    setEditBlogLoading(false);
                    dispatch(getUserUpdateThunk({ token }));
                    return;
                } else {
                    setEditContent();
                }

                dispatch(setBlogGeneratingContent(response.payload));
            }
        })
    }
    const wordsCount = markup?.split(' ').filter(word => word.length > 0).length;
    const imageButtonRef = useRef();

    useEffect(() => {
        if (!quill || !markup) return;
        quill.disable();

        // quill.clipboard.dangerouslyPasteHTML(generatingContent || markup, 'api');
        const contentToEditor = generatingContent || markup;
        const delta = quill.clipboard.convert(contentToEditor);
        quill.setContents(delta);
    }, [quill, markup, generatingContent]);

    useEffect(() => {
        if (!quill) return;
        if(isEdit) {
            quill.enable();
        } else {
            quill.disable();
        }
    }, [quill, isEdit]);


    return <>
        <div 
            id={'toolbar'+ index}
            className={classNames("editor-toolbar-column-wrpr flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px] lg:min-h-[40px] ql-toolbar ql-snow", isNotDone ? 'block-disabled' : '')}
            style={{
                display: isEdit ? 'flex' : 'none'
            }}
        >
            <EditorToolbar
                isEdit={isEdit}
                quill={quill}
                activeButton={editorActiveButton}
                setActiveButton={setEditorActiveButton}
                setImagePopupOpened={setImagePopupOpened}
                setImageLinkOpened={setImageLinkOpened}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                imageButtonRef={imageButtonRef}
            />
        </div>
        <div
            className={classNames("default-toolbar-wrpr flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px]",
            isEdit ? 'hidden' : 'flex')}
        >
            <DefaultToolbar
                disabled={isNotDone || editBlogLoading}
                footerActions={footerActions}
                setBlogIsEdit={setIsEdit}
                setFooterActions={setFooterActions}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                hiddenButtons={["Emojis", "List", "Copy"]}
            />
        </div>

        <pre
            ref={quillRef}
            // style={{display: isEdit ? 'block': 'none'}}
        />

        <div
            // className={classNames('blog-generator-card-footer', isOpen || isFullScreen ? 'block' : 'hidden')}
            className='blog-generator-card-footer'
        >
            {isEdit ? <EditorControl
                activeButton={editorActiveButton}
                closeEditor={() => setIsEdit(false)}
                imageLinkOpened={imageLinkOpened}
                quill={quill}
                setActiveButton={setEditorActiveButton}
                setImageLinkOpened={setImageLinkOpened}
                // setUpdateBlogs={setUpdateBlogs}
                // onlyInnerTextEmit={true}
            /> : footerActions.keyword ? <BlogVariantKeywords
                data={versions[activeVersionIndex]}
                setUpdateBlogs={setUpdateBlogs}
                editBlog={editBlog}
                setFooterActions={setFooterActions}
            /> : footerActions.customPrompt ? <div>
                <div className='flex gap-[10px] mob-flex-wrap'>
                    <input
                        className='bg-inherit h-[32px] text-[13px] flex-1 pl-[12px] pr-[8px]
                        border border-solid border-[#333] rounded-[8px]'
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                    />
                    <button 
                        className='goal-generator-action btn--primary-gradient btn'
                        style={{
                            height: 32,
                            padding: '0 12px',
                            fontSize: 13
                        }}
                        onClick={() => {
                            dispatch(setBlogActiveContent(null));
                            dispatch(setBlogActiveButton(null));
                            editBlog({
                                operation: 'custom',
                                isActive: false,
                                customPayload: {
                                    data: customPrompt
                                }
                            });
                            setFooterActions({
                                keyword: false,
                                customPrompt: false
                            });
                        }}
                        disabled={!customPrompt}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Apply
                    </button>
                </div>
            </div> : versions?.length > 1 ? <div className='flex justify-between items-center mob-flex-wrap gap-[8px] lg:gap-[0px]'>
                <ul className='flex gap-[8px] mob-flex-wrap'>
                    {versions.map((value, index) => <li
                        key={index}
                        className={classNames(`rounded-[8px] border border-solid border-[#333333] bg-[#1B1B1B]
                        p-[10px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7] cursor-pointer hover:text-white`, {
                            'border-[#FF8114] text-white': index === activeVersionIndex
                        })}
                        onClick={() => dispatch(setBlogActiveVersionIndex(index))}
                    >
                        <span className="capitalize">{ value?.pageName || value?.title }</span>
                    </li>)}
                </ul> 
                <div className='flex gap-[16px] items-center'>
                    <span className='text-[13px] opacity-30'>
                        {wordsCount} words
                    </span>
                </div>
            </div> : null }
        </div>

        {imagePopupOpened && <div className='generated-blog-popup'>
            <button
                onClick={() => {
                    imageButtonRef.current.click();
                    setImagePopupOpened(false);
                    setEditorActiveButton(null);
                }}
                className='py-[10px] lg:py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="1.5" y="1.5" width="21" height="21" rx="5" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.3928 16.6596L19.2412 13.508C18.7856 13.0524 18.0469 13.0524 17.5913 13.508L14.9163 16.1831L9.90791 11.1747C9.4523 10.7191 8.71361 10.7191 8.258 11.1747L1.86426 17.5684" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.7094 7.71081L14.7099 7.7103C14.8237 7.59419 15.01 7.59222 15.1262 7.70589C15.2423 7.81957 15.2444 8.00588 15.1308 8.12213C15.0172 8.23838 14.8309 8.24057 14.7146 8.12704C14.5983 8.01351 14.596 7.82719 14.7094 7.71081" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='mt-[10px] lg:mt-[20px] text-[13px] font-medium opacity-90'>Upload Image</p>
                <p className='mt-[5px] lg:mt-[12px] text-[12px] font-light opacity-50'>Add image from your computer</p>
            </button>
            <button
                onClick={() => {
                    setImageLinkOpened(true);
                    setImagePopupOpened(false);
                }}
                className='py-[10px] lg:py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020]'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M19.2239 16.2887L21.7811 13.7315C23.8119 11.6495 23.7911 8.32164 21.7346 6.26509C19.6781 4.20855 16.3502 4.18781 14.2682 6.21856L11.7109 8.77579" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.1123 10.8867L10.8867 17.1123" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.77579 11.7109L6.21856 14.2682C4.18781 16.3502 4.20855 19.6781 6.26509 21.7346C8.32164 23.7911 11.6495 23.8119 13.7315 21.7811L16.2887 19.2239" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='mt-[10px] lg:mt-[20px] text-[13px] font-medium opacity-90'>Add Image URL</p>
                <p className='mt-[5px] lg:mt-[12px] text-[12px] font-light opacity-50'>Paste image link to add picture</p>
            </button>
        </div>}
        {editorActiveButton === 'chart' &&
            <AddEditorChartModal
                handelClose={() => {
                    setEditorActiveButton(null);
                    setInitioaChartData(null)
                }}
                isOpened={editorActiveButton === 'chart'}
                editor={quill}
                initialData={initioaChartData}
            />}
    </>
};

export default MetaTagsCardContent;