import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as BotIcon } from '../../assets/img/icons/icon-bot.svg';
import userImage from '../../assets/img/bot-user.png';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getSocket, getUser, getToken, setUser } from '../../redux/slices/applicationSlice';
import getUserImageSrc from '../../helpers/getUserImageSrc';
import { toast } from 'react-toastify';
import { checkUserHaveCredits } from '../../helpers/userHelpers';
import { getUserUpdateThunk } from '../../redux/thunks/applicationThunks';

const ChatBot = ({ className }) => {
    const socket = useSelector(getSocket);
    const token = useSelector(getToken);
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [tempMessage, setTempMessage] = useState(null);
    const chatRef = useRef(null);
    useEffect(() => {
        chatRef.current?.scrollIntoView({ behavior: "smooth"});
    }, [messages])
    

    const handleSendMessage = async() => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));
            return;
        }

        setTempMessage(inputValue);
        setInputValue('');
        socket.send({
            event: 'chat-bot-local',
            payload: {
                messages,
                settings: {},
                messageText: inputValue
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                setTempMessage(null);
                setMessages(response.messages);
                
                if(response?.isDone){
                    dispatch(getUserUpdateThunk({ token }));
                }
            }
        }); 
    }

    return <div className={classNames('chat-bot-btn-wrpr absolute z-[999]', className)}>
        {isOpened ? <div className='chat-bot'>
            <div className='flex items-center justify-between py-[12px]'>
                <div className='flex gap-[14px]'>
                    <div className='chat-bot-button rounded-full w-[36px] h-[36px]'>
                        <BotIcon className='w-[24px] h-[24px]'/>
                    </div>
                    <div>
                        <h3 className='text-[14px] font-medium'>Chat Bot</h3>
                        <p className='text-[12px] opacity-40'>Just ask our bot and it will help you</p>
                    </div>
                </div>
                <div className='flex gap-[8px]'>
                    <button className='chat-bot-header-button'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <g opacity="0.6">
                                <path d="M2.83125 16.5939C2.57187 16.5939 2.36328 16.515 2.20547 16.3572C2.04766 16.1994 1.96875 15.9908 1.96875 15.7314V11.2502C1.96875 11.0152 2.05224 10.8127 2.21921 10.6427C2.38619 10.4727 2.58494 10.3877 2.81546 10.3877C3.04599 10.3877 3.24687 10.4727 3.41812 10.6427C3.58937 10.8127 3.675 11.0152 3.675 11.2502V13.6689L13.125 4.21895H10.6875C10.4578 4.21895 10.2598 4.13546 10.0933 3.96848C9.92695 3.80151 9.84375 3.60276 9.84375 3.37223C9.84375 3.14171 9.92695 2.94082 10.0933 2.76957C10.2598 2.59832 10.4578 2.5127 10.6875 2.5127H15.1875C15.44 2.5127 15.6469 2.59332 15.8081 2.75457C15.9694 2.91582 16.05 3.1227 16.05 3.3752V7.8752C16.05 8.10488 15.9647 8.30293 15.7941 8.46934C15.6234 8.63574 15.4201 8.71895 15.1842 8.71895C14.9536 8.71895 14.7559 8.63574 14.591 8.46934C14.4262 8.30293 14.3437 8.10488 14.3437 7.8752V5.4377L4.89375 14.8877H7.3125C7.5475 14.8877 7.75 14.973 7.92 15.1436C8.09 15.3143 8.175 15.5176 8.175 15.7535C8.175 15.9841 8.09 16.1818 7.92 16.3467C7.75 16.5115 7.5475 16.5939 7.3125 16.5939H2.83125Z" fill="white"/>
                            </g>
                        </svg>
                    </button>
                    <button className='chat-bot-header-button' onClick={() => setIsOpened(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M6 6L12 12" stroke="#A4A4A4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 6L6 12" stroke="#A4A4A4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className='chat-bot-chat no-scroll'>
                {messages.filter(({role}) => role !== 'system').map(message => 
                    message.role === 'user' ? <div className='chat-bot-card'>
                        <img src={getUserImageSrc(user)} alt='user' className='chat-bot-user-img'/>
                        <p className='chat-bot-text whitespace-pre-wrap'>{message.contents[0].content}</p>
                    </div> : <div className='chat-bot-card bg-[#151515]'>
                        <div className='chat-bot-button chat-bot-user-img'>
                            <BotIcon className='w-[20px] h-[20px]'/>
                        </div>
                        <p className='chat-bot-text whitespace-pre-wrap'>
                            {message.contents[0].content}
                        </p>
                    </div>
                )}
                <div ref={chatRef}>
                    {tempMessage &&
                    <div className='chat-bot-card'>
                        <img src={getUserImageSrc(user)} alt='user' className='chat-bot-user-img'/>
                        <p className='chat-bot-text whitespace-pre-wrap'>{tempMessage}</p>
                    </div>}
                </div>
            </div>
            <div className='chat-bot-input-container'>
                <input
                    className='chat-bot-input'
                    placeholder='Send a message...'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter' && inputValue) handleSendMessage();
                    }}
                />
                <button
                    disabled={!inputValue}
                    className='disabled:opacity-30 ml-[10px]'
                    onClick={handleSendMessage}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4814 18.7097L21.4684 12.8777C22.1774 12.5017 22.1774 11.4897 21.4684 11.1127L10.4974 5.28969C9.66943 4.84969 8.74143 5.67969 9.09243 6.54569L11.2814 11.9457L9.07443 17.4557C8.72843 18.3227 9.65543 19.1477 10.4814 18.7097Z" stroke="white" stroke-width="1.5036" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.2812 11.9496L22.0013 11.9996" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4 15H5.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 12H5.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 9H5.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div> : <button 
            className='chat-bot-button rounded-full w-[32px] lg:w-[48px] h-[32px] lg:h-[48px] p-[4px] lg:p-[0]' 
            onClick={() => setIsOpened(true)}
        >
            <BotIcon />
        </button>}
    </div>;
};

export default ChatBot;