import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Quill from "quill";
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/icon-check-circle.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/arrow-bottom.svg';
import { ReactComponent as TextIcon } from '../../../assets/img/icons/text.svg';

import ColorPicker from "./ColorPicker";

const fontOptions = {
    roboto: 'Roboto',
    openSans: 'Open Sans',
    notoSans: 'Noto Sans',
    montserrat: 'Montserrat',
    lato: 'Lato',
    timesNewRoman: "Times New Roman",
    helvetica: "Helvetica",
    arial: "Arial",
    comicSansMS: "Comic Sans MS",
    courierNew: "Courier New",
    impact: "Impact",
    tahoma: "Tahoma",
    verdana: "Verdana",
}

const fontSizeOptions = ['12', '13', '14', '15', '16', '20', '24', '32'];

const Icons = Quill.import('ui/icons');  
Icons['bold'] = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9.75 9C10.4462 9 11.1139 8.72344 11.6062 8.23116C12.0984 7.73887 12.375 7.07119 12.375 6.375C12.375 5.67881 12.0984 5.01113 11.6062 4.51884C11.1139 4.02656 10.4462 3.75 9.75 3.75H5.25V9M9.75 9H5.25M9.75 9H10.5C11.1962 9 11.8639 9.27656 12.3562 9.76884C12.8484 10.2611 13.125 10.9288 13.125 11.625C13.125 12.3212 12.8484 12.9889 12.3562 13.4812C11.8639 13.9734 11.1962 14.25 10.5 14.25H5.25V9" stroke="#A7A7A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`;
Icons.italic = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M8.25 3.75H12.75M5.25 14.25H9.75M10.5 3.75L7.5 14.25" stroke="#A7A7A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`;
Icons.underline = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M5.25 3.75V7.5C5.25 8.49456 5.64509 9.44839 6.34835 10.1517C7.05161 10.8549 8.00544 11.25 9 11.25C9.99456 11.25 10.9484 10.8549 11.6517 10.1517C12.3549 9.44839 12.75 8.49456 12.75 7.5V3.75M3.75 14.25H14.25" stroke="#A7A7A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`;
Icons.align[""] = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M3 3.375H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 7.125H12" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 10.875H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 14.625H9" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`
Icons.align.center = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M3 3.375H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.5 7.125H13.5" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 10.875H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 14.625H12" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`
Icons.align.right = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M15 3.375H3" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 7.125H6" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 10.875H3" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 14.625H9" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`
Icons.align.justify = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M3 3.375H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 7.125H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 10.875H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 14.625H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`
Quill.register(Icons, true);

const size = Quill.import('formats/size');
size.whitelist = fontSizeOptions;
Quill.register(size, true);
setTimeout(() => {
    const font = Quill.import('formats/font')
    // We do not add Aref Ruqaa since it is the default
    font.whitelist = Object.keys(fontOptions);
    Quill.register(font, true)
})

const styleOptions = ['Common Text', 'H1', 'H2', 'H3', 'H4'];
const defaultSizes = {
    0: '14',
    1: '32',
    2: '24',
    3: '20',
    4: '16'
}

const colorOptions = [
    '#000000',
    '#141414',
    '#292929',
    '#3D3D3D',
    '#525252',
    '#858585',
    '#FFFFFF',
    '#3772FF',
    '#7209B7',
    '#DF2935',
    '#EB5E28',
    '#FDCA40',
    '#8AC926'
]

export const EditorToolbar = ({ isEdit, quill, activeButton, setActiveButton, setImagePopupOpened, setImageLinkOpened, setCardFullScreen, isFullScreen, imageButtonRef }) => {
    const [activeHeader, setActiveHeader] = useState(0);
    const [activeFont, setActiveFont] = useState();
    const [activeSize, setActiveSize] = useState();
    const [activeColor, setActiveColor] = useState();

    useEffect(() => {
        if(!quill) return;
        quill.on('editor-change', function(event, range, oldRange, source) {
            if(isEdit  && range?.index){
                const { header, font, size, color, background } = quill.getFormat(range.index, range.length);
                setActiveColor(color || '#FFFFFF');
                setActiveHeader(header || 0);
                setActiveFont(font || 'openSans');
                if(size) {
                    setActiveSize(size);
                } else {
                    setActiveSize(defaultSizes[header || 0])
                }
            }
        });
    }, [quill, isEdit])

    useEffect(() => {
        if(!activeHeader && !activeFont && !activeSize && !activeColor){
            setActiveColor('#FFFFFF');
            setActiveHeader(0);
            setActiveFont('openSans');
            setActiveSize(defaultSizes[0])
        }
    }, [isEdit])

    return <>
        <div className="ql-formats editor-toolbar-column">
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Bold'
            >
                <button className="ql-bold" />
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Italic'
            >
                <button className="ql-italic" />
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Underlined'
            >
                <button className="ql-underline" />
            </Tippy>
            <div className="blog-generator-divider" />
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Text Style'
            >
                <Tippy
                    offset={[0,4]}
                    arrow={false}
                    className='generator-button-tooltip-modal w-[170px]'
                    placement='bottom-start'
                    visible={activeButton === 'style'}
                    content={<ul className="flex flex-col gap-[2px]">
                        {styleOptions.map((item, index) => 
                            <li
                                key={index}
                                className="h-[28px] flex gap-[6px] items-center text-[14px] pointer px-[10px]"
                                onClick={() => {
                                    quill.format('header', index, 'user');
                                    setActiveHeader(index);
                                    setActiveButton(null);
                                }}
                            >
                                <span className={activeHeader === index ? 'text-[#FF8114]' : 'text-[#A7A7A7]'}>
                                    {item}
                                </span>
                                {activeHeader === index && <CheckIcon />}
                            </li>
                        )}
                    </ul>}
                >
                    <div
                        className={classNames('select w-[120px]', {
                            'ql-active': activeButton === 'style'
                        })}
                        onClick={() => setActiveButton(activeButton === 'style' ? null : 'style')}
                    >
                        <div className="flex-1 text-[12px] font-medium">{styleOptions[activeHeader]}</div>
                        <ArrowIcon />
                    </div>
                </Tippy>
            </Tippy>
            <div className="blog-generator-divider" />
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Text Font'
            >
                <Tippy
                    offset={[0,4]}
                    arrow={false}
                    className='generator-button-tooltip-modal w-[170px]'
                    placement='bottom-start'
                    visible={activeButton === 'font'}
                    content={<ul className="flex flex-col gap-[2px]">
                        {Object.entries(fontOptions).map(([key, value]) => 
                            <li
                                key={key}
                                className="h-[28px] flex gap-[6px] items-center text-[14px] pointer px-[10px]"
                                onClick={() => {
                                    quill.format('font', key, 'user');
                                    setActiveFont(key);
                                    setActiveButton(null);
                                }}
                            >
                                <span className={activeFont === key ? 'text-[#FF8114]' : 'text-[#A7A7A7]'}>
                                    {value}
                                </span>
                                {activeFont === key && <CheckIcon />}
                            </li>
                        )}
                    </ul>}
                >
                    <div
                        className={classNames('select w-[120px]', {
                            'ql-active': activeButton === 'font'
                        })}
                        onClick={() => setActiveButton(activeButton === 'font' ? null : 'font')}
                    >
                        <div className="flex-1 text-[12px] font-medium">{fontOptions[activeFont]}</div>
                        <ArrowIcon />
                    </div>
                </Tippy>
            </Tippy>
            <div className="blog-generator-divider" />
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Font Size'
            >
                <Tippy
                    offset={[0,4]}
                    arrow={false}
                    className='generator-button-tooltip-modal'
                    placement='bottom-start'
                    visible={activeButton === 'size'}
                    content={<ul className="flex flex-col gap-[2px]">
                        {fontSizeOptions.map((value) => 
                            <li
                                key={value}
                                className="h-[28px] flex gap-[6px] items-center text-[14px] pointer px-[10px]"
                                onClick={() => {
                                    quill.format('size', value, 'user');
                                    // setActiveFont(key);
                                    setActiveButton(null);
                                }}
                            >
                                <span className={activeSize === value ? 'text-[#FF8114]' : 'text-[#A7A7A7]'}>
                                    {value}
                                </span>
                                {activeSize === value && <CheckIcon />}
                            </li>
                        )}
                    </ul>}
                >
                    <div
                        className={classNames('select w-[60px]', {
                            'ql-active': activeButton === 'size'
                        })}
                        onClick={() => setActiveButton(activeButton === 'size' ? null : 'size')}
                    >
                        <div className="flex-1 text-[12px] font-medium">{fontSizeOptions.find(value => value === activeSize)}</div>
                        <ArrowIcon />
                    </div>
                </Tippy>
            </Tippy>
            <div className="blog-generator-divider" />
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Text Color'
            >
                <Tippy
                    offset={[0,4]}
                    arrow={false}
                    className='generator-button-tooltip-modal'
                    placement='bottom-start'
                    visible={activeButton === 'color'}
                    content={<ColorPicker
                        handlePickColor={(color) => {
                            quill.format('color', color, 'user');
                            setActiveButton(null);
                        }}
                        activeColor={activeColor}
                    />}
                >
                    <button 
                        className={classNames("[svg]:hidden", {
                            'ql-active': activeButton === 'color'
                        })}
                        onClick={() => setActiveButton(activeButton === 'color' ? null : 'color')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M7.3125 9.75H10.6875" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9 3V9.75" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.375 4.52775V3.375C12.375 3.168 12.207 3 12 3H6C5.793 3 5.625 3.168 5.625 3.375V4.52775" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <rect x="3" y="13" width="12" height="2.5" rx="1.25" fill={activeColor}/>
                            <rect x="3.15" y="13.15" width="11.7" height="2.2" rx="1.1" stroke="black" strokeOpacity="0.12" strokeWidth="0.3"/>
                        </svg>
                    </button>
                </Tippy>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Highlight Color'
            >
                <Tippy
                    offset={[0,4]}
                    arrow={false}
                    className='generator-button-tooltip-modal'
                    placement='bottom-start'
                    visible={activeButton === 'background'}
                    content={<ColorPicker
                        handlePickColor={(color) => {
                            quill.format('background', color, 'user');
                            setActiveButton(null);
                        }}
                        activeColor={activeColor}
                    />}
                >
                    <button 
                        className={classNames("", {
                            'ql-active': activeButton === 'background'
                        })}
                        onClick={() => setActiveButton(activeButton === 'background' ? null : 'background')}
                    >
                        <div className="w-[18px] h-[18px] rounded-[4px] border-[1.5px] border-solid border-[#A4A4A429]
                        flex justify-center items-center">
                            <TextIcon />
                        </div>
                    </button>
                </Tippy>
            </Tippy>
            <div className="blog-generator-divider" />
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Align Left'
            >
                <button className="ql-align" value=""></button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Align Center'
            >
                <button className="ql-align" value="center"></button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Align Right'
            >
                <button className="ql-align" value="right"></button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Align Justify'
            >
                <button className="ql-align" value="justify"></button>
            </Tippy>
            <div className="blog-generator-divider" />
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Add Link'
            >
                <button 
                    className={classNames("", {
                        'ql-active': activeButton === 'link'
                    })}
                    onClick={() => setActiveButton(activeButton === 'link' ? null : 'link')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M13.0833 9.58357L14.833 7.83382C16.1215 6.54532 16.1215 4.45582 14.833 3.16657V3.16657C13.5445 1.87807 11.455 1.87807 10.1658 3.16657L8.41602 4.91632" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.66797 11.333L11.333 6.66797" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.91632 8.41602L3.16657 10.1658C1.87807 11.4543 1.87807 13.5438 3.16657 14.833V14.833C4.45507 16.1215 6.54457 16.1215 7.83382 14.833L9.58357 13.0833" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Add Picture'
            >
                <button 
                    className={classNames({
                        'ql-active': activeButton === 'image'
                    })}
                    onClick={() => {
                        if(activeButton === 'image') {
                            setActiveButton(null);
                            setImagePopupOpened(false);
                            setImageLinkOpened(false);
                        } else {
                            setActiveButton('image');
                            setImagePopupOpened(true);
                        }
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M10.1252 7.49898V5.24805" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.2509 6.37418H9" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.2521 4.49801V2.24707" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.127 3.37223H15.3779" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.4867 13.9858L12.5297 11.0289C11.8233 10.3224 10.6779 10.3224 9.97141 11.0289L5.24805 15.7522" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.99988 12.0006L5.77716 8.77789C5.0707 8.07143 3.92531 8.07143 3.21885 8.77789L2.24707 9.74967" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.7527 7.49926V12.7514C15.7527 14.409 14.409 15.7527 12.7514 15.7527H5.24832C3.59078 15.7527 2.24707 14.409 2.24707 12.7514V5.24832C2.24707 3.59078 3.59078 2.24707 5.24832 2.24707H9.7502" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Add Chart'
            >
                <button 
                    className={classNames({
                        'ql-active': activeButton === 'chart'
                    })}
                    onClick={() => {
                        if(activeButton === 'chart') {
                            setActiveButton(null);
                        } else {
                            setActiveButton('chart');
                        }
                        // const customComponentHTML = '<div><h1>Chart</h1><p>Pie Chart</p></div>';
                        // quill.clipboard.dangerouslyPasteHTML(quill.getSelection(true).index, customComponentHTML);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M9.75 2.25H6C3.92893 2.25 2.25 3.92893 2.25 6V12C2.25 14.0711 3.92893 15.75 6 15.75H12C14.0711 15.75 15.75 14.0711 15.75 12V8.25" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 9.75V12" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9 6V12" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 8.25V12" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.5 6V3" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 4.5H15" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </Tippy>
            <button 
                className="ql-image"
                style={{
                    display: 'none'
                }}
                ref={imageButtonRef}
            ></button>
        </div>
        <div className="ql-formats editor-toolbar-column">
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Undo'
            >
                <button onClick={() => quill.history.undo()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M6 3.75L3.75 6L6 8.25" stroke="#A4A4A4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.75 6H10.5C12.9855 6 15 7.8465 15 10.125V10.125C15 12.4035 12.9855 14.25 10.5 14.25H4.5" stroke="#A4A4A4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Redo'
            >
                <button onClick={() => quill.history.redo()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M12.75 3.75L15 6L12.75 8.25" stroke="#A4A4A4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15 6H8.25C5.7645 6 3.75 7.8465 3.75 10.125V10.125C3.75 12.4035 5.7645 14.25 8.25 14.25H14.25" stroke="#A4A4A4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </Tippy>
            <Tippy
                offset={[0,4]}
                arrow={false}
                className='generator-button-tooltip'
                placement='bottom'
                content='Expand'
            >
                <button
                    onClick={() => setCardFullScreen(isFullScreen ? false : true)}
                >
                    {isFullScreen ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M2.83125 16.5939C2.57187 16.5939 2.36328 16.515 2.20547 16.3572C2.04766 16.1994 1.96875 15.9908 1.96875 15.7314V11.2502C1.96875 11.0152 2.05224 10.8127 2.21921 10.6427C2.38619 10.4727 2.58494 10.3877 2.81546 10.3877C3.04599 10.3877 3.24687 10.4727 3.41812 10.6427C3.58937 10.8127 3.675 11.0152 3.675 11.2502V13.6689L13.125 4.21895H10.6875C10.4578 4.21895 10.2598 4.13546 10.0933 3.96848C9.92695 3.80151 9.84375 3.60276 9.84375 3.37223C9.84375 3.14171 9.92695 2.94082 10.0933 2.76957C10.2598 2.59832 10.4578 2.5127 10.6875 2.5127H15.1875C15.44 2.5127 15.6469 2.59332 15.8081 2.75457C15.9694 2.91582 16.05 3.1227 16.05 3.3752V7.8752C16.05 8.10488 15.9647 8.30293 15.7941 8.46934C15.6234 8.63574 15.4201 8.71895 15.1842 8.71895C14.9536 8.71895 14.7559 8.63574 14.591 8.46934C14.4262 8.30293 14.3437 8.10488 14.3437 7.8752V5.4377L4.89375 14.8877H7.3125C7.5475 14.8877 7.75 14.973 7.92 15.1436C8.09 15.3143 8.175 15.5176 8.175 15.7535C8.175 15.9841 8.09 16.1818 7.92 16.3467C7.75 16.5115 7.5475 16.5939 7.3125 16.5939H2.83125Z" fill="#FF8114"/>
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g opacity="0.6">
                            <path d="M2.83125 16.5939C2.57187 16.5939 2.36328 16.515 2.20547 16.3572C2.04766 16.1994 1.96875 15.9908 1.96875 15.7314V11.2502C1.96875 11.0152 2.05224 10.8127 2.21921 10.6427C2.38619 10.4727 2.58494 10.3877 2.81546 10.3877C3.04599 10.3877 3.24687 10.4727 3.41812 10.6427C3.58937 10.8127 3.675 11.0152 3.675 11.2502V13.6689L13.125 4.21895H10.6875C10.4578 4.21895 10.2598 4.13546 10.0933 3.96848C9.92695 3.80151 9.84375 3.60276 9.84375 3.37223C9.84375 3.14171 9.92695 2.94082 10.0933 2.76957C10.2598 2.59832 10.4578 2.5127 10.6875 2.5127H15.1875C15.44 2.5127 15.6469 2.59332 15.8081 2.75457C15.9694 2.91582 16.05 3.1227 16.05 3.3752V7.8752C16.05 8.10488 15.9647 8.30293 15.7941 8.46934C15.6234 8.63574 15.4201 8.71895 15.1842 8.71895C14.9536 8.71895 14.7559 8.63574 14.591 8.46934C14.4262 8.30293 14.3437 8.10488 14.3437 7.8752V5.4377L4.89375 14.8877H7.3125C7.5475 14.8877 7.75 14.973 7.92 15.1436C8.09 15.3143 8.175 15.5176 8.175 15.7535C8.175 15.9841 8.09 16.1818 7.92 16.3467C7.75 16.5115 7.5475 16.5939 7.3125 16.5939H2.83125Z" fill="white"/>
                        </g>
                    </svg>}
                </button>
            </Tippy>
        </div>
    </>;
}

export default EditorToolbar;