import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/arrow.svg';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getActiveBlogIndex, getGeneratedBlogs, setActiveBlogIndex, setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogIsEdit } from '../../../redux/slices/blogGeneratorSlice';
import { useDispatch } from 'react-redux';

import { getToken } from '../../../redux/slices/applicationSlice';

import CardContent from './CardContent';

const BlogPostCard = ({data, index, setCardFullScreen, isFullScreen, isNotDone = false}) => {
    const token = useSelector(getToken);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    const {activeVersionIndex, versions } = blogs[index] || {};
    const { generatedDate, selectedVersionId } = blogs[index];
    const { activeButton, isEdit } = versions[activeVersionIndex] || {};
    const dispatch = useDispatch();
    const isOpen = index === activeBlogIndex;

    const isSelected = selectedVersionId === data[activeVersionIndex]?.id;
    const [customPrompt, setCustomPrompt] = useState('');

    const { id } = useParams();
    const [updateBlogs, setUpdateBlogs] = useState(false);
    useEffect(() => {
        if(updateBlogs) {
            api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify(blogs)
                },
                token
            }).catch(e => console.log(e))
            setUpdateBlogs(false);
        }
    }, [updateBlogs])

    const markup = data[activeVersionIndex]?.activeContent || data[activeVersionIndex]?.content;

    return <li 
        className={classNames(
            'blog-post-card', 
            isFullScreen ? 'h-full' : isOpen ? 'h-[600px] lg:h-[442px]' : 'h-[42px]',
            (isFullScreen && !isOpen) ? 'hidden': 'flex'
        )}
        style={{
            borderColor: isSelected ? '#FF8114': '#1F1F1F'
        }}
    >
        <div className='flex items-center justify-between py-[11px] px-[16px] mob-flex-wrap'>
            <div className={`gen-con-header-title gap-[8px] cursor-pointer ${isNotDone ? 'disabled' : ''}`} onClick={() => dispatch(setActiveBlogIndex(index))}>
                <div
                    className={`gen-header-arrow pointer-events-none ${isOpen && 'rotate-180'}`}
                    //  onClick={(e) => {e.stopPropagation()}}
                >
                    <ArrowIcon />
                </div>
                <span className="leading-[18px]">Prompt {index + 1}</span>
            </div>
            <div className="gen-con-header-date leading-[18px]">
                Generated  {format(generatedDate ? new Date(generatedDate) : new Date(), 'MM.dd.yyyy, H:mm')}
            </div>
        </div>
        
        {isOpen && <CardContent
            index={index}
            isFullScreen={isFullScreen}
            setCardFullScreen={setCardFullScreen}
            setUpdateBlogs={setUpdateBlogs}
            markup={markup}
            isNotDone={isNotDone}
        />}
    </li>;
};

export default BlogPostCard;