import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getCourseCompeteTime, newsletters } from './consts';
import { format } from 'date-fns';
import { db } from '../../../db';

const NewsletterItem = ({ data, setOpenedCard, openSettings, generationIsNotDone }) => {
    console.log('NewsletterItem data:', data);
    const needSetup = !data.lessons || !data.cover;
    const navigate = useNavigate();
   
    const [image, setImage] = useState(null);

    const onImageChange = (file) => {
        if(file && typeof file !== 'string') {
            setImage(URL.createObjectURL(file));
        } else if(file && typeof file === 'string'){
            setImage(`${process.env.REACT_APP_API_URL}${file}`)
        }
    }

    useEffect(() => {
        if(data?.cover){
            onImageChange(data.cover);
        }
    }, [data.cover]);

    return <div
        className='generated-content-letter-card cursor-pointer'
        onClick={() => {
            if(needSetup) return;
            navigate(`/generated-page/courses/${data.id}`)
        }}
    >   
        <div className="w-[170px] shrink d-flex items-start justify-center">
            <div className={` ${!data.cover ? 'text-[14px] text-[#5F5F5F] bg-[#1B1B1B] rounded-xl border border-solid border-[#333] d-flex items-center justify-center w-100 min-h-[130px]' : ''}`}>
                {
                    data.cover ? <img 
                        src={image}
                        alt='course'
                        className='generated-content-letter-card-image w-[170px] h-full'
                    /> : 'No Image'
                }
            </div>
        </div>
        <div className='flex flex-col gap-[10px] flex-1'>
            <h2 className='text-[14px] font-medium leading-[22px] opacity-90'>{data.title || data.text}</h2>
            <p className='text-[13px] leading-[22px] opacity-60 line-clamp-6'>{
                data.description || 'To create content for this newsletter we need more information. Please setup this newsletter.'
            }</p>
            <div className={`flex justify-between flex-col lg:flex-row items-start ${needSetup ? 'lg:items-end' : 'lg:items-center'}`}>
                {   needSetup ? 
                    <div>
                        <button 
                            className="generated-button btn generated-content-bottom-panel-btn min-w-[109px] mb-[16px]" 
                            style={{ fontSize: '13px', height: '28px' }}
                            onClick={openSettings}
                        >
                            Setup Newsletter
                        </button>
                        <div className='d-flex items-center text-[#E84851] text-[13px] font-[500]'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-[8px]'>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9 2.25V2.25C12.7283 2.25 15.75 5.27175 15.75 9V9C15.75 12.7283 12.7283 15.75 9 15.75V15.75C5.27175 15.75 2.25 12.7283 2.25 9V9C2.25 5.27175 5.27175 2.25 9 2.25Z" stroke="#FF4E58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 9.375V5.625" stroke="#FF4E58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.99925 12C8.89575 12 8.81175 12.084 8.8125 12.1875C8.8125 12.291 8.8965 12.375 9 12.375C9.1035 12.375 9.1875 12.291 9.1875 12.1875C9.1875 12.084 9.1035 12 8.99925 12" stroke="#FF4E58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Need to setup newsletter
                        </div>
                    </div> :
                    <div className='flex gap-[12px]'>
                        <div className='flex gap-[8px] items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M7.75 5.82752H16.0034" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.0034 10H7.75" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.75 14.1725H16.0034" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.37125 5.45236C4.16406 5.45236 3.99609 5.62033 3.99609 5.82752C3.99609 6.03471 4.16406 6.20267 4.37125 6.20267C4.57844 6.20267 4.74641 6.03471 4.74641 5.82752C4.74641 5.62033 4.57844 5.45236 4.37125 5.45236" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.37125 9.62485C4.16406 9.62485 3.99609 9.79281 3.99609 10C3.99609 10.2072 4.16406 10.3752 4.37125 10.3752C4.57844 10.3752 4.74641 10.2072 4.74641 10C4.74641 9.79281 4.57844 9.62485 4.37125 9.62485" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.37125 13.7973C4.16406 13.7973 3.99609 13.9653 3.99609 14.1725C3.99609 14.3797 4.16406 14.5476 4.37125 14.5476C4.57844 14.5476 4.74641 14.3797 4.74641 14.1725C4.74641 13.9653 4.57844 13.7973 4.37125 13.7973" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className='text-[13px] leading-[22px] opacity-90 font-medium'>
                                {data?.lessons?.length} lesson{data?.lessons?.length > 1  ? 's' : ''}
                            </span>
                        </div>
                        <div className='flex gap-[8px] items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.375 5.25V9.375H6" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className='text-[13px] leading-[22px] opacity-90 font-medium'>
                                {data?.lessons?.[0]?.timeToComplete ? getCourseCompeteTime(data?.lessons) : null}
                            </span>
                        </div>
                    </div>
                }
                <div className='text-[13px] opacity-30'>
                    Generated {data?.generated ? format(new Date(data.generated), 'MM.dd.yyyy, H:mm') : "-"}
                </div>
            </div>
        </div>
        <div className={`flex lg:flex-col gap-[8px] items-center ${generationIsNotDone ? 'block-disabled' : ''}`}>
            <button 
                className='generated-content-letter-card-button rounded-[8px] w-[32px] hover:opacity-70'
                onClick={e => {

                    e.preventDefault();
                    e.stopPropagation();
                    openSettings();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" className='pointer-events-none'>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.60026 2.24707C9.91682 2.24707 10.1993 2.44584 10.3063 2.74378L10.6875 3.80547C10.748 3.97242 10.8654 4.1127 11.0191 4.20163L12.1498 4.85441C12.3034 4.943 12.4833 4.97436 12.6578 4.94294L13.7682 4.74261C14.0801 4.68591 14.3939 4.83126 14.5523 5.10576L15.1526 6.14119C15.3114 6.41495 15.2814 6.75894 15.0775 7.00105L14.349 7.86241C14.2342 7.99785 14.1712 8.1696 14.1712 8.34711V9.65265C14.1712 9.83017 14.2342 10.0019 14.349 10.1374L15.0775 10.9987C15.2814 11.2408 15.3114 11.5848 15.1526 11.8586L14.5523 12.894C14.394 13.1682 14.0806 13.3135 13.769 13.2572L12.6585 13.0568C12.4841 13.0254 12.3041 13.0568 12.1506 13.1454L11.0198 13.7981C10.8661 13.8871 10.7487 14.0273 10.6882 14.1943L10.307 15.256C10.2 15.5542 9.9171 15.753 9.60026 15.7527H8.39976C8.08319 15.7527 7.80071 15.5539 7.69371 15.256L7.31255 14.1943C7.25207 14.0276 7.13496 13.8873 6.98167 13.7981L5.85019 13.1454C5.69665 13.0568 5.5167 13.0254 5.34223 13.0568L4.23177 13.2572C3.91994 13.3139 3.60612 13.1685 3.44769 12.894L2.84744 11.8586C2.68859 11.5848 2.71861 11.2408 2.92247 10.9987L3.65103 10.1374C3.76577 10.0019 3.82878 9.83017 3.82885 9.65265V8.34711C3.82878 8.1696 3.76577 7.99785 3.65103 7.86241L2.92998 7.00105C2.72611 6.75894 2.6961 6.41495 2.85495 6.14119L3.4552 5.10576C3.61352 4.83155 3.92694 4.68625 4.23852 4.74261L5.34899 4.94294C5.52345 4.97436 5.7034 4.943 5.85695 4.85441L6.98842 4.20163C7.14172 4.11242 7.25882 3.97221 7.31931 3.80547L7.70046 2.74378C7.80662 2.44816 8.08567 2.24995 8.39976 2.24707H9.60026Z" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="9.00086" cy="8.99988" r="2.06336" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <button 
                className='generated-content-letter-card-button rounded-[8px] w-[32px] hover:opacity-70'
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    db.courses.delete(data.id);
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M4.74453 16.1437C4.28876 16.1437 3.88731 15.9764 3.5402 15.6417C3.19309 15.307 3.01953 14.9056 3.01953 14.4375V4.0875H2.81328C2.57891 4.0875 2.38281 4.00713 2.225 3.84638C2.06719 3.68564 1.98828 3.48251 1.98828 3.237C1.98828 2.9915 2.06711 2.7875 2.22477 2.625C2.38242 2.4625 2.57859 2.38125 2.81328 2.38125H6.22578C6.22578 2.13125 6.30781 1.92187 6.47187 1.75312C6.63594 1.58437 6.84141 1.5 7.08828 1.5H10.9133C11.1586 1.5 11.3668 1.58594 11.5379 1.75781C11.709 1.92969 11.7945 2.1375 11.7945 2.38125H15.168C15.4215 2.38125 15.6289 2.46484 15.7902 2.63203C15.9514 2.79923 16.032 2.99996 16.032 3.23421C16.032 3.48597 15.9514 3.69112 15.7902 3.84967C15.6289 4.00822 15.422 4.0875 15.1695 4.0875H14.9633V14.4375C14.9633 14.9056 14.7941 15.307 14.4557 15.6417C14.1174 15.9764 13.7116 16.1437 13.2383 16.1437H4.74453ZM6.69453 12.3C6.69453 12.4762 6.7636 12.6328 6.90174 12.7697C7.03987 12.9066 7.198 12.975 7.37611 12.975C7.55422 12.975 7.71641 12.9066 7.86266 12.7697C8.00891 12.6328 8.08203 12.4762 8.08203 12.3V6.225C8.08203 6.04344 8.00801 5.8793 7.85997 5.73259C7.71193 5.58586 7.54614 5.5125 7.36263 5.5125C7.18442 5.5125 7.02852 5.58586 6.89493 5.73259C6.76133 5.8793 6.69453 6.04344 6.69453 6.225V12.3ZM9.93828 12.3C9.93828 12.4762 10.0105 12.6328 10.1549 12.7697C10.2992 12.9066 10.4605 12.975 10.6386 12.975C10.8167 12.975 10.9789 12.9066 11.1252 12.7697C11.2714 12.6328 11.3445 12.4762 11.3445 12.3V6.225C11.3445 6.04344 11.2724 5.8793 11.1281 5.73259C10.9837 5.58586 10.818 5.5125 10.6307 5.5125C10.4488 5.5125 10.2879 5.58586 10.1481 5.73259C10.0082 5.8793 9.93828 6.04344 9.93828 6.225V12.3Z" fill="#9D9D9D"/>
                </svg>
            </button>
        </div>
    </div>
};

export default NewsletterItem;