import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import logo from '../../assets/img/gen4logo.svg';
import { chats, navLinks } from './consts';
import Chat from './Chat';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import api from '../../api';
import { ReactComponent as ExitIcon } from '../../assets/img/icons/arrow-exit.svg';
import useScreenWidth from '../../helpers/useScreenWidth';

const ChatBot = () => {
    const { botId, chatId } = useParams();
    const token = useSelector(getToken);

    const screenWidth = useScreenWidth();
    const mobileScreen = screenWidth < 1199;

    const [chats, setChats] = useState([]);
    const [botKit, setBotKit] = useState(null);

    useEffect(() => {
        api.botKits.getBotKit({
            botId,
            token
        }).then((resp) => {
            if(!resp.success) {
                return;
            }
            setBotKit(resp.data);
            api.botKits.getBotKitChats({
                botId,
                token
            }).then(resp => {
                if(!resp.success) {
                    return;
                }
                setChats(resp.data);

                if(mobileScreen){
                    setSideBarOpened(false);
                    setActiveTab(null);
                }
            })
        })
    },[botId, token])

    const [activeTab, setActiveTab] = useState(navLinks[0].title);
    const activeTabObjectIndex = navLinks.findIndex(({title}) => title === activeTab);
    const ActiveComponent = navLinks[activeTabObjectIndex]?.Component;

    const [sideBarOpened, setSideBarOpened] = useState(true);

    return <main className='w-screen h-screen overflow-hidden flex'>
        <nav className='flex flex-col w-[72px] bg-[#151515]'>
            <div className={classNames('generated-tab w-[72px] h-[60px]', {
                'generated-tab--active-top': activeTabObjectIndex === 0,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <Link to='/'><img src={logo} alt='Logo'/></Link>
            </div>
            {navLinks.map(({ icon, title }, index) =>
                <button
                    key={index} 
                    className={classNames('generated-tab w-[72px] h-[72px]', {
                        'generated-tab--active': title === activeTab,
                        'generated-tab--active-top': index === activeTabObjectIndex - 1,
                        'generated-tab--active-bottom': index === activeTabObjectIndex + 1,
                        'generated-tab--closed': !sideBarOpened,
                    })}
                    onClick={() => {
                        setActiveTab(title);
                        setSideBarOpened(true);
                    }}
                >
                    {icon}
                    <span className='text-[10px] font-medium'>{title}</span>
                </button>
            )}
            <div className={classNames('generated-exit-wrapper', {
                    'generated-tab--active-bottom': activeTabObjectIndex === navLinks.length - 1,
                    'generated-tab--closed': !sideBarOpened,
                })}>
                    <Link to='/my-assets?tab=My Bots' className='w-[72px] h-[72px] flex flex-col gap-[8px] justify-center items-center opacity-40 bg-[#111111]'>
                        <ExitIcon />
                        <span className='text-[10px] font-medium'>Exit</span>
                    </Link>
            </div>
        </nav>
        <div className={classNames('generated-sidebar', {
            'generated-sidebar--closed': !sideBarOpened
        })}>
            <button 
                className='absolute top-[18px] right-[16px]' 
                onClick={() => {
                    setSideBarOpened(false);
                    setActiveTab(null);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                        <path d="M18 6L6 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>
            {ActiveComponent && 
                <ActiveComponent 
                    botKit={botKit} 
                    setBotKit={setBotKit}
                    chats={chats} 
                    setChats={setChats}
                />}
        </div>
        <Chat setChats={setChats} botKit={botKit}/>
    </main>
};

export default ChatBot;