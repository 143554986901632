import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import Chart from 'chart.js/auto';
import Quill from "quill";
import Tippy from '@tippyjs/react';
const InlineBlot = Quill.import('blots/block/embed');
class ImageBlot extends InlineBlot {
    static create(data) {
        const node = super.create(data);
        console.log(node)

        const img = document.createElement('img');
        img.setAttribute('src', data.src);
        img.setAttribute('class', data.class);
        if (data.dataChart) {
            img.setAttribute('data-chart', data.dataChart);
        }
        node.appendChild(img);
        return node;
    }
    static value(domNode) {
        // const { src, chart } = domNode.dataset;
        const img = domNode.querySelector('img');
        // console.log(img)
        if (img) {
            return {
                src: img.getAttribute('src'),
                class: img.getAttribute('class'),
                dataChart: img.getAttribute('data-chart'),
            };
        }
    }
}
ImageBlot.blotName = 'imageBlot';
ImageBlot.tagName = 'figure';
ImageBlot.className = 'chart-wrapper';
console.dir(ImageBlot)
Quill.register({ 'formats/imageBlot': ImageBlot });
const alphabet = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G',
    'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U',
    'V', 'W', 'X', 'Y', 'Z'
]
const cols = new Array(36).fill('');
const DEFAULTCOLOR = '#FFE47F'

const AddEditorChartModal = ({ handelClose, isOpened, editor, initialData }) => {

    const [labels, setLabels] = useState(Array(5).fill('Data'));
    const [chartTitle, setChartTitle] = useState('');
    const [chartType, setChartType] = useState(null);
    const [indexAxis, setIndexAxis] = useState('x');

    const [datasets, setDatasets] = useState([
        {
            label: 'series 1',
            data: Array(5).fill(22),
            backgroundColor: Array(5).fill(DEFAULTCOLOR), // default color
        }
    ]);

    const [yAxisMin, setYAxisMin] = useState(0);
    const [yAxisMax, setYAxisMax] = useState(30);
    const canvasRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const currentChart = useRef(null);
    const initChart = () => {

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        chartInstanceRef.current = new Chart(canvasRef.current, {
            type: chartType,
            data: {
                labels: labels,
                datasets: datasets.map(dataset => ({
                    ...dataset,
                    borderColor: dataset.backgroundColor,
                    borderWidth: 1,
                }))
            },
            options: {
                indexAxis: indexAxis,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: chartTitle
                    }
                },
                scales: {
                    y: {
                        display: chartType === 'pie' ? false : true,
                        beginAtZero: true,
                        min: yAxisMin,
                        max: yAxisMax,
                    },
                },
            },
        });

    }
    useEffect(() => {
        if (canvasRef.current) {
            initChart()
        }
    }, [chartType, canvasRef])
    useEffect(() => {
        if (initialData) {
            setLabels(initialData.labels)
            setDatasets(initialData.datasets)
            setChartType(initialData.type)
            setYAxisMax(initialData.yAxisMax)
            setYAxisMin(initialData.yAxisMin)
            setChartTitle(initialData.title)
            currentChart.current = document.querySelector('.selected-chart')
        }
    }, [initialData])
    const handleLabelChange = (index, value) => {
        const newLabels = [...labels];
        newLabels[index] = value;
        setLabels(newLabels);


        const newDatasets = datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.length > newLabels.length
                ? dataset.data.slice(0, newLabels.length)
                : [...dataset.data, ...Array(newLabels.length - dataset.data.length).fill(0)]
        }));
        setDatasets(newDatasets);
    };

    const handleDatasetChange = (datasetIndex, key, value) => {
        if (!labels[datasetIndex]) {
            setLabels(prev => [...prev, 'data'])
        }
        const newDatasets = [...datasets];
        newDatasets[datasetIndex] = {
            ...newDatasets[datasetIndex],
            [key]: key === 'label' ? value : value.split(',').map(Number),
        };


        setDatasets(newDatasets);
    };

    const handleDataChange = (rowIndex, colIndex, value) => {
        const newDatasets = [...datasets];
        if (value > yAxisMax) {
            toast.error('Value greater than Data range end')
            return
        }
        if (value < yAxisMin) {
            toast.error('Value greater than Data range start')
            return
        }
        if (!labels[colIndex]) {
            setLabels(prev => [...prev, 'data'])
        }

        if (!newDatasets[rowIndex]) {
            newDatasets[rowIndex] = {
                label: `Series ${rowIndex + 1}`,
                data: new Array(colIndex).fill(0),
                backgroundColor: []
            };
        }
        newDatasets[rowIndex].data[colIndex] = value;
        if (!newDatasets[rowIndex].backgroundColor[colIndex]) {
            newDatasets[rowIndex].backgroundColor[colIndex] = newDatasets[rowIndex].backgroundColor[colIndex] || DEFAULTCOLOR
        }
        setDatasets(newDatasets);


    };
    const handleColorChange = (datasetIndex, color, seriesColor) => {

        const newDatasets = [...datasets];
        // if(!newDatasets[datasetIndex]) {
        //     return
        // }
        if (seriesColor) {
            if (newDatasets[datasetIndex]) {
                newDatasets[datasetIndex].backgroundColor = newDatasets[datasetIndex].backgroundColor.map(setcolor => color);
            }

        } else {
            newDatasets.map(dataset => {
                dataset.backgroundColor[datasetIndex] = color
            })
        }
        setDatasets(newDatasets);
    };

    const insertImage = (imageUrl) => {

        const chartData = JSON.stringify({ type: chartType, labels, datasets, yAxisMin, yAxisMax, indexAxis, title: chartTitle })
        if (currentChart.current) {
            currentChart.current.src = imageUrl
            currentChart.current.setAttribute('data-chart', chartData)
            currentChart.current.classList.remove('selected-chart')
        } else {
            const range = editor.getSelection();  // Получаем текущую позицию курсора
            
                // editor.insertEmbed(range.index, 'image', imageUrl);  // Вставляем изображение
                editor.insertEmbed(range?.index || 0, 'imageBlot', {
                    src: imageUrl, // any url
                    dataChart: chartData,
                    class: 'chart-img'
                });
            
        }


    };


    const handleSubmit = () => {
        const chartDataUrl = canvasRef.current.toDataURL();

        insertImage(chartDataUrl)
        handelClose()
        setChartType(null)
        currentChart.current = null
    };
    return (
        <div className={`modal ${isOpened ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--1000">
                <div className={'modal__content'}>

                    <button className="modal-outside-close-btn" onClick={() => {
                        currentChart.current = null
                        handelClose()
                    }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.8">
                                <path d="M22.5 7.5L7.5 22.5" stroke="#A7A7A7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.5 7.5L22.5 22.5" stroke="#A7A7A7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </svg>
                    </button>
                    {!chartType ? <div className='flex gap-5 p-3 max-sm:flex-col'>
                        <button
                            className='flex flex-col justify-center items-center min-h-[60px] lg:min-h-[122px] flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
                            onClick={() => {
                                setChartType('pie')
                                setIndexAxis('x')
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                <path d="M8.50022 1.61035C4.81178 2.90818 2.151 6.14934 1.59651 10.0199C1.04202 13.8905 2.68581 17.7483 5.86154 20.0295C9.03726 22.3107 13.218 22.6367 16.709 20.8754" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1V11.4999L19.4247 18.9245C22.4276 15.9215 23.3259 11.4053 21.7007 7.48178C20.0755 3.55824 16.2468 1.00002 12 1L12 1Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className='mt-[5px] lg:mt-[16px] text-[13px] font-medium opacity-90'>Pie Chart</p>
                        </button>
                        <button
                            className='flex flex-col justify-center items-center min-h-[60px] lg:min-h-[122px] flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
                            onClick={() => {
                                setChartType('line')
                                setIndexAxis('x')
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                <path d="M24.5 25H5.83333C4.54417 25 3.5 23.9558 3.5 22.6667V4" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.5003 7.5L19.136 13.9377C18.849 14.2818 18.3917 14.4312 17.9565 14.3227L14.7108 13.5107C14.2768 13.4022 13.8183 13.5515 13.5313 13.8957L8.16699 20.3333" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className='mt-[5px] lg:mt-[16px] text-[13px] font-medium opacity-90'>Line Chart</p>
                        </button>
                        <button
                            onClick={() => {
                                setChartType('bar')
                                setIndexAxis('x')
                            }}
                            className='flex flex-col justify-center items-center min-h-[60px] lg:min-h-[122px] flex-1 rounded-[8px] bg-[#202020] cursor-pointer'

                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                <path d="M8.16634 20.3333V14.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.8333 20.3333V7.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.5003 20.3335V13.2168" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.1663 20.3337V8.66699" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3.5 4V22.6667C3.5 23.9558 4.54417 25 5.83333 25H24.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className='mt-[5px] lg:mt-[16px] text-[13px] font-medium opacity-90'>Column Chart</p>
                        </button>
                        <button
                            onClick={() => {
                                setChartType('bar')
                                setIndexAxis('y')
                            }}
                            className='flex flex-col justify-center items-center min-h-[60px] lg:min-h-[122px] flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                <path d="M3.50033 4V25" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 17.2217H17.5V21.4998C17.5 22.1438 16.9773 22.6665 16.3333 22.6665H3.5V17.2217Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 11.7783H23.3333C23.9773 11.7783 24.5 12.301 24.5 12.945V16.0565C24.5 16.7005 23.9773 17.2232 23.3333 17.2232H3.5V11.7783Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 6.33301H19.8333C20.4773 6.33301 21 6.85567 21 7.49967V11.7778H3.5V6.33301Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className='mt-[5px] lg:mt-[16px] text-[13px] font-medium opacity-90'>Bar Chart</p>
                        </button>
                    </div> :
                        <div className="">

                            <div className="flex max-w-full max-md:flex-col">
                                <div className="tabs-chart-block">
                                    <div className="tabs gap-[32px]">
                                        <div className="tabs-header">
                                            {/* <ul className="tabs-nav flex-wrap">
                                            <li className="tabs-nav--item max-w-max">
                                                <button type='button' onClick={() => { setChartType('bar') }} className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${chartType === 'bar' ? 'active-tab' : ''}`}>

                                                    <span className="text--sm font-medium">Bar Chart</span>
                                                </button>
                                            </li>
                                            <li className="tabs-nav--item max-w-max">
                                                <button type='button' onClick={() => { setChartType('line') }} className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${chartType === 'line' ? 'active-tab' : ''}`}>

                                                    <span className="text--sm font-medium">Line Chart</span>
                                                </button>
                                            </li>
                                            <li className="tabs-nav--item max-w-max">
                                                <button type='button' onClick={() => { setChartType('pie') }} className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${chartType === 'pie' ? 'active-tab' : ''}`}>
                                                    <span className="text--sm font-medium">Pie Chart</span>
                                                </button>
                                            </li>
                                        </ul> */}
                                            <button
                                                onClick={() => {
                                                    setChartType(null)
                                                    setIndexAxis('x')
                                                }}
                                                className='flex flex-col justify-center items-center flex-1 rounded-[8px] bg-[#202020] cursor-pointer p-3 w-full'
                                            >
                                                <p className='text-[13px] font-medium opacity-90 text-center'>Change Chart Type</p>
                                            </button>
                                        </div>
                                        <div className="tabs-body">
                                            <div className="tabs-item gap-[16px] active-tab" id="bar-chart" style={{ maxHeight: '400px' }}>
                                                <canvas ref={canvasRef} width={'100%'} height={'300px'} ></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-block">
                                    <div className="row gx-12 gy-12">
                                        <div className="col-12">
                                            <div className="input-wrapper input--sm">
                                                <label className="input-custom__label mb-[6px]">Chart title</label>
                                                <input
                                                    className="input-custom__input height-40"
                                                    type="text"
                                                    placeholder="Ender chart title"
                                                    value={chartTitle}
                                                    onChange={(e) => setChartTitle(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 mb-[20px]">
                                            <div className="input-wrapper input--sm">
                                                <label className="input-custom__label mb-[6px]">Data range start</label>
                                                <input
                                                    className="input-custom__input height-40"
                                                    type="number"
                                                    placeholder="Min"
                                                    value={yAxisMin}
                                                    onChange={(e) => setYAxisMin(Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 mb-[20px]">
                                            <div className="input-wrapper input--sm">
                                                <label className="input-custom__label mb-[6px]">Data range end</label>
                                                <input
                                                    className="input-custom__input height-40"
                                                    type="number"
                                                    placeholder="Max"
                                                    value={yAxisMax}
                                                    onChange={(e) => setYAxisMax(Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-chart-table">
                                        <table className="table-chart">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: "44px" }}>
                                                        <Tippy
                                                            offset={[0, 4]}
                                                            arrow={false}
                                                            className='generator-button-tooltip'
                                                            placement='top'
                                                            content='Update Chart'
                                                        >
                                                            <button
                                                                type='button'
                                                                className="btn h-full"
                                                                onClick={initChart}
                                                            >
                                                                <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.659 10.5269C16.6523 12.2244 16.0082 13.9194 14.7132 15.2144C12.1098 17.8177 7.88815 17.8177 5.28482 15.2144C4.82148 14.751 4.44482 14.2352 4.14648 13.6885" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M3.33594 10.3918C3.3626 8.72183 4.01094 7.06016 5.2851 5.786C7.88844 3.18266 12.1101 3.18266 14.7134 5.786C15.1768 6.24933 15.5534 6.76516 15.8518 7.31183" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.293 7.31288H16.2388V4.36621" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M6.7056 13.6875H3.75977V16.6342" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </button>
                                                        </Tippy>
                                                    </th>
                                                    {alphabet.map((item, rowIndex) => (
                                                        <th style={{ minWidth: "76px" }} key={item}>
                                                            <div className="flex items-center gap-2 justify-center">
                                                                <span>{item}</span>
                                                                {rowIndex > 0 && <input
                                                                    type="color"
                                                                    className='input-color-picker'
                                                                    value={datasets[0].backgroundColor[rowIndex - 1] || DEFAULTCOLOR}
                                                                    onChange={(e) => handleColorChange(rowIndex - 1, e.target.value)}
                                                                />}
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>1</td>
                                                    {alphabet.map((item, index) => (
                                                        <React.Fragment key={item + index}>
                                                            {index === 0 ?
                                                                <td></td> :
                                                                <td>
                                                                    <input

                                                                        type='text'
                                                                        defaultValue={labels[index] || 'data'}
                                                                        className='input text-center'
                                                                        onChange={(e) => handleLabelChange(index - 1, e.target.value)}
                                                                    />
                                                                </td>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                                {cols.map((item, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        <td>
                                                            <div className="flex items-center justify-center gap-1">
                                                                {1 + (rowIndex + 1)}
                                                                <input
                                                                    type="color"
                                                                    className='input-color-picker'
                                                                    value={datasets[rowIndex]?.backgroundColor[0] || DEFAULTCOLOR}
                                                                    onChange={(e) => handleColorChange(rowIndex, e.target.value, true)}
                                                                />
                                                            </div>
                                                        </td>
                                                        {alphabet.map((item, colIndex) => (
                                                            <React.Fragment key={colIndex + rowIndex}>
                                                                {colIndex === 0 ?
                                                                    <td>
                                                                        <input
                                                                            // defaultValue={`${rowIndex < 1 ? 'series ' + (rowIndex + 1) : ''}`}
                                                                            type='text'
                                                                            defaultValue={datasets[rowIndex]?.label || ('series' + (rowIndex + 1))}
                                                                            className='input text-center'
                                                                            onChange={(e) => handleDatasetChange(rowIndex, 'label', e.target.value)}
                                                                        />
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <input
                                                                            type='number'
                                                                            style={{
                                                                                paddingLeft: '13px'
                                                                            }}
                                                                            className='input text-center'
                                                                            value={datasets[rowIndex]?.data[colIndex - 1]}
                                                                            onChange={(e) => handleDataChange(rowIndex, colIndex - 1, e.target.value)}
                                                                        />
                                                                    </td>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal__footer justify-between">
                                <button
                                    onClick={() => {
                                        currentChart.current = null
                                        handelClose()
                                    }}
                                    className="h-[40px] px-[12px] flex items-center bg-[#1B1B1B] border border-solid border-[#333] rounded-full text-[13px] text-[#A7A7A7] gap-[8px] opacity-90"
                                >
                                    Cancel
                                </button>
                                <button type='button' onClick={handleSubmit} className="goal-generator-action btn--primary-gradient btn">Save</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddEditorChartModal
