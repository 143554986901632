import React, { useEffect, useMemo, useState } from 'react';
import SelectBox from '../../components/base/SelectBox';
// import { newsletters } from '../GeneratedContent/EmailEducator/consts';
import NewsletterCard from './NewsletterCard';
import classNames from 'classnames';
import NewsletterListItem from './NewsletterListItem';
import { useSelector } from 'react-redux';
import api from '../../api';
import { getToken } from '../../redux/slices/applicationSlice';
import GeneratedContentModal from '../GeneratedContent/GeneratedContentModal';

const Created = () => {
    const token = useSelector(getToken);

    const [viewSettings, setViewSettings] = useState({
        language: 'all',
        frequency: 'all',
        createdByMe: false,
        sortBy: 'title',
        isGrid: true
    })

    const [openedModal, setOpenedModal] = useState(null);

    const [newsletters, setNewsletters] = useState([]);
    console.log('newsletters:', newsletters);

    const fetchCourses = async() => {
        const response = await api.courses.getCourses({
            token
        });
        console.log('fetchCourses response:', response);
        setNewsletters(response.data);

        // const response2 = await api.courses.getCourse({
        //     id: response.data[1].id,
        //     token
        // });
        // console.log('fetchCourses response2:', response2);
    }

    // const resultNewsLetters = useMemo(() => newsletters.filter(({language, frequency, createdBy}) => {
    //     if(viewSettings.language !== 'all') {
    //         if(language !== viewSettings.language) {
    //             return false;
    //         }
    //     }
    //     if(viewSettings.frequency !== 'all') {
    //         if(frequency !== viewSettings.frequency) {
    //             return false
    //         }
    //     }
    //     if(viewSettings.createdByMe && !createdBy.me) {
    //         return false
    //     }
    //     return true;
    // }).sort((a, b) => {
    //     const A = a[viewSettings.sortBy];
    //     const B = b[viewSettings.sortBy];
    //     if (A < B) {
    //         return -1;
    //     }
    //     if (A > B) {
    //         return 1;
    //     }
    //     return 0;
    // }), [viewSettings.language, viewSettings.createdByMe, viewSettings.frequency, viewSettings.sortBy]);

    const CurrentBlock = viewSettings.isGrid ? NewsletterCard : NewsletterListItem;

    const resultNewsLetters = [...newsletters].sort((a, b) => {
            const A = a[viewSettings.sortBy];
            const B = b[viewSettings.sortBy];
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });
    console.log('resultNewsLetters:', resultNewsLetters);

    useEffect(()=>{
        fetchCourses();
    },[])

    return <div className='py-[16px] px-[20px]'>
        <div className='flex justify-between h-[36px] sm-filter-wrap sm-h-auto'>
            <div className='flex gap-[8px] sm-filter-wrap'>
                <SelectBox
                    className="default-select w-[150px] sort-select-box small-select email-select"
                    propsOptions={[
                        {label: 'Language', value: '', placeholder: true},
                        // ({ label: item.language, value: item.language })
                        ...[...new Set( resultNewsLetters.map(item => item.language) )].map(item => ({ label: item, value: item }))
                        // {label: 'English', value: 'eng'},
                        // {label: 'Spanish', value: 'esp'},
                        // {label: 'All', value: 'all'},
                    ]}
                    change={(language) => setViewSettings({...viewSettings, language})}
                />
                <SelectBox
                    className="default-select w-[150px] sort-select-box small-select email-select"
                    propsOptions={[
                        {label: 'Frequency', value: '', placeholder: true},
                        {label: 'Daily', value: 'daily'},
                        {label: 'Weekly', value: 'weekly'},
                        {label: 'Monthly', value: 'monthly'},
                        {label: 'All', value: 'all'}
                    ]}
                    change={(frequency) => setViewSettings({...viewSettings, frequency})}
                />
                <div className='settings-checkbox-container checkbox-new-words'>
                    <span className='sett-title'>Created by me</span>
                    <label htmlFor='created-by-me' className='sett-checkbox cursor-pointer'>
                        <input 
                            id='created-by-me'
                            type="checkbox"
                            className='sett-checkbox-hid'
                            hidden
                            checked={viewSettings.createdByMe}
                            onChange={e => setViewSettings({...viewSettings, createdByMe: e.target.checked})}
                         />
                        <div className='sett-checkbox-sub'></div>
                    </label>      
                </div>
            </div>
            <div className='flex gap-[8px] sm-bottom-filter-line'>
                <SelectBox
                    className="default-select w-[auto] sort-select-box small-select email-select email-sort"
                    propsOptions={[
                        {label: 'Title', value: 'title'},
                        {label: 'Description', value: 'description'},
                        {label: 'Lessons', value: 'lessons'}
                    ]}
                    change={sortBy => setViewSettings({...viewSettings, sortBy})}
                    icon={
                        <>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.25 18.525H3.775C3.4525 18.525 3.18021 18.4195 2.95812 18.2085C2.73604 17.9976 2.625 17.7309 2.625 17.4085C2.625 17.0862 2.73604 16.8125 2.95812 16.5875C3.18021 16.3625 3.4525 16.25 3.775 16.25H8.25C8.58667 16.25 8.8625 16.3637 9.0775 16.5912C9.2925 16.8188 9.4 17.0898 9.4 17.4044C9.4 17.7352 9.2925 18.0047 9.0775 18.2128C8.8625 18.4209 8.58667 18.525 8.25 18.525ZM20.25 7.84999H3.775C3.4525 7.84999 3.18021 7.7445 2.95812 7.53354C2.73604 7.32257 2.625 7.0559 2.625 6.73354C2.625 6.41117 2.73604 6.13332 2.95812 5.89999C3.18021 5.66665 3.4525 5.54999 3.775 5.54999H20.25C20.5562 5.54999 20.8203 5.66791 21.0422 5.90374C21.2641 6.13959 21.375 6.4148 21.375 6.72936C21.375 7.0602 21.2641 7.32968 21.0422 7.53781C20.8203 7.74593 20.5562 7.84999 20.25 7.84999ZM14.25 13.2H3.775C3.4525 13.2 3.18021 13.0928 2.95812 12.8785C2.73604 12.6642 2.625 12.3933 2.625 12.066C2.625 11.7387 2.73604 11.4667 2.95812 11.25C3.18021 11.0333 3.4525 10.925 3.775 10.925H14.25C14.5796 10.925 14.8495 11.0364 15.0597 11.2594C15.2699 11.4823 15.375 11.7499 15.375 12.0623C15.375 12.3979 15.2699 12.6715 15.0597 12.8829C14.8495 13.0943 14.5796 13.2 14.25 13.2Z"
                                    fill="#A7A7A7"/>
                            </svg>
                            <span className='text-[14px] opacity-50 mr-[2px] ml-[8px]'>Sort by</span>
                        </>
                    }
                    menuPosition="absolute"
                />
                <div className='flex items-center px-[10px] gap-[10px] rounded-[8px]
                border border-solid border-[#333] bg-[#1B1B1B]'>
                    <svg onClick={() => setViewSettings({...viewSettings, isGrid: true})} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" cursor="pointer">
                        <path fill={viewSettings.isGrid ? "#FF8114" : "#A7A7A7"} d="M3.95833 9.37499C3.43438 9.37499 2.98741 9.18653 2.61744 8.80962C2.24748 8.43271 2.0625 7.98228 2.0625 7.45832V3.95832C2.0625 3.43436 2.24748 2.98393 2.61744 2.60703C2.98741 2.23011 3.43438 2.04166 3.95833 2.04166H7.47917C7.98924 2.04166 8.43272 2.23011 8.80963 2.60703C9.18654 2.98393 9.375 3.43436 9.375 3.95832V7.45832C9.375 7.98228 9.18654 8.43271 8.80963 8.80962C8.43272 9.18653 7.98924 9.37499 7.47917 9.37499H3.95833ZM3.95833 17.9375C3.43438 17.9375 2.98741 17.7525 2.61744 17.3826C2.24748 17.0126 2.0625 16.5656 2.0625 16.0417V12.5208C2.0625 12.0108 2.24748 11.5673 2.61744 11.1904C2.98741 10.8134 3.43438 10.625 3.95833 10.625H7.47917C7.98924 10.625 8.43272 10.8134 8.80963 11.1904C9.18654 11.5673 9.375 12.0108 9.375 12.5208V16.0417C9.375 16.5656 9.18654 17.0126 8.80963 17.3826C8.43272 17.7525 7.98924 17.9375 7.47917 17.9375H3.95833ZM12.5417 9.37499C12.0177 9.37499 11.5673 9.18653 11.1904 8.80962C10.8135 8.43271 10.625 7.98228 10.625 7.45832V3.95832C10.625 3.43436 10.8135 2.98393 11.1904 2.60703C11.5673 2.23011 12.0177 2.04166 12.5417 2.04166H16.0417C16.5656 2.04166 17.0161 2.23011 17.393 2.60703C17.7699 2.98393 17.9583 3.43436 17.9583 3.95832V7.45832C17.9583 7.98228 17.7699 8.43271 17.393 8.80962C17.0161 9.18653 16.5656 9.37499 16.0417 9.37499H12.5417ZM12.5417 17.9375C12.0177 17.9375 11.5673 17.7525 11.1904 17.3826C10.8135 17.0126 10.625 16.5656 10.625 16.0417V12.5208C10.625 12.0108 10.8135 11.5673 11.1904 11.1904C11.5673 10.8134 12.0177 10.625 12.5417 10.625H16.0417C16.5656 10.625 17.0161 10.8134 17.393 11.1904C17.7699 11.5673 17.9583 12.0108 17.9583 12.5208V16.0417C17.9583 16.5656 17.7699 17.0126 17.393 17.3826C17.0161 17.7525 16.5656 17.9375 16.0417 17.9375H12.5417Z"/>
                    </svg>
                    <svg onClick={() => setViewSettings({...viewSettings, isGrid: false})} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" cursor="pointer">
                        <path fill={viewSettings.isGrid ? "#A7A7A7" : "#FF8114"} d="M3 17.9375C2.7375 17.9375 2.51563 17.8469 2.33438 17.6656C2.15313 17.4844 2.0625 17.2625 2.0625 17V14.375C2.0625 14.1125 2.15313 13.8906 2.33438 13.7094C2.51563 13.5281 2.7375 13.4375 3 13.4375H17C17.2625 13.4375 17.4878 13.5281 17.676 13.7094C17.8642 13.8906 17.9583 14.1125 17.9583 14.375V17C17.9583 17.2625 17.8642 17.4844 17.676 17.6656C17.4878 17.8469 17.2625 17.9375 17 17.9375H3ZM3 12.25C2.7375 12.25 2.51563 12.1608 2.33438 11.9823C2.15313 11.8038 2.0625 11.5736 2.0625 11.2917V8.70832C2.0625 8.44582 2.15313 8.22048 2.33438 8.03228C2.51563 7.84409 2.7375 7.74999 3 7.74999H17C17.2625 7.74999 17.4878 7.84409 17.676 8.03228C17.8642 8.22048 17.9583 8.44582 17.9583 8.70832V11.2917C17.9583 11.5736 17.8642 11.8038 17.676 11.9823C17.4878 12.1608 17.2625 12.25 17 12.25H3ZM3 6.56249C2.7375 6.56249 2.51563 6.47178 2.33438 6.29036C2.15313 6.10893 2.0625 5.8802 2.0625 5.60416V2.99999C2.0625 2.73749 2.15313 2.51214 2.33438 2.32395C2.51563 2.13575 2.7375 2.04166 3 2.04166H17C17.2625 2.04166 17.4878 2.13575 17.676 2.32395C17.8642 2.51214 17.9583 2.73749 17.9583 2.99999V5.60416C17.9583 5.8802 17.8642 6.10893 17.676 6.29036C17.4878 6.47178 17.2625 6.56249 17 6.56249H3Z"/>
                    </svg>
                </div>
                {/* <button className='flex items-center justify-center rounded-[8px] w-[36px] 
                border border-solid border-[#333] bg-[#1B1B1B]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <circle cx="10.217" cy="10.2155" r="5.88495" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.668 17.6694L14.375 14.3764" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button> */}
            </div>
        </div>
        <ul className={classNames('mt-[15px] gap-[15px] grid', viewSettings.isGrid ? 'grid-cols-5' : 'grid-cols-1')}>
            { resultNewsLetters.map(item => <CurrentBlock 
                key={item?.title}
                data={item}
                fetchCourses={fetchCourses}
                setOpenedModal={setOpenedModal}
            />) }
        </ul>

        { openedModal && 
        <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            { openedModal?.name === 'confirm-delete' && <div className="modal__body w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        { openedModal?.title ? openedModal.title : 'Do you really want to delete?' }
                    </h3>
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div> }
        </GeneratedContentModal> }
    </div>;
};

export default Created;