import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	Bold,
	CloudServices,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	HtmlComment,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	SourceEditing,
	TextTransformation,
	Undo,
    Alignment,
    Underline
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import '../assets/css/ck-theme-variables.css';
import ThisCustomUploadAdapterPlugin from '../ckEditorPlagins/uploadAdapter';
import { useParams } from 'react-router-dom';

export default function HtmlEdittor({initialData, setData, editor, setEditor, toolbarRef, isEdit}) {
    
	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);
	const {id} = useParams()

	useEffect(() => {
		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	}, []);
	
	const editorConfig = {
        theme: 'dark',
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'alignment:left',
                'alignment:center',
                'alignment:right',
                'alignment:justify',
				'|',
				'heading',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
                'underline',
				'|',
				'link',
				// 'mediaEmbed',
				'insertImage',
				
				// '|',
				// 'bulletedList',
				// 'numberedList',
                '|',
				'sourceEditing',
			],
			shouldNotGroupWhenFull: true
		},
		alignment: {
            options: [ 'left', 'center', 'right', 'justify' ]
        },
		plugins: [
			AccessibilityHelp,
			Autoformat,
			AutoImage,
			AutoLink,
            Alignment,
			Autosave,
			Bold,
			CloudServices,
			Essentials,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			Heading,
			HtmlComment,
            Underline,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageTextAlternative,
			ImageToolbar,
			ImageUpload,
			Italic,
			Link,
			LinkImage,
			List,
			// ListProperties,
			MediaEmbed,
			Paragraph,
			PasteFromOffice,
			SelectAll,
			SourceEditing,
			TextTransformation,
			Undo,
		],
		extraPlugins: [ ThisCustomUploadAdapterPlugin],
		fontFamily: {
			options: [
				'Roboto',
				'Open Sans',
				'Noto Sans',
				'Montserrat',
				'Lato',
				"Times New Roman",
				"Helvetica",
				"Arial",
				"Comic Sans MS",
				"Courier New",
				"Impact",
				"Tahoma",
				"Verdana",
			],
			supportAllValues: true
		},
		fontSize: {
			options: [10, 12, 14, 16, 18, 20, 24, 32],
			supportAllValues: true
		},
		heading: {
			options: [
				{
					model: 'paragraph',
					title: 'Common Text',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'H1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'H2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'H3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'H4',
					class: 'ck-heading_heading4'
				},
				// {
				// 	model: 'heading5',
				// 	view: 'h5',
				// 	title: 'H5',
				// 	class: 'ck-heading_heading5'
				// },
				// {
				// 	model: 'heading6',
				// 	view: 'h6',
				// 	title: 'H6',
				// 	class: 'ck-heading_heading6'
				// }
			]
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				}
			]
		},
		image: {
			toolbar: [
				'toggleImageCaption',
				'imageTextAlternative',
				'|',
				'imageStyle:inline',
				'imageStyle:wrapText',
				'imageStyle:breakText',
				'|',
				'resizeImage'
			]
		},
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			// decorators: {
			// 	toggleDownloadable: {
			// 		mode: 'manual',
			// 		label: 'Downloadable',
			// 		attributes: {
			// 			download: 'file'
			// 		}
			// 	}
			// }
		},
		// list: {
		// 	properties: {
		// 		styles: true,
		// 		startIndex: true,
		// 		reversed: true
		// 	}
		// },
		placeholder: 'Type or paste your content here!',
	};

    useEffect(() => {
        if(!editor) return
        if(isEdit) {
            editor.disableReadOnlyMode('read-only');
        } else {
            editor.enableReadOnlyMode('read-only');
        }
    }, [isEdit, editor])
    
    useEffect(() => {
        if (editor && toolbarRef.current) {
            const toolbar = editor.ui.view.toolbar.element;
            toolbarRef.current.appendChild(toolbar);
            
        }
    }, [editor]);
	
	return (
        <div 
            
            ref={editorContainerRef}
            // style={{display: isEdit ? 'block': 'none'}}
        >
            <div className='p-[20px] pb-[56px] editor-reset'>
                <div ref={editorRef}>
                    {isLayoutReady && 
                        <CKEditor 
                            editor={ClassicEditor} 
                            config={editorConfig} 
                            data={initialData}
                            onReady={(editor) => {
                                setEditor(editor);
                            }}
                            onChange={(event, editor) => {
                                
                            }}
                        />}
                    </div>
            </div>
        </div>
	);
}
